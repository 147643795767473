import { useOrganizationsApplicationQuery } from "api-client";
import { Outlet } from "react-router-dom";

import { Sidebar } from "~/components/Sidebar";
import NotFound from "~/pages/404";
import { useApplicationSyncRealtime } from "~/realtime/application-sync";
import { useParams } from "~/router";

function ApplicationLayout() {
  const params = useParams("/o/:org/w/:app");

  const applicationQuery = useOrganizationsApplicationQuery({
    organizationSlug: params.org,
    slug: params.app,
  });
  const application = applicationQuery.data ?? null;

  useApplicationSyncRealtime(application);

  if (applicationQuery.isPending) return null;

  if (!applicationQuery.data) {
    return <NotFound />;
  }

  return (
    <div className="flex-1 flex">
      <Sidebar />
      <div
        className="w-[calc(100vw-16rem)] flex"
        key={`${params.org}/${params.app}`}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default ApplicationLayout;
