import { Star01 } from "@untitled-ui/icons-react";
import { m } from "framer-motion";
import { SVGProps } from "react";

import { cn } from "~/lib/cn";

export function Favorite(props: SVGProps<SVGSVGElement>) {
  return (
    <m.span
      initial={{ scale: 0.75 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", bounce: 0.7 }}
    >
      <Star01
        {...props}
        className={cn(
          props.className,
          "text-warning [&_path]:fill-current [&_path]:[fill-opacity:1]",
        )}
      />
    </m.span>
  );
}
