import { useMutation } from "@tanstack/react-query";
import { InfoCircle } from "@untitled-ui/icons-react";
import { updateModelRecordMutationOptions } from "api-client";
import { m } from "framer-motion";
import { useMemo } from "react";
import { FormProvider, get } from "react-hook-form";

import { ActionsBar } from "~/components/_forms/ActionsBar";
import { Form } from "~/components/_forms/Form";
import { useRecordForm } from "~/components/_forms/RecordForm/hook";
import { ViewsPile } from "~/components/_presence/ViewsPile";
import { RecordFields } from "~/components/_records/RecordFields";
import { RecordSidebar } from "~/components/_records/RecordSidebar";
import { SidebarToggle } from "~/components/_records/RecordSidebar/SidebarToggle";
import { useRecordSidebarState } from "~/components/_records/RecordSidebar/store";
import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { RecordTimestamp } from "~/components/_records/RecordTimestamp";
import { Button } from "~/components/Button";
import { Titlebar } from "~/components/Titlebar";
import { useScreenMatches } from "~/lib/breakpoint";
import { cn } from "~/lib/cn";
import { useCurrentModel, useCurrentRecord } from "~/lib/current";
import { useFlagEnabled } from "~/lib/flags";
import { easeOutSmooth } from "~/lib/motion";
import { useParams } from "~/router";

function RecordDetail() {
  const model = useCurrentModel();
  const record = useCurrentRecord();
  const { methods, fields } = useRecordForm(model, record);

  const hasRecordSidebar = useFlagEnabled("record_sidebar");
  const [sidebarOpen] = useRecordSidebarState();
  const isCollapsible = useScreenMatches("collapse-sidebar");

  const params = useParams("/o/:org/w/:app/m/:model/r/:record");

  const titleField = useMemo(
    () => fields.find((field) => field.id === model?.title_field_id),
    [fields, model?.title_field_id],
  );
  const currentTitle = titleField
    ? methods.watch(`data.${titleField.field_name}`)?.toString()
    : record?.title ?? record?.id;

  const updateMutation = useMutation(updateModelRecordMutationOptions());
  const isDirty = methods.formState.isDirty;
  const canSubmit = isDirty && !updateMutation.isPending;
  const submit = methods.handleSubmit(async (data) => {
    if (!canSubmit) return;

    // Only include the fields that have changed
    const dirtyFields = Object.keys(methods.formState.dirtyFields.data ?? {});
    const changedData = Object.fromEntries(
      dirtyFields.map((field) => [field, get(data.data, field)]),
    );

    updateMutation.mutate(
      {
        organizationSlug: params.org,
        modelId: params.model,
        id: params.record,
        record: { ...data, data: changedData },
      },
      {
        onSuccess(data) {
          methods.reset(data, { keepDirtyValues: false });
        },
      },
    );
  });

  return (
    <FormProvider {...methods}>
      <div className="flex-1 flex flex-col overflow-clip">
        <Titlebar>
          <div className="flex items-center -mr-1">
            <div className="flex items-center gap-2.5">
              {record && <RecordTimestamp record={record} />}
              <ViewsPile model={model} record={record} />
            </div>

            {hasRecordSidebar && (isCollapsible ? true : !sidebarOpen) && (
              <m.div
                className="-m-1 -my-1.5 p-1 ml-1 overflow-hidden flex justify-end"
                exit={{ width: 0 }}
                transition={{ duration: 0.2, ease: easeOutSmooth }}
              >
                <SidebarToggle />
              </m.div>
            )}
          </div>
        </Titlebar>

        <div className="p-4 pb-0 flex flex-col flex-1 items-center gap-6">
          <header className="flex flex-col items-start gap-3 max-w-2xl w-full">
            <RecordThumbnail size="lg" model={model} record={record} />
            <h1
              className={cn(
                "text-2xl font-semibold",
                !currentTitle && "text-placeholder",
              )}
            >
              {currentTitle || titleField?.name}
            </h1>
          </header>

          <Form
            className="flex-1 flex flex-col max-w-2xl w-full"
            onSubmit={submit}
          >
            <RecordFields record={methods.watch()} />

            <ActionsBar hidden={!isDirty}>
              <div className="flex items-center gap-10 pl-1.5">
                <div className="flex items-center gap-2">
                  <InfoCircle className="w-4 h-4 text-icon" />
                  <p className="text-sm font-medium">Unsaved changes</p>
                </div>

                <div className="flex items-center gap-1.5">
                  <Button
                    type="reset"
                    size="sm"
                    variant="secondary"
                    onClick={() => methods.reset()}
                  >
                    Reset
                  </Button>

                  <Button
                    type="submit"
                    size="sm"
                    disabled={!canSubmit}
                    busy={updateMutation.isPending}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ActionsBar>
          </Form>
        </div>
      </div>

      <RecordSidebar />
    </FormProvider>
  );
}

export default RecordDetail;
