import { Copy01, Link01, Settings02, Trash04 } from "@untitled-ui/icons-react";
import {
  Application,
  destroyApplicationMutationOptions,
  mutate,
  syncApplicationMutationOptions,
} from "api-client";
import toast from "react-hot-toast/headless";
import { generatePath } from "react-router-dom";

import { AlertDialog } from "~/components/AlertDialog";
import { Path } from "~/router";
import { Sync } from "~/svgs/Sync";
import { Action } from "~/types";

export const syncApplicationAction: Action<Application> = {
  id: "syncApplication",
  label: "Sync",
  icon: Sync,
  applicable: (context) => context.target.viewer_can_sync,
  disabled: (context) => {
    const latestSync = context.target.latest_sync;
    const isSyncing =
      !!latestSync &&
      latestSync.state !== "succeeded" &&
      latestSync.state !== "failed";
    return isSyncing;
  },
  execute: async (evt) => {
    await mutate(
      {
        ...syncApplicationMutationOptions(),
        onSuccess() {
          toast.success(
            `Started syncing workspace "${evt.context.target.name}"`,
          );
        },
        onError() {
          toast.error(`Failed to sync workspace "${evt.context.target.name}"`);
        },
      },
      {
        organizationSlug: evt.context.target.organization_slug,
        applicationSlug: evt.context.target.slug,
      },
    );
  },
};

export const openApplicationSettingsAction: Action<Application> = {
  id: "openApplicationSettings",
  label: "Settings",
  icon: Settings02,
  execute: async (evt, helpers) => {
    helpers.navigate("/settings/o/:org/w/:app", {
      params: {
        org: evt.context.target.organization_slug,
        app: evt.context.target.slug,
      },
      state: { redirect: helpers.location.pathname },
    });
  },
};

export const copyApplicationIdAction: Action<Application> = {
  id: "copyApplicationId",
  group: "copy",
  label: "Copy ID",
  shortcut: "meta+shift+.",
  icon: Copy01,
  execute: async (evt) => {
    const text = evt.context.target.id;
    await navigator.clipboard.writeText(text);
    toast(`"${text}" copied to clipboard`);
  },
};

export const copyApplicationUrlAction: Action<Application> = {
  id: "copyApplicationUrl",
  group: "copy",
  label: "Copy URL",
  shortcut: "meta+shift+,",
  icon: Link01,
  execute: async (evt) => {
    const pathname: Path = "/o/:org/w/:app";
    const asPath = generatePath(pathname, {
      org: evt.context.target.organization_slug,
      app: evt.context.target.slug,
    });
    const url = new URL(asPath, window.location.origin);
    await navigator.clipboard.writeText(url.toString());
    toast(`Workspace "${evt.context.target.name}" URL copied to clipboard`);
  },
};

export const destroyApplicationAction: Action<Application> = {
  id: "destroyApplication",
  label: "Delete",
  icon: Trash04,
  variant: "danger",
  execute: async (evt, helpers) => {
    return (
      <AlertDialog
        initialOpen
        onDismiss={helpers.cleanup}
        title={`Delete "${evt.context.target.name}"`}
        description="By confirming, the workspace will be deleted. This action cannot be reversed."
        action="Delete"
        onSubmit={() => {
          mutate(
            {
              ...destroyApplicationMutationOptions(),
              onSuccess() {
                const params = evt.context.matchPath("/o/:org/w/:app");
                if (params?.app === evt.context.target.slug) {
                  helpers.navigate("/o/:org", {
                    params: {
                      org: evt.context.target.organization_slug,
                    },
                    replace: true,
                  });
                }
              },
            },
            {
              organizationSlug: evt.context.target.organization_slug,
              slug: evt.context.target.slug,
            },
          );
        }}
      />
    );
  },
};

export const applicationActions = [
  syncApplicationAction,
  openApplicationSettingsAction,
  copyApplicationIdAction,
  copyApplicationUrlAction,
  destroyApplicationAction,
];
