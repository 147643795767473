import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";

import twConfig from "~/../tailwind.config.js";

const resolvedTwConfig = resolveConfig(twConfig);
const breakpoints: Record<string, string> = resolvedTwConfig.theme.screens;

export const SCREENS = {
  "collapse-sidebar": breakpoints["collapse-sidebar"],
};

type OnScreenChangeFn = (matches: boolean, previous: boolean) => void;

export function useScreenMatches(
  screen: keyof typeof SCREENS,
  onScreenChange?: OnScreenChangeFn,
) {
  const mediaQuery = `not all and (min-width: ${SCREENS[screen]})`;
  const [matches, setMatches] = useState(
    () => window.matchMedia(mediaQuery).matches,
  );

  useEffect(() => {
    function handleChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
      onScreenChange?.(event.matches, matches);
    }

    const mediaQueryList = window.matchMedia(mediaQuery);
    mediaQueryList.addEventListener("change", handleChange);
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, [mediaQuery, matches, onScreenChange]);

  return matches;
}
