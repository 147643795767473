import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, ModelFieldStringView } from "api-client";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { Select } from "~/components/Select";
import { TextField } from "~/components/TextField";
import { cn } from "~/lib/cn";
import { getStringFieldSchema } from "~/lib/schema";
import { UnionKeys } from "~/types";

const STRING_OPERATORS = {
  eq: (value) => (value !== null ? "equals" : "has no value"),
  neq: (value) => (value !== null ? "not equals" : "has value"),
  gt: "",
  gte: "",
  lt: "",
  lte: "",
  like: "contains",
  nlike: "does not contain",
  starts: "starts with",
  ends: "ends with",
} satisfies Record<
  UnionKeys<Filter<string>>,
  string | ((value: string | null) => string)
>;

export interface StringFieldFilterProps {
  field: ModelFieldStringView;
  value: Filter<string>;
  onValueChange: (value: Filter<string>) => void;
  onRemove: () => void;
}

export function StringFieldFilter({
  field,
  value,
  onValueChange,
  onRemove,
}: StringFieldFilterProps) {
  const string = Object.values(value)[0];
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;

  const schema = useMemo(() => {
    return z.object({
      value: getStringFieldSchema(field),
    });
  }, [field]);

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      value: string,
    },
  });
  const formValue = methods.watch("value");
  useEffect(() => {
    onValueChange({ [operator]: formValue } as Filter<string>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, operator]);

  const getOperatorLabel = (
    operator: UnionKeys<typeof value>,
    overrideString?: string | null,
  ) => {
    const operatorLabel = STRING_OPERATORS[operator];
    return typeof operatorLabel === "function"
      ? operatorLabel(overrideString !== undefined ? overrideString : string)
      : operatorLabel;
  };

  const isEnum = field.enum_options.length > 0;

  // Store last selected string (to keep in memory between `null` values)
  const lastSelectedStringRef = useRef<string | null>(null);
  if (string !== null) {
    lastSelectedStringRef.current = string;
  }
  const lastSelectedString = lastSelectedStringRef.current ?? "";
  const currentString = string ?? lastSelectedString;

  return (
    <div
      className={cn(
        "contents",
        "items-center rounded-xl h-9",
        "relative overflow-hidden",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2">
        <Icon
          className="w-4 h-4 text-icon"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar" />}
        />
        <p className="text-sm whitespace-nowrap font-medium">
          {field.names.camelized_singular}
        </p>
      </div>

      <DropdownMenu
        modal
        value={getOperatorLabel(operator)}
        align="start"
        trigger={
          <button
            className={cn(
              "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
              "flex items-center gap-1.5",
              "outline-none focus-visible:bg-action-active data-[state=open]:bg-action-active",
            )}
          >
            <p className="text-sm truncate flex-1 text-left">
              {getOperatorLabel(operator)}
            </p>
            <ChevronDown className="w-4 h-4 text-icon" />
          </button>
        }
      >
        {field.nullable && (
          <Fragment>
            <DropdownItem onSelect={() => onValueChange({ neq: null })}>
              {getOperatorLabel("neq", null)}
            </DropdownItem>

            <DropdownItem onSelect={() => onValueChange({ eq: null })}>
              {getOperatorLabel("eq", null)}
            </DropdownItem>
          </Fragment>
        )}

        {!isEnum && (
          <Fragment>
            <DropdownItem
              onSelect={() => onValueChange({ like: currentString })}
            >
              {getOperatorLabel("like", currentString)}
            </DropdownItem>

            <DropdownItem
              onSelect={() => onValueChange({ nlike: currentString })}
            >
              {getOperatorLabel("nlike", currentString)}
            </DropdownItem>
          </Fragment>
        )}

        <DropdownItem onSelect={() => onValueChange({ eq: currentString })}>
          {getOperatorLabel("eq", currentString)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ neq: currentString })}>
          {getOperatorLabel("neq", currentString)}
        </DropdownItem>

        {!isEnum && (
          <Fragment>
            <DropdownItem
              onSelect={() => onValueChange({ starts: currentString })}
            >
              {getOperatorLabel("starts", currentString)}
            </DropdownItem>

            <DropdownItem
              onSelect={() => onValueChange({ ends: currentString })}
            >
              {getOperatorLabel("ends", currentString)}
            </DropdownItem>
          </Fragment>
        )}
      </DropdownMenu>

      <div className="flex-1">
        {string !== null &&
          (isEnum ? (
            <Select
              label="Filter"
              hideLabel
              size="sm"
              placeholder="value"
              options={field.enum_options.map((option) => option.value)}
              value={currentString}
              onValueChange={(value) => onValueChange({ eq: value })}
            />
          ) : (
            <TextField
              label="Filter"
              hideLabel
              size="sm"
              control={methods.control}
              name="value"
              placeholder="value"
            />
          ))}
      </div>

      <div className="px-0.5 flex items-center">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
