import * as RadixPopover from "@radix-ui/react-popover";
import { PropsWithChildren, ReactNode, useRef, useState } from "react";

import { cn } from "~/lib/cn";

export interface PopoverProps extends PropsWithChildren<unknown> {
  dark?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
  className?: string;
  trigger?: ReactNode;
  align?: "start" | "center" | "end";
  alignOffset?: number;
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  preventAutoFocus?: boolean;
}

export function Popover({
  dark = true,
  open,
  onOpenChange,
  modal,
  className,
  children,
  trigger,
  align,
  alignOffset,
  side,
  sideOffset = 4,
  preventAutoFocus,
}: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [openState, setOpenState] = useState(open ?? false);
  if (open !== undefined && open !== openState) {
    setOpenState(open);
  }

  return (
    <RadixPopover.Root
      modal={modal}
      open={openState}
      onOpenChange={(o) => {
        onOpenChange?.(o);
        setOpenState(o);
      }}
    >
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          align={align}
          side={side}
          alignOffset={alignOffset}
          sideOffset={sideOffset}
          collisionPadding={12}
          onOpenAutoFocus={(e) => {
            if (preventAutoFocus) {
              e.preventDefault();
              ref.current?.focus();
            }
          }}
          asChild
        >
          <div
            ref={ref}
            tabIndex={preventAutoFocus ? -1 : undefined}
            className={cn(
              "group/popover",
              "z-50 bg-main rounded-xl shadow outline-none overflow-hidden",
              {
                "bg-action": !dark,
                "dark bg-main": dark,
              },
              "flex flex-col",
              "data-[state=open]:animate-popover-enter",
              "data-[state=closed]:animate-popover-leave",
              "origin-[--radix-popover-content-transform-origin]",
              "min-w-[max(var(--radix-popover-trigger-width),8rem)]",
              className,
            )}
          >
            <div className="absolute inset-0 ring-1 ring-inset ring-primary rounded-xl pointer-events-none" />
            {children}
          </div>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
