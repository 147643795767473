import {
  LogOut01,
  Plus,
  Repeat01,
  Settings02,
  Trash04,
  XClose,
} from "@untitled-ui/icons-react";
import {
  CurrentUser,
  destroyInviteMutationOptions,
  destroyMemberMutationOptions,
  ModelFieldOneReferenceView,
  ModelRecord,
  mutate,
  OrganizationInvite,
  OrganizationMember,
} from "api-client";

import { RecordSelectorDrawer } from "~/components/_records/RecordSelectorDrawer";
import { signOutMutationOptions, switchUserMutationOptions } from "~/mutations";
import { Action } from "~/types";

export const joinOrCreateTeamAction: Action<CurrentUser> = {
  id: "joinOrCreateTeam",
  label: "Join or create team",
  icon: Plus,
  execute: async (evt, helpers) => {
    if (evt.context.currentUser?.id !== evt.context.target.id) {
      await mutate(switchUserMutationOptions(), {
        user_id: evt.context.target.id,
      });
    }
    helpers.navigate("/org");
  },
};

export const userSettingsAction: Action<CurrentUser> = {
  id: "userSettings",
  label: "Settings",
  icon: Settings02,
  execute: async (evt, helpers) => {
    if (evt.context.currentUser?.id !== evt.context.target.id) {
      await mutate(switchUserMutationOptions(), {
        user_id: evt.context.target.id,
      });
    }
    helpers.navigate("/settings", {
      state: { redirect: helpers.location.pathname },
    });
  },
};

export const signOutAction: Action<CurrentUser> = {
  id: "signOut",
  label: "Sign out",
  icon: LogOut01,
  execute: async (evt, helpers) => {
    await mutate(signOutMutationOptions(), { user_id: evt.context.target.id });
    if (evt.context.currentUser?.id === evt.context.target.id) {
      helpers.navigate("/", { replace: true });
    }
  },
};

export const removeMemberAction: Action<OrganizationMember> = {
  id: "removeMember",
  label: "Remove member",
  icon: XClose,
  applicable: (context) => context.currentUser?.id !== context.target.user.id,
  variant: "danger",
  execute: async (evt) => {
    await mutate(destroyMemberMutationOptions(), {
      id: evt.context.target.id,
      organizationSlug: evt.context.target.organization_slug,
    });
  },
};

export const revokeInviteAction: Action<OrganizationInvite> = {
  id: "revokeInvite",
  label: "Revoke invite",
  icon: Trash04,
  variant: "danger",
  execute: async (evt) => {
    await mutate(destroyInviteMutationOptions(), {
      id: evt.context.target.id,
      organizationSlug: evt.context.target.organization.slug,
    });
  },
};

export const changeOneReferenceFieldAction: Action<
  ModelFieldOneReferenceView,
  { record?: ModelRecord | null; onChangeRecord(record: ModelRecord): void }
> = {
  id: "changeOneReferenceField",
  label: "Replace",
  icon: Repeat01,
  applicable: (context) => !!context.record && !context.target.read_only,
  execute: async (evt, helpers) => {
    return (
      <RecordSelectorDrawer
        field={evt.context.target}
        initialOpen
        onValueChange={evt.context.onChangeRecord}
        onDismiss={helpers.cleanup}
      />
    );
  },
};

export const removeOneReferenceFieldAction: Action<
  ModelFieldOneReferenceView,
  {
    record?: ModelRecord | null;
    onRemoveRecord(): void;
  }
> = {
  id: "removeOneReferenceField",
  label: "Remove",
  icon: Trash04,
  variant: "danger",
  applicable: (context) =>
    !!context.record && context.target.nullable && !context.target.read_only,
  execute: async (evt) => {
    evt.context.onRemoveRecord();
  },
};
