import {
  InfiniteData,
  MutationOptions,
  Query,
  QueryKey,
  UseBaseQueryOptions,
  useQueries,
  UseQueryOptions,
} from "@tanstack/react-query";
import isEqual from "fast-deep-equal";
import { useMemo } from "react";

import { cache } from "./cache";
import { Pagination, UrlParam } from "./generated/api";
import { queryClient } from "./query";

export const p = {
  Any: { __type: Symbol("any") } as any,
};

export function matchPredicate(key: QueryKey) {
  return (query: Query) => matchQueryKeys(key, query.queryKey);
}

export function matchQueryKeys(expected: QueryKey, actual: QueryKey) {
  if (expected.length !== actual.length) return false;

  for (let i = 0; i < expected.length; i++) {
    const value = expected[i];

    if (
      typeof value === "object" &&
      value !== null &&
      "__type" in value &&
      value.__type === p.Any.__type
    ) {
      continue;
    }

    if (!isEqual(value, actual[i])) return false;
  }

  return true;
}

export function useInfiniteQueries<
  T extends object,
  Key extends QueryKey = QueryKey,
>(data: T[], query: (data: T) => UseQueryOptions<T, Error, T, Key>) {
  const queries = useQueries({
    queries: data.map((i) => ({ ...query(i), initialData: i })),
  });
  return useMemo(() => {
    return queries.flatMap((query) => (query.data ? [query.data] : []));
  }, [queries]);
}

export function useInfiniteData<T extends object>(
  data?: InfiniteData<Pagination<T>>,
) {
  return useMemo(() => infiniteFlatten(data), [data]);
}

export function useInfiniteTotalCount<T extends object>(
  data: InfiniteData<Pagination<T>> | undefined,
) {
  return useMemo(() => {
    const lastPage = data?.pages.at(-1);
    return lastPage?.meta.total_count ?? 0;
  }, [data]);
}

export function useInfiniteCurrentPage<T extends object>(
  data: InfiniteData<Pagination<T>> | undefined,
  pageParam: number,
) {
  return useMemo(() => {
    const index = data?.pageParams.findIndex((param) => param === pageParam);
    if (index === undefined) return null;
    return data?.pages.at(index) ?? null;
  }, [data, pageParam]);
}

export function infiniteFlatten<T extends object>(
  infiniteData?: InfiniteData<Pagination<T>, any>,
) {
  return infiniteData?.pages.flatMap((page) => page.data) ?? [];
}

export function infiniteAppend<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  data: T,
) {
  const lastPage = infiniteData.pages[infiniteData.pages.length - 1];
  lastPage.data.push(data);
  return {
    ...infiniteData,
    pages: [...infiniteData.pages.slice(0, -1), lastPage],
  };
}

export function infinitePrepend<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  data: T,
) {
  const firstPage = infiniteData.pages[0];
  firstPage.data.unshift(data);
  return {
    ...infiniteData,
    pages: [firstPage, ...infiniteData.pages.slice(1)],
  };
}

export function infiniteMap<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  fn: (data: T) => T,
) {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map((page) => ({
      ...page,
      data: page.data.map(fn),
    })),
  };
}

export function infiniteFilter<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  fn: (data: T) => boolean,
) {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map((page) => ({
      ...page,
      data: page.data.filter(fn),
    })),
  };
}

export function infiniteSome<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  fn: (data: T) => boolean,
) {
  return infiniteData.pages.some((page) => page.data.some(fn));
}

export function infiniteReorder<T extends object>(
  infiniteData: InfiniteData<Pagination<T>, any>,
  getId: (data: T) => UrlParam,
  reordered: { id: UrlParam; position: number }[],
) {
  const flattened = infiniteFlatten(infiniteData);
  const dataMap = new Map(flattened.map((item) => [getId(item), item]));

  const reorderedMap = new Map(
    reordered.map(({ id, position }) => [position, dataMap.get(id)]),
  );
  const reorderedData = flattened.map((item, index) => {
    const value = reorderedMap.get(index);
    return value ?? item;
  });
  const reorderedPages = infiniteData.pages.map((page, index) => {
    const start =
      0 +
      infiniteData.pages
        .slice(0, index)
        .reduce((acc, page) => acc + page.data.length, 0);

    return {
      ...page,
      data: reorderedData.slice(start, start + page.data.length),
    };
  });

  return {
    ...infiniteData,
    pages: reorderedPages,
  };
}

export type OptimisticFn<
  QQueryFnData,
  QQueryError,
  QData,
  QQueryData,
  QQueryKey extends QueryKey,
> = {
  query: UseBaseQueryOptions<
    QQueryFnData,
    QQueryError,
    QData,
    QQueryData,
    QQueryKey
  >;
  update?: (cache: QQueryData) => QQueryData;
};

export type RegisterOptimisticFn = <
  QQueryFnData,
  QQueryError,
  QData,
  QQueryData,
  QQueryKey extends QueryKey,
>(
  variables: OptimisticFn<
    QQueryFnData,
    QQueryError,
    QData,
    QQueryData,
    QQueryKey
  >,
) => void;

const isQueryKey = (value: any): value is QueryKey => {
  return typeof value === "string" || Array.isArray(value);
};

export type OptimisticMutationOptions<
  TData = unknown,
  TError = Error,
  TVariables = void,
  TContext = unknown,
> = Omit<MutationOptions<TData, TError, TVariables>, "onMutate"> & {
  onMutate?: (
    variables: TVariables,
    register: RegisterOptimisticFn,
  ) => TContext;
};

export interface OptimisticMutationResult {
  rollback: () => void;
  invalidate: () => void;
}

export interface OptimisticMutationContext {
  results: OptimisticMutationResult[];
}

export function mutationOptions<TData, TError, TVariables, TContext>(
  options: OptimisticMutationOptions<TData, TError, TVariables, TContext>,
): MutationOptions<TData, TError, TVariables, OptimisticMutationContext> {
  return {
    ...options,

    onMutate(variables) {
      const results: OptimisticMutationResult[] = [];

      const register: RegisterOptimisticFn = ({ query, update }) => {
        const queryKey = isQueryKey(query) ? query : query.queryKey;
        const predicate = matchPredicate(queryKey);

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries({ predicate });

        // Get the old data from the cache
        let old: [QueryKey, unknown][] = [];
        old = queryClient.getQueriesData({ predicate });

        // Optimistically update data in query cache
        old.forEach(([key, value]) => {
          if (!value || !update) return;
          const updatedData = update(value as any);
          if (updatedData) cache(updatedData);
          queryClient.setQueryData(key, updatedData);
        });

        // If the query fails, we'll want to roll back any optimistic updates
        const rollback = () => {
          old.forEach(([key, value]) => {
            queryClient.setQueryData(key, value);
          });
        };

        // Otherwise, we can invalidate the cache after a successful mutation
        const invalidate = () => {
          queryClient.invalidateQueries({ predicate });
        };

        results.push({ rollback, invalidate });
      };

      options.onMutate?.(variables, register);

      return { results };
    },

    onError(error, variables, context) {
      context?.results.forEach(({ rollback }) => rollback());
      options.onError?.(error, variables, context);
    },

    async onSuccess(data, variables, context) {
      const promise = Promise.allSettled(
        context.results.map(({ invalidate }) => invalidate()),
      );
      options.onSuccess?.(data, variables, context);
      await promise;
    },
  };
}

/**
 * Call mutations outside of the React tree.
 *
 * Reference: https://github.com/TanStack/query/discussions/4771#discussioncomment-5999962
 */
export function mutate<TData, TError, TVariables extends void, TContext>(
  options: MutationOptions<TData, TError, TVariables, TContext>,
): Promise<TData>;
export function mutate<TData, TError, TVariables, TContext>(
  options: MutationOptions<TData, TError, TVariables, TContext>,
  variables: TVariables,
): Promise<TData>;
export function mutate<TData, TError, TVariables, TContext>(
  options: MutationOptions<TData, TError, TVariables, TContext>,
  variables?: TVariables extends unknown ? never : TVariables,
): Promise<TData> {
  return queryClient
    .getMutationCache()
    .build(queryClient, options)
    .execute(variables ?? ({} as TVariables));
}
