export function Logo() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 6.479C2 5.50695 2 5.02093 2.09773 4.62013C2.40151 3.37425 3.37425 2.40151 4.62013 2.09773C5.02093 2 5.50696 2 6.479 2V2C7.03141 2 7.30762 2 7.53539 2.05554C8.24341 2.22817 8.79622 2.78098 8.96885 3.489C9.02439 3.71677 9.02439 3.99298 9.02439 4.54538V15.4546C9.02439 16.007 9.02439 16.2832 8.96885 16.511C8.79622 17.219 8.24341 17.7718 7.53539 17.9445C7.30762 18 7.03141 18 6.479 18V18C5.50696 18 5.02093 18 4.62013 17.9023C3.37425 17.5985 2.40151 16.6257 2.09773 15.3799C2 14.9791 2 14.493 2 13.521V6.479Z"
        fill="currentColor"
      />
      <path
        d="M10.9756 4.54539C10.9756 3.99298 10.9756 3.71677 11.0311 3.489C11.2038 2.78098 11.7566 2.22817 12.4646 2.05554C12.6924 2 12.9686 2 13.521 2V2C14.493 2 14.979 2 15.3798 2.09773C16.6257 2.40151 17.5985 3.37425 17.9022 4.62013C18 5.02093 18 5.50696 18 6.479V6.479C18 7.03141 18 7.30762 17.9444 7.53539C17.7718 8.24341 17.219 8.79622 16.511 8.96885C16.2832 9.02439 16.007 9.02439 15.4546 9.02439H14.0975C13.0048 9.02439 12.4584 9.02439 12.041 8.81172C11.6738 8.62465 11.3753 8.32615 11.1883 7.95901C10.9756 7.54162 10.9756 6.99522 10.9756 5.90244V4.54539Z"
        fill="currentColor"
      />
      <path
        d="M10.9756 14.0975C10.9756 13.0048 10.9756 12.4584 11.1883 12.041C11.3753 11.6738 11.6738 11.3753 12.041 11.1883C12.4584 10.9756 13.0048 10.9756 14.0975 10.9756H15.4546C16.007 10.9756 16.2832 10.9756 16.511 11.0311C17.219 11.2038 17.7718 11.7566 17.9444 12.4646C18 12.6924 18 12.9686 18 13.521V13.521C18 14.493 18 14.979 17.9022 15.3798C17.5985 16.6257 16.6257 17.5985 15.3798 17.9022C14.979 18 14.493 18 13.521 18V18C12.9686 18 12.6924 18 12.4646 17.9444C11.7566 17.7718 11.2038 17.219 11.0311 16.511C10.9756 16.2832 10.9756 16.007 10.9756 15.4546V14.0975Z"
        fill="currentColor"
      />
    </svg>
  );
}
