import { DotsHorizontal } from "@untitled-ui/icons-react";
import {
  useInfiniteData,
  useOrganizationsMembersInfiniteQuery,
} from "api-client";

import { removeMemberAction } from "~/actions";
import { ActionMenu } from "~/components/ActionMenu";
import { Avatar } from "~/components/Avatar";
import { IconButton } from "~/components/IconButton";

export interface MemberListProps {
  org: string;
}

export function MemberList({ org }: MemberListProps) {
  const membersQuery = useOrganizationsMembersInfiniteQuery({
    organizationSlug: org,
  });
  const members = useInfiniteData(membersQuery.data);

  return (
    <section className="flex flex-col gap-3">
      <h2 className="font-medium">Members</h2>

      {members.length > 0 && (
        <ul className="flex flex-col gap-3">
          {members.map((member) => (
            <li
              key={member.id}
              className="items-center grid gap-2 grid-cols-[4fr,2fr,1fr]"
            >
              <div className="flex items-center gap-2.5  mr-10">
                <Avatar user={member.user} />

                <dl className="flex-1 items-center overflow-hidden grid grid-cols-subgrid">
                  <div className="flex flex-col flex-1 -space-y-0.5 min-w-8">
                    <dt className="sr-only">Name</dt>
                    <dd className="truncate">{member.user.full_name}</dd>
                    <dt className="sr-only">Email</dt>
                    <dd className="truncate text-secondary">
                      {member.user.email}
                    </dd>
                  </div>
                </dl>
              </div>

              <dl className="flex-1  mr-10">
                <dt className="sr-only">Role</dt>
                <dd>Member</dd>
              </dl>

              <div className="flex justify-end">
                <ActionMenu
                  target={member}
                  actions={[removeMemberAction]}
                  trigger={
                    <IconButton
                      icon={DotsHorizontal}
                      accessibilityLabel="Actions"
                    />
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
