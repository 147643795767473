import { zodResolver } from "@hookform/resolvers/zod";
import {
  Model,
  ModelRecord,
  useOrganizationsModelsFieldsQuery,
} from "api-client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { getDefaultRecord } from "~/lib/get";
import { getModelSchema } from "~/lib/schema";

export function useRecordFields(
  model: Model | null,
  record: ModelRecord | null,
) {
  const isNewRecord = !record?.id;

  const fieldsQuery = useOrganizationsModelsFieldsQuery(
    {
      organizationSlug: model?.organization_slug ?? "",
      modelId: model?.id ?? "",
    },
    {
      enabled: !!model,
    },
  );
  const fields = useMemo(() => fieldsQuery.data ?? [], [fieldsQuery.data]);

  return useMemo(() => {
    if (isNewRecord) {
      return fields.filter((field) => {
        if (field.read_only) return false;
        if (field.hidden) return false;
        if (
          field.field_type === "reference" &&
          field.reference_type === "to_many"
        )
          return false;
        return true;
      });
    } else {
      return fields.filter((field) => !field.hidden);
    }
  }, [fields, isNewRecord]);
}

export function useRecordForm(model: Model | null, record: ModelRecord | null) {
  const fields = useRecordFields(model, record);

  const schema = useMemo(() => getModelSchema(fields), [fields]);

  const defaultValues = useMemo(() => {
    if (!record) {
      return getDefaultRecord(model, fields);
    }
    const result = schema.safeParse(record);
    if (!result.success) {
      return getDefaultRecord(model, fields);
    } else {
      return result.data;
    }
  }, [record, schema, model, fields]);

  const methods = useForm({
    resetOptions: { keepDirtyValues: true },
    mode: "onSubmit",
    defaultValues,
    resolver: zodResolver(schema),
  });

  // Reset the form when the default values change
  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, JSON.stringify(defaultValues)]);

  return useMemo(() => ({ methods, fields }), [methods, fields]);
}
