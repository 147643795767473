import fs from "fs";
import path from "path";
import plugin from "tailwindcss/plugin";

import { UtilityAPI } from "./utilities";

export interface ExtractColorsOptions {
  input: string;
}

/**
 * This plugin extracts color from a given input CSS file and generates
 * text, background, and border color utilities for each color variant.
 *
 * Input:
 *
 * ```txt
 * --text-primary: #333;
 * ```
 *
 * Output:
 *
 * ```txt
 * .text-primary, .text-primary/95, ...
 * ```
 */
export const ExtractColorsPlugin = plugin.withOptions<ExtractColorsOptions>(
  function (options) {
    return function (api) {
      // Define color maps
      const textColorMap = new Map();
      const backgroundColorMap = new Map();
      const borderColorMap = new Map();
      const prefixMaps = Object.entries({
        "--text-": textColorMap,
        "--bg-": backgroundColorMap,
        "--border-": borderColorMap,
      });

      // Parse and extract CSS variables from source into maps
      const pathname = path.resolve(__dirname, "..", "..", options.input);
      const source = fs.readFileSync(pathname, "utf-8");
      const matches = source.match(/--[^":;]+/g) ?? [];
      matches.forEach((variable) => {
        for (const [prefix, map] of prefixMaps) {
          if (!variable.startsWith(prefix)) continue;

          const value = `rgb(var(${variable}) / <alpha-value>)`;
          map.set(variable.replace(prefix, ""), value);
          break;
        }
      });

      // Generate utilities
      const utils = new UtilityAPI(api);
      utils.text(textColorMap);
      utils.background(backgroundColorMap);
      utils.border(borderColorMap);
    };
  },
);
