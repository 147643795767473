import {
  createContext,
  forwardRef,
  PropsWithChildren,
  useContext,
  useId,
} from "react";

import { Avatar, AvatarProps, AvatarSize } from "~/components/Avatar";
import { cn } from "~/lib/cn";

interface FacePileContextValue {
  size: AvatarSize;
}
const FacePileContext = createContext<FacePileContextValue>({ size: "md" });

const gapMap: Record<AvatarSize, string> = {
  sm: cn("-space-x-1.5"),
  md: cn("-space-x-2"),
  lg: cn("-space-x-2.5"),
};

export interface FacePileProps extends PropsWithChildren<unknown> {
  size?: AvatarSize;
}

export function FacePile({ children, size = "md" }: FacePileProps) {
  const id = useId();
  return (
    <FacePileContext.Provider value={{ size }}>
      <span
        className={cn("relative flex shrink-0", gapMap[size])}
        style={{ "--clip": `url(#${id})` }}
      >
        {children}

        <svg className="sr-only" aria-hidden="true">
          <defs>
            <clipPath id={id} clipPathUnits="objectBoundingBox">
              <path d="M0.273708 0.840172C0.245385 0.878907 0.249876 0.935699 0.293571 0.955531C0.3565 0.984094 0.426393 1 0.500002 1C0.776144 1 1 0.776142 1 0.5C1 0.223858 0.776144 0 0.500002 0C0.426393 0 0.3565 0.0159061 0.293571 0.0444686C0.249876 0.0643012 0.245385 0.121093 0.273708 0.159828C0.343448 0.255205 0.384617 0.372795 0.384617 0.5C0.384617 0.627205 0.343448 0.744795 0.273708 0.840172Z" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </FacePileContext.Provider>
  );
}

export type FacePileItemProps = Omit<AvatarProps, "size">;

export const FacePileItem = forwardRef<HTMLDivElement, FacePileItemProps>(
  function FacePileItem({ user, disabled, ...props }, ref) {
    const ctx = useContext(FacePileContext);
    return (
      <div
        ref={ref}
        {...props}
        className={cn("flex [&:not(:first-child)]:[clip-path:var(--clip)]")}
      >
        <Avatar size={ctx.size} disabled={disabled} user={user} />
      </div>
    );
  },
);
