import { Model } from "api-client";
import { ReactNode } from "react";

import { IconMap } from "~/lib/constants";

export interface IconProps {
  className?: string;
  name?: Model["icon"] | null;
  fallback?: ReactNode;
}

export function Icon({ name, className, fallback = null }: IconProps) {
  const Component = name ? IconMap[name] : null;
  if (!Component) return fallback;
  return <Component className={className} />;
}
