import { ApiModel } from "api-client";
import { useState } from "react";

import { useRealtimeChannel, useRealtimeEvent } from "~/lib/pusher";

type WithPresence<T> = T extends { pusher_presence_channel: string }
  ? T
  : never;

export type PresenceApiModel = WithPresence<ApiModel>;

export function usePresentUsers(apiModel: PresenceApiModel | null) {
  const [ids, setIds] = useState(new Set<string>());

  const channel = useRealtimeChannel(apiModel?.pusher_presence_channel);

  useRealtimeEvent(channel, "pusher:subscription_succeeded", () => {
    setIds(new Set(Object.keys(channel.current?.members.members ?? {})));
  });

  useRealtimeEvent(channel, "pusher:member_added", (data) => {
    setIds((ids) => new Set([...ids, data.id]));
  });

  useRealtimeEvent(channel, "pusher:member_removed", (data) => {
    setIds((ids) => new Set(Array.from(ids).filter((id) => id !== data.id)));
  });

  return ids;
}
