import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, Model, ModelFieldBooleanView } from "api-client";
import { useMemo } from "react";

import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { cn } from "~/lib/cn";
import { UnionKeys } from "~/types";

const BOOLEAN_OPERATORS = {
  eq: "is",
  neq: "is not",
  gt: "",
  gte: "",
  lt: "",
  lte: "",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<boolean>>,
  string | ((value: boolean | null) => string)
>;

export interface BooleanFieldFilterProps {
  models: Model[];
  field: ModelFieldBooleanView;
  value: Filter<boolean>;
  onValueChange: (value: Filter<boolean>) => void;
  onRemove: () => void;
}

export function BooleanFieldFilter({
  models,
  field,
  value,
  onValueChange,
  onRemove,
}: BooleanFieldFilterProps) {
  const rawValue = Object.values(value)[0];

  const recordName = useMemo(() => {
    if (!models.length || models.length > 1) {
      return "Record";
    } else {
      return models[0].names.camel_singular;
    }
  }, [models]);

  return (
    <div
      className={cn(
        "contents",
        "items-center rounded-xl h-9",
        "relative shadow overflow-hidden",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2">
        <Icon
          className="w-4 h-4 text-icon"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar" />}
        />
        <p className="text-sm whitespace-nowrap font-medium">{recordName}</p>
      </div>

      <DropdownMenu
        modal
        value={rawValue ? BOOLEAN_OPERATORS["eq"] : BOOLEAN_OPERATORS["neq"]}
        align="start"
        trigger={
          <button
            className={cn(
              "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
              "flex items-center gap-1.5",
              "outline-none focus-visible:bg-action-active data-[state=open]:bg-action-active",
            )}
          >
            <p className="text-sm whitespace-nowrap flex-1 text-left">
              {rawValue ? BOOLEAN_OPERATORS["eq"] : BOOLEAN_OPERATORS["neq"]}
            </p>
            <ChevronDown className="w-4 h-4 text-icon" />
          </button>
        }
      >
        <DropdownItem onSelect={() => onValueChange({ eq: true })}>
          {BOOLEAN_OPERATORS["eq"]}
        </DropdownItem>
        <DropdownItem onSelect={() => onValueChange({ eq: false })}>
          {BOOLEAN_OPERATORS["neq"]}
        </DropdownItem>
      </DropdownMenu>

      <div className="px-2 flex items-center">
        <p className="text-sm whitespace-nowrap font-medium">
          {field.names.humanized}
        </p>
      </div>

      <div className="px-0.5 flex items-center">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
