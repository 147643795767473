import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ApiModel } from "api-client";
import deepEqual from "fast-deep-equal";
import { AnimatePresence } from "framer-motion";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";
import { parseHotkey } from "react-hotkeys-hook/src/parseHotkeys";

import { Kbd } from "~/components/Kbd";
import {
  apply,
  useActionContext,
  useActionHelpers,
  useExecuteAction,
} from "~/lib/actions";
import { ActionReactContext } from "~/lib/actions/context";
import { cn } from "~/lib/cn";
import { Action, Prettify } from "~/types";

export type ExtractTarget<Actions extends Action<any, any>[]> = {
  [K in keyof Actions]: Actions[K] extends Action<infer Target, any>
    ? Target
    : never;
}[number];
// Extract context from array of actions
export type ExtractContext<Actions extends Action<any, any>[]> = {
  [K in keyof Actions]: Actions[K] extends Action<any, infer ExtraContext>
    ? ExtraContext
    : never;
}[number];
// Merge contexts together
type MergeContexts<ExtraContext = {}> = {
  [K in keyof ExtraContext]: ExtraContext[K];
};
// Add context to props (if it's not empty)
export type PropsWithExtraContext<ExtraContext = {}> =
  keyof ExtraContext extends never
    ? { context?: never }
    : { context: ExtraContext };

export type ActionMenuProps<
  T extends ApiModel,
  Actions extends Action<T, any>[],
> = {
  as?: "dropdown" | "contextmenu";
  trigger: ReactNode;
  target: T | null;
  actions: Actions;
  modal?: boolean;
} & PropsWithExtraContext<Prettify<MergeContexts<ExtractContext<Actions>>>>;

export function ActionMenu<
  T extends ApiModel,
  Actions extends Action<T, any>[],
>({
  as = "dropdown",
  trigger,
  target,
  actions,
  modal,
  context: extraContext,
}: ActionMenuProps<T, Actions>) {
  const Component = as === "dropdown" ? DropdownMenu : ContextMenu;

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const context = useActionContext(target, extraContext);
  const [node, helpers] = useActionHelpers({
    onDismiss() {
      setOpen(false);
      // Context menus cannot be programmatically dismissed, so we need to
      // programmatically trigger an escape keydown event to close the menu
      if (as === "contextmenu") {
        const event = new KeyboardEvent("keydown", { key: "Escape" });
        triggerRef.current?.dispatchEvent(event);
      }
    },
    onCleanup() {
      triggerRef.current?.focus();
    },
  });
  const execute = useExecuteAction(context, helpers);

  const applicableActions = useMemo(() => {
    return actions.filter((action) => {
      if (!context) return false;
      if (!action.applicable) return true;
      return action.applicable(context);
    });
  }, [actions, context]);

  const shortcutActionsMap = useMemo(() => {
    const map = new Map<string, Action<T>>();
    for (const action of applicableActions) {
      if (!action.shortcut) continue;
      map.set(action.shortcut, action);
    }
    return map;
  }, [applicableActions]);

  const { disableScope, enableScope } = useHotkeysContext();

  useEffect(() => {
    if (as !== "contextmenu") return;

    if (open) {
      enableScope("contextmenu");
    } else {
      disableScope("contextmenu");
    }
  }, [as, open, disableScope, enableScope]);

  useHotkeys(
    Array.from(shortcutActionsMap.keys()).join("||"),
    useCallback(
      (_event, match) => {
        const shortcuts = Array.from(shortcutActionsMap.keys());
        const shortcut = shortcuts.find((shortcut) =>
          deepEqual(parseHotkey(shortcut), match),
        );
        if (!shortcut) return;

        const action = shortcutActionsMap.get(shortcut);
        if (!action) return;

        execute(action);
      },
      [shortcutActionsMap, execute],
    ),
    {
      splitKey: "||",
      preventDefault: true,
      scopes: as === "contextmenu" ? "contextmenu" : "*",
      enabled: as === "contextmenu" ? open : true,
    },
  );

  if (!applicableActions.length) {
    if (as === "contextmenu") return trigger;
    return null;
  }

  return (
    <Fragment>
      {node}
      <Component.Root
        modal={as === "contextmenu" || modal}
        open={open}
        onOpenChange={setOpen}
      >
        <Component.Trigger asChild ref={triggerRef}>
          {trigger}
        </Component.Trigger>

        <Component.Portal>
          <Component.Content
            className={cn(
              "dark z-50 bg-main rounded-xl ring-1 ring-inset ring-primary shadow",
              "flex flex-col",
              "data-[state=open]:animate-popover-enter",
              "data-[state=closed]:animate-popover-leave",
              "origin-[--radix-popper-transform-origin]",
            )}
            side="bottom"
            align="start"
            sideOffset={5}
            collisionPadding={12}
            loop
          >
            <div className="p-[5px]">
              <AnimatePresence initial={false}>
                {applicableActions.map((action, index) => {
                  const showSeparator =
                    index > 0 &&
                    action.group !== applicableActions[index - 1]?.group;

                  const Icon = action.icon;
                  const label = apply(action.label)(context);
                  const variant = apply(action.variant ?? "primary")(context);
                  const disabled = action.disabled?.(context) ?? false;
                  return (
                    <Fragment key={action.id}>
                      {showSeparator && (
                        <Component.Separator className="w-[calc(100%+10px)] translate-x-[-5px] my-[5px] h-0 border-t border-primary" />
                      )}

                      <Component.Item
                        className={cn(
                          "group/item",
                          "w-full flex items-center gap-5 p-2 rounded-md text-left cursor-pointer outline-none",
                          "data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 transition-opacity",
                          "border-t border-transparent",
                          "[&[data-highlighted]:hover]:outline-none [&[data-highlighted]]:shadow",
                          {
                            "[&[data-highlighted]]:bg-subtle [&[data-highlighted]]:border-action":
                              variant === "primary",
                            "[&[data-highlighted]]:bg-danger-subtle [&[data-highlighted]]:border-danger":
                              variant === "danger",
                          },
                        )}
                        disabled={disabled}
                        onSelect={() => execute(action)}
                      >
                        <span className="flex items-center gap-2 flex-1">
                          <span className="p-0.5">
                            <ActionReactContext.Provider value={context}>
                              <Icon
                                className={cn(
                                  "w-4 h-4",
                                  variant === "danger"
                                    ? "text-danger"
                                    : "text-icon",
                                )}
                              />
                            </ActionReactContext.Provider>
                          </span>
                          <Component.Label asChild>
                            <span
                              className={cn(
                                "pr-1 text-sm font-medium",
                                variant === "danger"
                                  ? "text-danger"
                                  : "text-primary",
                              )}
                            >
                              {label}
                            </span>
                          </Component.Label>
                        </span>
                        {action.shortcut && (
                          <Kbd shortcut={action.shortcut} flush />
                        )}
                      </Component.Item>
                    </Fragment>
                  );
                })}
              </AnimatePresence>
            </div>
          </Component.Content>
        </Component.Portal>
      </Component.Root>
    </Fragment>
  );
}
