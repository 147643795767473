import {
  ChevronLeft,
  IntersectCircle,
  Lock02,
  Plus,
  User01,
  UserCircle,
} from "@untitled-ui/icons-react";
import {
  organizationsApplicationQueryOptions,
  useInfiniteData,
  useInfiniteQueries,
  useOrganizationsApplicationsInfiniteQuery,
  useUserQuery,
} from "api-client";
import {
  animate,
  AnimatePresence,
  m,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { useCallback, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";

import { CreateApplicationDialog } from "~/components/_applications/CreateApplicationDialog";
import { AppIcon } from "~/components/AppIcon";
import { Avatar } from "~/components/Avatar";
import { IconButton } from "~/components/IconButton";
import { DesktopNavigation } from "~/components/Sidebar/DesktopNavigation";
import { cn } from "~/lib/cn";
import { useCurrentUser } from "~/lib/current";
import { Link, Path, useNavigate } from "~/router";

import { SidebarGroup, SidebarItem, SidebarItemProps } from "./Item";

function SettingsSidebarItem<P extends Path, T>(props: SidebarItemProps<P, T>) {
  const location = useLocation();
  return <SidebarItem {...props} state={location.state} />;
}

interface AppsGroupProps {
  organizationSlug?: string;
}

function AppsGroup({ organizationSlug }: AppsGroupProps) {
  const allAppsQuery = useOrganizationsApplicationsInfiniteQuery(
    {
      organizationSlug: organizationSlug || "",
    },
    { enabled: !!organizationSlug },
  );
  const rawApps = useInfiniteData(allAppsQuery.data);
  const apps = useInfiniteQueries(rawApps, (app) =>
    organizationsApplicationQueryOptions({
      organizationSlug: app.organization_slug,
      slug: app.slug,
    }),
  );

  if (!organizationSlug || allAppsQuery.isPending) {
    return (
      <div className="p-2">
        <div className="w-[60%] h-5 rounded-md bg-subtle" />
      </div>
    );
  }

  if (!allAppsQuery.isPending && !apps.length) {
    return (
      <div className="p-2">
        <p className="text-sm text-placeholder">No apps</p>
      </div>
    );
  }

  return (
    <AnimatePresence initial={false}>
      {apps.map((app) => (
        <SettingsSidebarItem
          key={app.id}
          icon={() => <AppIcon application={app} className="w-5 h-5" />}
          label={app.name}
          to="/settings/o/:org/w/:app"
          exact
          params={{ org: organizationSlug, app: app.slug }}
        >
          <ul className="flex-1 flex flex-col pl-2 pt-0.5 gap-0.5 -mr-1">
            <SettingsSidebarItem
              icon={Lock02}
              label="Security"
              to="/settings/o/:org/w/:app/security"
              params={{ org: organizationSlug, app: app.slug }}
            />
          </ul>
        </SettingsSidebarItem>
      ))}
    </AnimatePresence>
  );
}

export function SettingsSidebar() {
  const me = useCurrentUser();
  const userQuery = useUserQuery({ id: me?.id ?? "" }, { enabled: !!me });
  const user = userQuery.data;

  const navigate = useNavigate();
  const location = useLocation();

  const scrollRef = useRef<HTMLDivElement>(null);
  const scroll = useScroll({
    container: scrollRef,
  });
  const borderOpacity = useMotionValue(0);
  useMotionValueEvent(scroll.scrollY, "change", (value) => {
    if (value > 0) {
      animate(borderOpacity, 1, { duration: 0.2 });
    } else {
      borderOpacity.stop();
      borderOpacity.set(0);
    }
  });

  // TODO: support users who are part of multiple organizations
  const currentOrg = me?.organization_memberships.at(0)?.organization;

  useHotkeys(
    "esc",
    useCallback(() => {
      navigate(location.state?.redirect ?? "/");
    }, [location, navigate]),
    { preventDefault: true },
  );

  return (
    <nav className="min-w-sidebar h-full max-h-100vh sticky top-0 bg-panel">
      <div
        ref={scrollRef}
        className={cn(
          "absolute inset-0 overflow-y-auto",
          "flex flex-col border-r border-primary",
        )}
      >
        <div className="sticky top-0 z-20 bg-panel w-full flex flex-col gap-2.5">
          <div className="web:hidden pt-2.5 px-3 -mb-2.5">
            <DesktopNavigation />
          </div>

          <Link
            to={location.state?.redirect ?? "/"}
            className="group/link flex items-start pl-4 pr-4 py-4 outline-none"
          >
            <div className="flex items-center gap-2 p-1.5 pr-2.5 -m-1.5 rounded-lg group-focus-visible/link:bg-subtle">
              <ChevronLeft className="w-4 h-4 text-icon" />
              <h2 className="text-sm font-medium">Settings</h2>
            </div>
          </Link>

          <m.div
            className="absolute inset-x-0 bottom-0 border-b border-primary"
            style={{ opacity: borderOpacity } as any}
          />
        </div>

        {user ? (
          <div className="flex items-center p-3 px-4 mb-2 overflow-hidden shrink-0">
            <Avatar user={user} />
            <div className="ml-3 truncate font-medium">
              <p className="text-sm leading-tight truncate">{user.full_name}</p>
              <p className="text-xs text-secondary leading-tight truncate">
                {user.email}
              </p>
            </div>
          </div>
        ) : null}

        <div className="flex flex-col flex-1 p-2 pt-0">
          <SidebarGroup title="Account">
            <SettingsSidebarItem
              icon={UserCircle}
              label="Profile"
              to="/settings"
              exact
            />
          </SidebarGroup>

          <SidebarGroup title="Team">
            <SettingsSidebarItem
              icon={IntersectCircle}
              label="General"
              to="/settings/o/:org"
              params={{
                org: currentOrg?.slug ?? "",
              }}
              exact
            />
            <SettingsSidebarItem
              icon={User01}
              label="Members"
              to="/settings/o/:org/members"
              params={{
                org: currentOrg?.slug ?? "",
              }}
            />
          </SidebarGroup>

          <SidebarGroup
            className="-mb-2"
            title="Workspaces"
            action={
              <CreateApplicationDialog
                trigger={
                  <IconButton icon={Plus} accessibilityLabel="New workspace" />
                }
                form={{
                  organizationSlug: currentOrg?.slug,
                  onSuccess: (app) => {
                    navigate("/settings/o/:org/w/:app", {
                      params: { org: app.organization_slug, app: app.slug },
                    });
                  },
                }}
              />
            }
          >
            <AppsGroup organizationSlug={currentOrg?.slug} />
          </SidebarGroup>
        </div>
      </div>
    </nav>
  );
}
