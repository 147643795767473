import { AnimatePresence, m } from "framer-motion";
import { useId } from "react";

import { easeOutSmooth } from "~/lib/motion";

export interface SidebarProps {
  className?: string;
  open?: boolean;
}

export function Sidebar({ className, open = false }: SidebarProps) {
  const id = useId();
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={id}>
          <rect x="3" y="3" width="18" height="18" rx="3" fill="white" />
        </mask>
      </defs>

      <path
        d="M15 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g mask={`url(#${id})`}>
        <AnimatePresence initial={false}>
          <m.path
            animate={{ translateX: open ? 0 : 6 }}
            transition={{ duration: 0.2, ease: easeOutSmooth }}
            d="M15 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H15V3Z"
            fill="currentColor"
          />
        </AnimatePresence>
      </g>
    </svg>
  );
}
