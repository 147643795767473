import { DotsHorizontal, Mail01 } from "@untitled-ui/icons-react";
import {
  useInfiniteData,
  useOrganizationsInvitesInfiniteQuery,
} from "api-client";

import { revokeInviteAction } from "~/actions";
import { InviteForm } from "~/components/_settings/InviteForm";
import { ActionMenu } from "~/components/ActionMenu";
import { IconButton } from "~/components/IconButton";
import { cn } from "~/lib/cn";

export interface InviteListProps {
  org: string;
}

export function InviteList({ org }: InviteListProps) {
  const invitesQuery = useOrganizationsInvitesInfiniteQuery({
    organizationSlug: org,
  });
  const invites = useInfiniteData(invitesQuery.data);

  return (
    <section className="flex flex-col gap-3">
      <h2 className="font-medium">Invites</h2>

      {invites.length > 0 && (
        <ul className="flex flex-col gap-3">
          {invites.map((invite) => (
            <li
              key={invite.id}
              className="items-center grid gap-2 grid-cols-[4fr,2fr,1fr]"
            >
              <div className="flex items-center gap-2.5 mr-10">
                <span
                  className={cn(
                    "shrink-0 rounded-full bg-avatar w-8 h-8 flex items-center justify-center",
                  )}
                >
                  <Mail01 className={cn("w-5 h-5 text-secondary")} />
                </span>

                <dl className="flex-1 items-center overflow-hidden grid grid-cols-subgrid">
                  <div className="flex flex-col flex-1 -space-y-0.5 min-w-8">
                    <dt className="sr-only">Email</dt>
                    <dd className="truncate">{invite.recipient_email}</dd>
                  </div>
                </dl>
              </div>

              <dl className="flex-1 mr-10">
                <dt className="sr-only">Role</dt>
                <dd className="text-secondary">Member</dd>
              </dl>

              <div className="flex justify-end">
                <ActionMenu
                  target={invite}
                  actions={[revokeInviteAction]}
                  trigger={
                    <IconButton
                      icon={DotsHorizontal}
                      accessibilityLabel="Actions"
                    />
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      )}

      <div className="flex-1">
        <InviteForm org={org} />
      </div>
    </section>
  );
}
