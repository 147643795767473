import { Model, ModelRecord } from "api-client";

import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { Link } from "~/router";

export interface RecordPreviewLockupProps {
  model: Model | null;
  record: ModelRecord | null;
}

export function RecordPreviewLockup({
  model,
  record,
}: RecordPreviewLockupProps) {
  return (
    <span className="font-medium flex flex-row items-center gap-2.5">
      <span className="flex -my-1">
        <RecordThumbnail size="md" model={model} record={record} />
      </span>
      <span className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
        {record?.title ?? record?.id}
      </span>
    </span>
  );
}

export interface RecordPreviewProps extends RecordPreviewLockupProps {}

export function RecordPreview({ model, record }: RecordPreviewProps) {
  return (
    <Link
      to={`/o/:org/w/:app/m/:model/r/:record`}
      params={{
        org: model?.organization_slug ?? "",
        app: model?.application_slug ?? "",
        model: model?.id ?? "",
        record: record?.id ?? "",
      }}
    >
      <RecordPreviewLockup model={model} record={record} />
    </Link>
  );
}
