import { RefreshCcw02 } from "@untitled-ui/icons-react";
import { Application } from "api-client";
import { SVGProps } from "react";

import { useActionReactContext } from "~/lib/actions/context";
import { cn } from "~/lib/cn";

export function Sync(props: SVGProps<SVGSVGElement>) {
  const context = useActionReactContext<Application>();
  const latestSync = context.target.latest_sync;
  const isSyncing =
    !!latestSync &&
    latestSync.state !== "succeeded" &&
    latestSync.state !== "failed";

  return (
    <span className={cn("flex", isSyncing && "animate-spin-reverse")}>
      <RefreshCcw02 {...props} />
    </span>
  );
}
