import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useSignupMutation } from "~/mutations";
import { Link, useNavigate } from "~/router";
import { Logo } from "~/svgs/Logo";

const SignUpFormSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email("Invalid email address"),
  password: z.string().min(10, "Password must be 10 or more characters long"),
});

type SignUpForm = z.infer<typeof SignUpFormSchema>;

function SignUp() {
  const {
    formState: { errors },
    setError,
    handleSubmit,
    control,
  } = useForm<SignUpForm>({
    resolver: zodResolver(SignUpFormSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const signUp = useSignupMutation();

  const onSubmit = handleSubmit(async (data: SignUpForm) => {
    signUp.mutate(data, {
      onSuccess() {
        navigate("/verify", { replace: true });
      },
      onError: (error) => handleFormErrors(error, setError),
    });
  });

  return (
    <div className="p-4 gap-4 flex-1 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-1.5 text-icon w-full max-w-md">
        <div className="w-6 aspect-square text-icon-subtle">
          <Logo />
        </div>
        <h1 className="text-lg font-medium text-primary">Sign up</h1>
        <p className="text-sm text-placeholder">
          Already have an account?{" "}
          <Link className="text-secondary" to="/signin">
            Sign in
          </Link>
        </p>
      </div>

      <div className="w-full max-w-md mt-1">
        <form className="flex flex-col gap-3 mb-4" onSubmit={onSubmit}>
          <div className="grid grid-cols-2 gap-2.5">
            <TextField
              name="first_name"
              type="text"
              label="First name"
              placeholder="Johnny"
              control={control}
            />
            <TextField
              name="last_name"
              type="text"
              label="Last name"
              placeholder="Appleseed"
              control={control}
            />
          </div>
          <TextField
            name="email"
            type="email"
            label="Email"
            placeholder="john@appleseed.com"
            control={control}
            errorMessages={{
              remote_taken:
                "User exists in WorkOS but not locally. Run the seed script to sync.",
            }}
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="•••••••••••••••"
            control={control}
          />

          <div className="flex flex-col mt-4">
            <Button size="lg">Sign up</Button>
          </div>
        </form>

        {errors.root && <ErrorMessage label="Form" error={errors.root} />}
      </div>
    </div>
  );
}

const ProtectedSignUp = withAuth(SignUp, { type: "unauthenticated" });

export default ProtectedSignUp;
