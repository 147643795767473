import { isAxiosError } from "axios";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";

export function getFormError<T extends FieldValues>(
  path: Path<T>,
  error: unknown,
): string[] {
  if (!isAxiosError(error) || !error.response?.status) {
    return [];
  }

  const errors = Object.entries(error?.response?.data?.errors);

  for (const [key, value] of errors) {
    if (key === path) {
      return value as string[];
    }
  }

  return [];
}

export const handleFormErrors = <T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
) => {
  if (!isAxiosError(error) || !error.response?.status) {
    return;
  }

  if (error.response.status >= 500) {
    setError("root", {
      message: "Server temporarily unavailable. Please try again later.",
    });
  } else {
    Object.entries(error?.response?.data?.errors).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((error: string) => {
          const path: Path<T> = key === "base" ? "root" : (key as any);
          setError(path, {
            message: error,
          });
        });
      }
    });
  }
};
