import * as RadixDialog from "@radix-ui/react-dialog";
import { XClose } from "@untitled-ui/icons-react";
import { AnimatePresence, m } from "framer-motion";
import { ReactNode, useState } from "react";

import { Button } from "~/components/Button";
import { IconButton } from "~/components/IconButton";
import { cn } from "~/lib/cn";
import { easeOutExpo } from "~/lib/motion";

export interface AlertDialogProps {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  onDismiss?(): void;
  trigger?: ReactNode;
  title: string;
  description?: string;
  action: string;
  onSubmit(evt: Event): void;
  variant?: "primary" | "danger";
}

export function AlertDialog({
  title,
  description,
  action,
  onSubmit,
  variant = "danger",
  initialOpen = false,
  open: openProp,
  onOpenChange: setOpenProp,
  onDismiss,
  trigger,
}: AlertDialogProps) {
  const [openState, setOpenState] = useState(initialOpen);
  const open = openProp ?? openState;
  const onOpenChange = setOpenProp ?? setOpenState;

  return (
    <RadixDialog.Root
      modal
      defaultOpen={initialOpen}
      open={open}
      onOpenChange={onOpenChange}
    >
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <AnimatePresence onExitComplete={onDismiss}>
        {open && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay asChild>
              <m.div
                className="z-50 fixed inset-0 bg-reverse/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: easeOutExpo, duration: 0.35 }}
              />
            </RadixDialog.Overlay>
            <RadixDialog.Content asChild>
              <m.div
                className={cn(
                  "z-50 fixed top-10 inset-x-0 outline-none p-3 todesktop:app-no-drag",
                  "ease-out-expo duration-350 transition-transform",
                  "data-[state=closed]:opacity-0",
                  "data-[state=closed]:animate-alert-leave data-[state=open]:animate-alert-enter",
                  "!pointer-events-none",
                )}
              >
                <div
                  className={cn(
                    "flex flex-col bg-main rounded-lg border border-primary shadow",
                    "w-full max-w-screen-sm max-h-100vh mx-auto",
                    "pointer-events-auto",
                  )}
                >
                  <header className="pl-4 pr-3 py-3 flex items-center justify-between border-b border-primary">
                    <RadixDialog.Title asChild>
                      <h2 className="font-medium">{title}</h2>
                    </RadixDialog.Title>
                    <RadixDialog.Close asChild>
                      <IconButton
                        icon={XClose}
                        accessibilityLabel="Close"
                        onClick={() => onOpenChange(false)}
                        hideTooltip
                      />
                    </RadixDialog.Close>
                  </header>
                  <form
                    className={cn(
                      "p-4 pt-3 flex flex-col flex-1 gap-2",
                      variant === "danger" &&
                        "bg-gradient-to-t from-danger/5 to-danger/0",
                    )}
                    onSubmit={(event) => {
                      event.preventDefault();

                      const submitEvent = new Event("submit", {
                        cancelable: true,
                      });
                      onSubmit(submitEvent);
                      if (!submitEvent.defaultPrevented) {
                        onOpenChange(false);
                      }
                    }}
                  >
                    {description && <p>{description}</p>}

                    <div className="flex justify-end gap-2 mt-2 -mr-0.5 -mb-0.5">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={() => onOpenChange(false)}
                      >
                        Cancel
                      </Button>
                      <Button danger={variant === "danger"}>{action}</Button>
                    </div>
                  </form>
                </div>
              </m.div>
            </RadixDialog.Content>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}
