import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import { GlobalHotKeys } from "~/components/GlobalHotKeys";
import { useAuth, withAuth } from "~/lib/auth";
import { useCurrentUser } from "~/lib/current";
import { Navigate } from "~/router";

function AuthLayout() {
  const status = useAuth();
  const me = useCurrentUser();

  // If not verified, redirect to `/verify`
  if (status === "pending") {
    return <Navigate to="/verify" replace />;
  }

  // Otherwise, render the child route
  return (
    <Fragment>
      {me && <Outlet />}
      <GlobalHotKeys />
    </Fragment>
  );
}

const ProtectedAuthLayout = withAuth(AuthLayout, { type: "authenticated" });

export default ProtectedAuthLayout;
