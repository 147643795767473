import {
  DataTag,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { flexRender } from "@tanstack/react-table";
import { CubeOutline } from "@untitled-ui/icons-react";
import { Model, ModelRecord, Pagination } from "api-client";
import { ReactNode, useCallback, useRef, useState } from "react";

import { recordActions } from "~/actions/record";
import { useRecordTable } from "~/components/_records/RecordTable/data";
import { RecordFilters } from "~/components/_records/RecordTable/Filters";
import { RecordTablePagination } from "~/components/_records/RecordTable/Pagination";
import { ActionMenu } from "~/components/ActionMenu";
import { Button } from "~/components/Button";
import { Wrap } from "~/components/Wrap";
import { cn } from "~/lib/cn";

class SelectRecordEvent extends Event {
  record: ModelRecord;

  constructor(record: ModelRecord) {
    super("select-record");
    this.record = record;
  }
}

export interface RecordTableProps {
  variant?: "default" | "contained";
  record?: ModelRecord | null;
  models: Model[];
  queryOptions: UseInfiniteQueryOptions<
    Pagination<ModelRecord>,
    Error,
    InfiniteData<Pagination<ModelRecord>>,
    Pagination<ModelRecord>,
    QueryKey,
    number | undefined
  > & {
    queryKey: DataTag<QueryKey, InfiniteData<Pagination<ModelRecord>>>;
    enabled?: boolean;
  };
  onSelect?(event: SelectRecordEvent): void;
  actions?: ReactNode;
  where?: Record<string, any>;
  onWhereChange?(where: Record<string, any>): void;
  page?: number;
  onPageChange?(page: number | ((current: number) => number)): void;
}

export function RecordTable({
  variant = "default",
  record,
  models,
  queryOptions,
  onSelect,
  actions,
  where,
  onWhereChange,
  page: propsPage,
  onPageChange,
}: RecordTableProps) {
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const [statePage, setStatePage] = useState(1);
  const page = propsPage ?? statePage;
  const setPage = onPageChange ?? setStatePage;
  const onPageParamChange = useCallback(
    (updater: number | ((current: number) => number)) => {
      setPage(updater);
      bodyRef.current?.scrollTo({ top: 0, behavior: "auto" });
    },
    [setPage],
  );

  const [table, isPending] = useRecordTable({
    record,
    models,
    queryOptions,
    pageParam: page,
    onPageParamChange,
  });
  const isEmpty = !isPending && table.getRowModel().rows.length === 0;

  return (
    <Wrap
      condition={variant === "default"}
      wrap={(children) => (
        <div className="flex-1 flex flex-col relative">
          <div className="absolute inset-0 flex flex-col flex-1 overflow-hidden [&_[data-table-container]]:absolute [&_[data-table-container]]:inset-0">
            {children}
          </div>
        </div>
      )}
    >
      <div className="flex-1 max-h-full flex flex-col gap-2.5">
        <div className="flex items-start justify-between gap-3">
          <RecordFilters
            models={models}
            where={where}
            onWhereChange={onWhereChange}
          />

          {actions}
        </div>

        <div
          className={cn(
            "flex flex-col w-full flex-1 relative",
            variant === "contained" &&
              "border border-action rounded-[13px] bg-panel p-[3px]",
          )}
        >
          <div data-table-container className="flex flex-col flex-1 relative">
            <table className={cn("flex flex-col w-full", !isEmpty && "h-full")}>
              <thead className="rounded-t-xl">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="flex w-full">
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} className="flex-1">
                        <div
                          className={cn(
                            "px-3.5 pb-2 pt-1.5 flex flex-row items-center gap-2",
                            "text-left text-secondary font-medium text-sm",
                          )}
                        >
                          <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                            {header.isPlaceholder ? (
                              <div className="w-full h-4 rounded-md bg-subtle" />
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )
                            )}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody
                ref={bodyRef}
                className={cn(
                  "w-full flex flex-col flex-1 overflow-y-auto overflow-x-hidden",
                  "border border-action bg-panel rounded-xl",
                  variant === "contained" && "max-h-[25rem] bg-main shadow",
                  isEmpty && "invisible h-0 flex-none overflow-hidden",
                )}
              >
                {table.getRowModel().rows.map((row, rowIndex) => (
                  <Wrap
                    key={row.id}
                    condition={!isPending}
                    wrap={(children) => (
                      <ActionMenu
                        as="contextmenu"
                        target={row.original}
                        actions={recordActions}
                        trigger={children}
                      />
                    )}
                  >
                    <tr
                      className={cn(
                        "group/row cursor-pointer flex w-full",
                        "shadow-[0_1px_0_0_rgb(var(--border-action))]",
                      )}
                      onClick={() => {
                        if (!isPending && onSelect) {
                          const event = new SelectRecordEvent(row.original);
                          onSelect(event);
                        }
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="p-0 flex-1 overflow-hidden"
                        >
                          <div
                            className={cn(
                              "relative overflow-hidden flex items-center",
                              "h-full min-h-[50px]",
                              rowIndex < table.getRowModel().rows.length - 1 &&
                                "border-action border-b",
                              "bg-action group-hover/row:bg-action-active group-data-[state=open]/row:bg-action-active",
                            )}
                          >
                            <div className="p-3.5 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  </Wrap>
                ))}
              </tbody>
            </table>

            {isEmpty && (
              <div
                className={cn(
                  "w-full flex flex-col flex-1",
                  "border border-action bg-panel rounded-xl",
                  variant === "contained" && "max-h-[25rem] bg-main shadow",
                )}
              >
                <div
                  className={cn(
                    "min-h-48 w-full flex-1 rounded-xl flex flex-col gap-2.5 items-center justify-center",
                  )}
                >
                  <CubeOutline className="text-icon-subtle w-6 h-6" />
                  <div className="text-center text-placeholder text-sm">
                    No results
                  </div>

                  {where && Object.keys(where).length > 0 && (
                    <Button
                      className="mt-0.5 text-secondary"
                      size="xs"
                      variant="subtle"
                      onClick={() => onWhereChange?.({})}
                    >
                      Clear filters
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <RecordTablePagination table={table} />
        </div>
      </div>
    </Wrap>
  );
}
