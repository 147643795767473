import { PluginAPI } from "tailwindcss/types/config";

export class UtilityAPI {
  private readonly api: PluginAPI;
  constructor(api: PluginAPI) {
    this.api = api;
  }

  private addOpacityVariants(variableMap: Map<string, string>) {
    const opacities = { ...this.api.theme("opacity") };

    const variants = Array.from(variableMap.entries()).flatMap(
      ([key, value]) => {
        return [
          [key, value.replace("<alpha-value>", "1")],
          ...Object.entries(opacities).map(([opacityKey, opacityValue]) => {
            return [
              `${key}/${opacityKey}`,
              value.replace("<alpha-value>", opacityValue),
            ];
          }),
        ];
      },
    );

    return Object.fromEntries(variants);
  }

  text(variableMap: Map<string, string>) {
    const values = this.addOpacityVariants(variableMap);
    this.api.matchUtilities(
      {
        text: (color) => ({
          color,
        }),
        decoration: (color) => ({
          textDecorationColor: color,
        }),
      },
      { values },
    );
  }

  background(variableMap: Map<string, string>) {
    const values = this.addOpacityVariants(variableMap);
    this.api.matchUtilities(
      {
        bg: (color) => ({
          backgroundColor: color,
        }),
        from: (color) => ({
          "--tw-gradient-from": `${color} var(--tw-gradient-from-position)`,
          "--tw-gradient-stops":
            "var(--tw-gradient-from), var(--tw-gradient-to)",
        }),
        via: (color) => ({
          "--tw-gradient-via": `${color} var(--tw-gradient-via-position)`,
          "--tw-gradient-stops": `var(--tw-gradient-from), var(--tw-gradient-via), var(--tw-gradient-to)`,
        }),
        to: (color) => ({
          "--tw-gradient-to": `${color} var(--tw-gradient-to-position)`,
        }),
      },
      { values },
    );
  }

  border(variableMap: Map<string, string>) {
    const values = this.addOpacityVariants(variableMap);
    this.api.matchUtilities(
      {
        border: (color) => ({
          borderColor: color,
        }),
        "border-t": (color) => ({
          borderTopColor: color,
        }),
        "border-r": (color) => ({
          borderRightColor: color,
        }),
        "border-l": (color) => ({
          borderLeftColor: color,
        }),
        "border-b": (color) => ({
          borderBottomColor: color,
        }),

        divide: (color) => ({
          "> :not([hidden]) ~ :not([hidden])": {
            borderColor: color,
          },
        }),
        "divide-y": (color) => ({
          "> :not([hidden]) ~ :not([hidden])": {
            borderColor: color,
          },
        }),
        "divide-x": (color) => ({
          "> :not([hidden]) ~ :not([hidden])": {
            borderColor: color,
          },
        }),

        outline: (color) => ({
          outlineColor: color,
        }),

        ring: (color) => ({
          "--tw-ring-color": color,
        }),
        "ring-offset": (color) => ({
          "--tw-ring-offset-color": color,
          boxShadow:
            "0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow)",
        }),
      },
      { values },
    );
  }
}
