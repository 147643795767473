import { Check } from "@untitled-ui/icons-react";
import { Command } from "cmdk";
import {
  ComponentType,
  createContext,
  PropsWithChildren,
  ReactNode,
  SVGProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Popover } from "~/components/Popover";
import { cn } from "~/lib/cn";

interface DropdownMenuContextValue {
  value: string | number | undefined;
  onSelect(): void;
  showHighlight: boolean;
  size: "sm" | "md";
}
const DropdownMenuContext = createContext<DropdownMenuContextValue>({
  value: undefined,
  onSelect: () => {},
  showHighlight: false,
  size: "sm",
});

export interface DropdownMenuProps extends PropsWithChildren<unknown> {
  open?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
  className?: string;
  trigger?: ReactNode;
  align?: "start" | "center" | "end";
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  value?: string | number;
  size?: "sm" | "md";
}

export function DropdownMenu({
  open: openProp,
  onOpenChange: setOpenProp,
  modal,
  children,
  trigger,
  align,
  side,
  sideOffset = 4,
  value,
  size = "sm",
}: DropdownMenuProps) {
  const [openState, setOpenState] = useState(false);
  const open = openProp ?? openState;
  const setOpen = setOpenProp ?? setOpenState;

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const [isUsingMouse, setIsUsingMouse] = useState(false);
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false);
  const focusVisible = isUsingMouse ? false : isUsingKeyboard;

  const [isHovering, setIsHovering] = useState(true);
  const showHighlight = isHovering ? true : focusVisible;

  const onSelect = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (open) {
      setIsHovering(true);
      setIsUsingMouse(false);
      setIsUsingKeyboard(false);
      setIsDirty(false);
    }
  }, [open]);

  return (
    <DropdownMenuContext.Provider
      value={{ size, value, onSelect, showHighlight }}
    >
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={trigger}
        align={align}
        side={side}
        sideOffset={sideOffset}
        modal={modal}
      >
        <div
          onMouseMove={() => {
            setIsUsingMouse(true);
            setIsUsingKeyboard(false);
          }}
          onMouseEnter={() => {
            setIsHovering(true);
            setIsUsingMouse(true);
            setIsUsingKeyboard(false);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
            setIsUsingMouse(false);
            setIsUsingKeyboard(false);
          }}
        >
          <Command
            loop
            value={internalValue?.toString()}
            onValueChange={setInternalValue}
          >
            <div
              className={cn(
                "p-[5px] border-b border-primary bg-main",
                !isDirty && "sr-only",
              )}
            >
              <div className="py-1 px-2">
                <Command.Input
                  className={cn(
                    "bg-transparent w-full min-w-0 flex-1 outline-none overflow-hidden",
                    "text-sm placeholder:text-placeholder",
                  )}
                  size={1}
                  placeholder="Filter..."
                  onValueChange={() => {
                    setIsDirty(true);
                  }}
                  onKeyDown={() => {
                    setIsUsingKeyboard(true);
                    setIsUsingMouse(false);
                  }}
                />
              </div>
            </div>

            <Command.List
              className={cn(
                "p-[5px] group/list",
                "max-h-[--radix-popover-content-available-height)]",
                "scroll-py-[5px] overflow-y-auto",
              )}
            >
              <Command.Empty className="py-2 px-2.5">
                <p className="mb-px text-sm text-secondary">No results found</p>
              </Command.Empty>

              {children}
            </Command.List>
          </Command>
        </div>
      </Popover>
    </DropdownMenuContext.Provider>
  );
}

export interface DropdownItemProps extends PropsWithChildren<unknown> {
  value?: string | number;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  onSelect?(): void;
}

export function DropdownItem({
  value,
  children,
  icon: Icon,
  disabled,
  onSelect,
}: DropdownItemProps) {
  const ctx = useContext(DropdownMenuContext);

  return (
    <Command.Item
      disabled={disabled}
      onSelect={() => {
        onSelect?.();
        ctx.onSelect();
      }}
      value={value?.toString()}
      className={cn(
        "flex items-center gap-2 rounded-md cursor-pointer outline-none",
        {
          "px-2.5 py-1.5": !Icon,
          "pl-2 pr-2.5 py-2": Icon,
        },
        "data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50 transition-opacity",
        "border-t border-transparent",
        "outline-none",
        ctx.showHighlight &&
          "[&[data-selected=true]]:bg-subtle [&[data-selected=true]]:border-action [&[data-selected=true]]:shadow",
      )}
    >
      {Icon && (
        <span className="p-0.5">
          <Icon className="w-4 h-4 text-icon" />
        </span>
      )}
      <span
        className={cn("flex-1", {
          "text-sm font-medium": ctx.size === "sm",
          "text-base": ctx.size === "md",
        })}
      >
        {children}
      </span>
      {ctx.value !== undefined && (
        <div className="ml-4 -mr-0.5 w-4 h-4">
          {ctx.value === (value ?? children) && (
            <Check className="w-4 h-4 text-icon" />
          )}
        </div>
      )}
    </Command.Item>
  );
}
