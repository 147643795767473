import { AriaPlugin } from "./lib/plugins/aria";
import { ExtractColorsPlugin } from "./lib/plugins/extract-colors";
import { IsolateGroupPlugin } from "./lib/plugins/isolate-group";
import { ToDesktopPlugin } from "./lib/plugins/to-desktop";

/** @type {import('tailwindcss').Config} */
export default {
  content: ["./index.html", "./src/**/*.tsx", "./src/index.scss"],
  darkMode: "class",
  theme: {
    colors: {
      current: "currentColor",
      transparent: "transparent",
      black: "#000000",
      white: "#ffffff",
    },

    boxShadow: {
      DEFAULT: "0px 3px 4px -3px var(--shadow-default)",
      none: "none",
      sidebar: "-3px 0px 10px -6px var(--shadow-default)",
    },

    fontFamily: {
      sans: ["Inter", "sans-serif"],
      mono: ["IBM Plex Mono", "sans-serif"],
    },

    extend: {
      animation: {
        collapse: "collapse 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 1",
        expand: "expand 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 1",
        "alert-enter": "alert-enter 0.25s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "alert-leave": "alert-leave 0.25s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "dialog-enter": "dialog-enter 0.35s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "dialog-leave": "dialog-leave 0.35s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "popover-enter": "popover-enter 0.2s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "popover-leave": "popover-leave 0.2s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "spin-reverse": "spin-reverse 1s linear infinite",
        "tooltip-enter": "tooltip-enter 0.2s cubic-bezier(0.16, 1, 0.3, 1) 1",
        "tooltip-leave": "tooltip-leave 0.2s cubic-bezier(0.16, 1, 0.3, 1) 1",
      },

      borderRadius: {
        icon: "5px",
        xl: "10px",
      },

      fontSize: {
        "3xs": "0.5rem", // 8px
        "2xs": "0.625rem", // 10px
      },

      maxHeight: {
        "100vh": "calc(100vh - var(--staff-bar, 0px))",
      },

      keyframes: {
        collapse: {
          "0%": {
            height: "var(--radix-collapsible-content-height)",
            opacity: 1,
            transform: "scale(1)",
          },
          "100%": {
            height: "0px",
            opacity: 0,
            transform: "scale(0.95)",
          },
        },
        expand: {
          "0%": {
            height: "0px",
            opacity: 0,
            transform: "scale(0.95)",
          },
          "100%": {
            height: "var(--radix-collapsible-content-height)",
            opacity: 1,
            transform: "scale(1)",
          },
        },
        "alert-enter": {
          "0%": { opacity: "0", transform: "translateY(1rem) scale(0.97)" },
          "100%": { opacity: "1", transform: "translateY(0rem) scale(1)" },
        },
        "alert-leave": {
          "0%": { opacity: "1", transform: "translateY(0rem) scale(1)" },
          "100%": { opacity: "0", transform: "translateY(1rem) scale(0.97)" },
        },
        "dialog-enter": {
          "0%": { opacity: "0", transform: "translateX(2rem) scale(1.03)" },
          "100%": { opacity: "1", transform: "translateX(0rem) scale(1)" },
        },
        "dialog-leave": {
          "0%": { opacity: "1", transform: "translateX(0rem) scale(1)" },
          "100%": { opacity: "0", transform: "translateX(2rem) scale(1.03)" },
        },
        "popover-enter": {
          "0%": { opacity: "0", transform: "scale(0.95)" },
          "100%": { opacity: "1", transform: "scale(1)" },
        },
        "popover-leave": {
          "0%": { opacity: "1", transform: "scale(1)" },
          "100%": { opacity: "0", transform: "scale(0.95)" },
        },
        "spin-reverse": {
          "100%": { transform: "rotate(-360deg)" },
        },
        "tooltip-enter": {
          "0%": { opacity: "0", transform: "scale(0.9)" },
          "100%": { opacity: "1", transform: "scale(1)" },
        },
        "tooltip-leave": {
          "0%": { opacity: "1", transform: "scale(1)" },
          "100%": { opacity: "0", transform: "scale(0.9)" },
        },
      },

      opacity: {
        2: 0.02,
      },

      scale: {
        97: "0.97",
      },

      screens: {
        "collapse-sidebar": "1250px",
      },

      spacing: {
        4.5: "1.125rem", // 18px
        6.5: "1.625rem", // 26px
        15: "3.75rem", // 60px
        68: "17rem",
        "staff-bar": "var(--staff-bar,0px)",
        sidebar: "16rem",
        "record-panel": "26rem", // 416px
      },

      transitionTimingFunction: {
        "out-expo": "cubic-bezier(0.16, 1, 0.3, 1)",
        "out-smooth": "cubic-bezier(0.165, 0.84, 0.44, 1)",
      },

      transitionDelay: {
        75: "75ms",
      },

      transitionDuration: {
        250: "250ms",
        350: "350ms",
        400: "400ms",
      },

      zIndex: {
        max: 9999,
        "max-overlay": 10000,
      },
    },
  },
  plugins: [
    ExtractColorsPlugin({
      input: "./src/index.scss",
    }),
    ToDesktopPlugin,
    AriaPlugin,
    IsolateGroupPlugin,
  ],
};
