import * as RadixToggle from "@radix-ui/react-toggle";
import { AnimatePresence, m } from "framer-motion";
import { useId } from "react";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { cn } from "~/lib/cn";
import { easeOutExpo } from "~/lib/motion";

export interface ToggleProps {
  value: boolean;
  onValueChange(value: boolean): void;
  disabled?: boolean;
  readOnly?: boolean;
  id?: string;
}

export function Toggle({
  value,
  onValueChange,
  disabled,
  readOnly,
  id,
}: ToggleProps) {
  return (
    <RadixToggle.Root
      asChild
      id={id}
      disabled={readOnly}
      aria-readonly={readOnly}
      aria-disabled={disabled}
      pressed={value}
      onPressedChange={(value) => {
        if (disabled) return;
        onValueChange(value);
      }}
    >
      <button
        className={cn(
          "w-[2.125rem] h-5 rounded-xl flex items-center justify-center",
          "aria-disabled:opacity-40 aria-disabled:cursor-not-allowed",
          "transition-colors duration-250 ease-out-expo",
          value ? "bg-success" : "bg-avatar",
        )}
      >
        <AnimatePresence initial={false}>
          <m.span
            className="flex w-3.5 h-3.5 rounded-full bg-main shadow"
            initial={{ x: value ? "50%" : "-50%" }}
            animate={{ x: value ? "50%" : "-50%" }}
            transition={{ ease: easeOutExpo, duration: 0.25 }}
          />
        </AnimatePresence>
      </button>
    </RadixToggle.Root>
  );
}

export interface ToggleFieldProps {
  value: boolean;
  onValueChanged(value: boolean): void;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
}

export function ToggleField({
  value,
  onValueChanged,
  disabled,
  label,
  readOnly,
}: ToggleFieldProps) {
  const id = useId();

  return (
    <div className="flex items-center gap-4">
      <FieldLabel htmlFor={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <Toggle
        value={value}
        onValueChange={onValueChanged}
        disabled={disabled}
        readOnly={readOnly}
        id={id}
      />
    </div>
  );
}
