import { useEffect, useRef, useState } from "react";

import { Button } from "~/components/Button";
import { OtpField } from "~/components/OtpField";
import { withAuth } from "~/lib/auth";
import { useCurrentUser } from "~/lib/current";
import {
  useResendVerificationMutation,
  useSignOutMutation,
  useVerifyMutation,
} from "~/mutations";
import { useNavigate } from "~/router";
import { Logo } from "~/svgs/Logo";

function Verify() {
  const NUM_CODE_DIGITS = 6;
  const COUNTDOWN_SECONDS = 60;

  const me = useCurrentUser();

  const formRef = useRef<HTMLFormElement>(null);
  const [code, setCode] = useState("");
  const [codeCountdown, setCodeCountdown] = useState(COUNTDOWN_SECONDS);

  const navigate = useNavigate();
  const verify = useVerifyMutation();
  const resendVerification = useResendVerificationMutation();
  const signOut = useSignOutMutation();

  useEffect(() => {
    if (codeCountdown === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setCodeCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [codeCountdown]);

  useEffect(() => {
    if (code.length === NUM_CODE_DIGITS) {
      formRef.current?.requestSubmit();
    }
  }, [code]);

  // if (me?.email_verified) {
  //   return <Navigate to="/" replace />;
  // }

  const resendCode = () => {
    resendVerification.mutate(
      {},
      {
        onSuccess() {
          setCodeCountdown(COUNTDOWN_SECONDS);
          setCode("");
        },
      },
    );
  };

  return (
    <div className="flex-1 flex flex-col p-3 items-start">
      <Button
        variant="subtle"
        size="xs"
        onClick={() => {
          if (me) {
            signOut.mutate({ user_id: me.id });
          }
        }}
      >
        Sign out
      </Button>

      <div className="gap-4 flex-1 flex flex-col justify-center mx-auto w-full max-w-md">
        <div className="flex flex-col gap-1.5 text-icon w-full">
          <div className="w-6 aspect-square text-icon-subtle">
            <Logo />
          </div>
          <h1 className="text-lg font-medium text-primary">Check your email</h1>
          <p className="text-sm text-placeholder">
            Enter the verification code we sent to you
          </p>
        </div>

        <div className="w-full mt-1">
          <form
            ref={formRef}
            className="flex flex-col gap-y-2 mt-2"
            onSubmit={(evt) => {
              evt.preventDefault();
              verify.mutate(
                { code },
                {
                  onSuccess() {
                    navigate("/", { replace: true });
                  },
                },
              );
            }}
          >
            <OtpField
              value={code}
              onValueChange={setCode}
              numInputs={NUM_CODE_DIGITS}
            />

            <button
              type="submit"
              className="sr-only"
              disabled={code.length !== NUM_CODE_DIGITS}
            >
              Submit
            </button>
          </form>
          <div className="mt-11">
            <p className="text-sm text-placeholder inline">
              Didn't receive a code?
            </p>{" "}
            {codeCountdown != 0 ? (
              <p className="text-sm text-secondary inline">
                Try again in {codeCountdown}s
              </p>
            ) : (
              <button className="text-sm text-primary" onClick={resendCode}>
                Resend code
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const ProtectedVerify = withAuth(Verify, { type: "pending_or_authenticated" });

export default ProtectedVerify;
