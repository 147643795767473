import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, m } from "framer-motion";
import { useRef } from "react";

import { RecordActivity } from "~/components/_records/RecordActivity";
import { RecordProperties } from "~/components/_records/RecordProperties";
import { useRecordSidebarState } from "~/components/_records/RecordSidebar/store";
import { Wrap } from "~/components/Wrap";
import { useScreenMatches } from "~/lib/breakpoint";
import { cn } from "~/lib/cn";
import { useFlagEnabled } from "~/lib/flags";
import { easeOutSmooth } from "~/lib/motion";

export function RecordSidebar() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useRecordSidebarState();
  const isCollapsible = useScreenMatches("collapse-sidebar");

  const hasRecordSidebar = useFlagEnabled("record_sidebar");
  if (!hasRecordSidebar) return null;

  return (
    <AnimatePresence initial={false}>
      <m.div
        className="shrink-0 max-collapse-sidebar:!w-0"
        animate={{
          width: open ? "26rem" : "0",
          display: open ? "block" : "none",
        }}
        transition={{ duration: isCollapsible ? 0 : 0.2, ease: easeOutSmooth }}
      >
        <Wrap
          condition={isCollapsible}
          wrap={(children) => (
            <Dialog.Root open={open} onOpenChange={setOpen}>
              <AnimatePresence initial={false}>
                {open && (
                  <Dialog.Portal forceMount>
                    <Dialog.Overlay asChild>
                      <m.div
                        className="z-50 fixed inset-0 bg-reverse/60"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { duration: 0.1 } }}
                        transition={{ ease: easeOutSmooth, duration: 0.2 }}
                      />
                    </Dialog.Overlay>
                    <Dialog.Content
                      ref={contentRef}
                      className="z-50"
                      tabIndex={-1}
                      asChild
                      onOpenAutoFocus={(evt) => {
                        evt.preventDefault();
                        contentRef.current?.focus();
                      }}
                    >
                      {children}
                    </Dialog.Content>
                  </Dialog.Portal>
                )}
              </AnimatePresence>
            </Dialog.Root>
          )}
        >
          <m.aside
            className={cn(
              "w-record-panel h-full max-h-100vh flex flex-col border-l border-primary divide-y divide-y-primary",
              "fixed top-0 right-0 z-20 bg-main max-collapse-sidebar:shadow-sidebar",
            )}
            initial={isCollapsible ? { x: "26rem" } : { x: 0 }}
            animate={{ x: !open ? "26rem" : 0 }}
            exit={isCollapsible ? { x: "26rem" } : { x: 0 }}
            transition={{ duration: 0.2, ease: easeOutSmooth }}
          >
            <RecordProperties />
            <RecordActivity />
          </m.aside>
        </Wrap>
      </m.div>
    </AnimatePresence>
  );
}
