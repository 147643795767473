import OtpInput from "react-otp-input";

import { cn } from "~/lib/cn";

export interface OtpFieldProps {
  value: string;
  onValueChange(value: string): void;
  numInputs: number;
}

export function OtpField({ value, onValueChange, numInputs }: OtpFieldProps) {
  return (
    <div className="flex flex-col gap-2">
      <OtpInput
        value={value}
        onChange={onValueChange}
        numInputs={numInputs}
        containerStyle="gap-2.5"
        shouldAutoFocus={true}
        renderInput={(props) => (
          <input
            {...props}
            className={cn(
              "min-w-12 min-h-16 bg-action px-2.5 py-2 rounded-xl ring-1 ring-inset ring-action shadow",
              "text-primary text-xl placeholder:text-placeholder",
              "focus:outline-none focus:ring-action-active focus:bg-action-active",
            )}
          />
        )}
      />
    </div>
  );
}
