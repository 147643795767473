import { copyRecordIdAction, copyRecordUrlAction } from "~/actions/record";
import { SidebarToggle } from "~/components/_records/RecordSidebar/SidebarToggle";
import { ActionIconButton } from "~/components/IconButton";
import { useCurrentRecord } from "~/lib/current";

export function RecordProperties() {
  const record = useCurrentRecord();

  return (
    <section className="pl-4 pr-3 py-3.5 -mb-px gap-4 flex items-center justify-between">
      <h2 className="text-sm font-medium">Properties</h2>

      <div className="flex items-center gap-2">
        <ActionIconButton action={copyRecordUrlAction} target={record} />
        <ActionIconButton action={copyRecordIdAction} target={record} />
        <SidebarToggle />
      </div>
    </section>
  );
}
