import { SVGProps } from "react";

import { useRecordSidebarState } from "~/components/_records/RecordSidebar/store";
import { IconButton } from "~/components/IconButton";
import { Sidebar } from "~/svgs/Sidebar";

function SidebarIcon(props: SVGProps<SVGElement>) {
  const [open] = useRecordSidebarState();
  return <Sidebar {...props} open={open} />;
}

export function SidebarToggle() {
  const [open, setOpen] = useRecordSidebarState();
  return (
    <IconButton
      icon={SidebarIcon}
      accessibilityLabel="Toggle sidebar"
      onClick={() => setOpen(!open)}
      aria-pressed={open}
    />
  );
}
