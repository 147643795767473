import {
  Model,
  ModelField,
  ModelFieldBooleanView,
  ModelFieldDateView,
  ModelFieldJsonView,
  ModelFieldNumberView,
  ModelFieldOneReferenceView,
  ModelFieldStringView,
  ModelRecord,
} from "api-client";

import { getFieldSchema } from "~/lib/schema";

export type FieldType<F extends ModelField> = F extends ModelFieldNumberView
  ? number
  : F extends ModelFieldStringView
    ? string
    : F extends ModelFieldBooleanView
      ? boolean
      : F extends ModelFieldDateView
        ? string
        : F extends ModelFieldJsonView
          ? any
          : F extends ModelFieldOneReferenceView
            ? ModelRecord | null
            : F extends ModelFieldOneReferenceView
              ? (ModelRecord | null)[]
              : unknown;

export function get<Field extends ModelField>(
  object: Record<string, any>,
  field: Field,
): FieldType<Field> | undefined {
  const schema = getFieldSchema(field);
  const result = schema?.safeParse(object?.[field.field_name]);
  return result?.success ? result.data : undefined;
}

export function getDefaultValue(field: ModelField) {
  switch (field.field_type) {
    case "string":
      return "";
    case "number":
      return 0;
    case "boolean":
      return false;
    case "date":
      return new Date().toISOString();
    case "json":
      return null;
    case "reference":
      if (field.reference_type === "to_one") {
        return null;
      } else {
        return [];
      }
  }
}

export function getDefaultRecord(model: Model | null, fields: ModelField[]) {
  const modelRecord: ModelRecord = {
    type: "ModelRecord",
    model_id: model?.id ?? "",
    model_name: model?.model_name ?? "",
    organization_slug: model?.organization_slug ?? "",
    application_slug: model?.application_slug ?? "",
    id: "",
    title: null,
    thumbnail: null,
    created_at: null,
    updated_at: null,
    data: {},
    viewer_favorited_at: null,
    pusher_channel: "private-",
    pusher_presence_channel: "presence-",
  };

  fields.forEach((field) => {
    modelRecord.data[field.field_name] = getDefaultValue(field);
  });

  return modelRecord;
}
