// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/forgot-password`
  | `/form-kitchen-sink`
  | `/invite/:token`
  | `/o/:org`
  | `/o/:org/w/:app`
  | `/o/:org/w/:app/m/:model`
  | `/o/:org/w/:app/m/:model/new`
  | `/o/:org/w/:app/m/:model/r/:record`
  | `/org`
  | `/reset-password`
  | `/settings`
  | `/settings/o/:org`
  | `/settings/o/:org/members`
  | `/settings/o/:org/w/:app`
  | `/settings/o/:org/w/:app/security`
  | `/signin`
  | `/signup`
  | `/verify`

export type Params = {
  '/invite/:token': { token: string }
  '/o/:org': { org: string }
  '/o/:org/w/:app': { org: string; app: string }
  '/o/:org/w/:app/m/:model': { org: string; app: string; model: string }
  '/o/:org/w/:app/m/:model/new': { org: string; app: string; model: string }
  '/o/:org/w/:app/m/:model/r/:record': { org: string; app: string; model: string; record: string }
  '/settings/o/:org': { org: string }
  '/settings/o/:org/members': { org: string }
  '/settings/o/:org/w/:app': { org: string; app: string }
  '/settings/o/:org/w/:app/security': { org: string; app: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
