import { Plus } from "@untitled-ui/icons-react";
import {
  organizationsModelsRecordsInfiniteQueryOptions,
  Where,
} from "api-client";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { create, useStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { ViewsPile } from "~/components/_presence/ViewsPile";
import { RecordTable } from "~/components/_records/RecordTable";
import { Button } from "~/components/Button";
import { Titlebar } from "~/components/Titlebar";
import { cn } from "~/lib/cn";
import { useCurrentModel } from "~/lib/current";
import { Params, useNavigate, useParams } from "~/router";

interface ModelFiltersStore {
  where: Where;
}
const createModelFiltersStore = (params: Params["/o/:org/w/:app/m/:model"]) =>
  create(
    persist<ModelFiltersStore>(() => ({ where: {} }), {
      name: `${params.org}-${params.app}-${params.model}:filters`,
      storage: createJSONStorage(() => localStorage),
    }),
  );

function whereToSearchParams(where: Where) {
  const search = new URLSearchParams();
  Object.entries(where).forEach(([key, value]) => {
    if (typeof value === "object") {
      Object.entries(value).forEach(([childKey, childValue]) => {
        search.set(`${key}.${childKey}`, childValue as any);
      });
    } else {
      search.set(key, value);
    }
  });
  return search;
}

function searchParamsToWhere(search: URLSearchParams): Where {
  const where: Record<string, any> = {};
  Array.from(search.keys()).forEach((key) => {
    if (key === "page") return;

    const value = search.get(key);
    if (value === undefined) return;

    const parsedValue = value === "null" ? null : value;

    if (key.includes(".")) {
      const [fieldName, operator] = key.split(".");
      where[fieldName] ||= {};
      where[fieldName][operator] = parsedValue;
    } else {
      where[key] = parsedValue;
    }
  });
  return where;
}

function ModelDetail() {
  const params = useParams("/o/:org/w/:app/m/:model");
  const model = useCurrentModel();
  const navigate = useNavigate();

  const modelFiltersStore = useMemo(
    () => createModelFiltersStore(params),
    [params],
  );
  const storedWhere = useStore(modelFiltersStore, (state) => state.where);

  const [search, setSearch] = useSearchParams(whereToSearchParams(storedWhere));

  const page = useMemo(() => {
    const value = Number.parseInt(search.get("page") ?? "1", 10);
    return Number.isNaN(value) ? 1 : value;
  }, [search]);
  const setPage = useCallback(
    (updater: number | ((current: number) => number)) => {
      const pageParam = typeof updater === "function" ? updater(page) : updater;

      if (pageParam === 1) {
        search.delete("page");
      } else {
        search.set("page", pageParam.toString());
      }
      setSearch(search, { replace: true });
    },
    [page, search, setSearch],
  );

  const where = useMemo(() => searchParamsToWhere(search), [search]);
  const [debouncedWhere] = useDebounce(where, 300, { leading: true });
  const setWhere = useCallback(
    (where: Record<string, any>) => {
      const newSearch = whereToSearchParams(where);
      const page = search.get("page");
      if (page) {
        newSearch.set("page", page);
      }
      setSearch(newSearch, { replace: true });
      modelFiltersStore.setState({ where });
    },
    [search, setSearch, modelFiltersStore],
  );

  return (
    <div className="flex-1 flex flex-col">
      <Titlebar>
        <ViewsPile model={model} />
      </Titlebar>
      <div className="pt-0.5 flex-1 flex flex-col">
        <header className="px-4 flex items-center justify-between gap-8">
          <h1 className={cn("text-2xl font-semibold")}>{model?.name}</h1>
        </header>

        <div className="flex-1 flex flex-col p-4 pt-3">
          <RecordTable
            models={model ? [model] : []}
            where={where}
            onWhereChange={setWhere}
            page={page}
            onPageChange={setPage}
            queryOptions={{
              ...organizationsModelsRecordsInfiniteQueryOptions({
                organizationSlug: params.org,
                modelId: params.model,
                where: debouncedWhere,
              }),
              enabled: !!params.org && !!params.model,
            }}
            onSelect={(event) => {
              navigate("/o/:org/w/:app/m/:model/r/:record", {
                params: {
                  org: event.record.organization_slug,
                  app: event.record.application_slug,
                  model: event.record.model_id,
                  record: event.record.id,
                },
              });
            }}
            actions={
              <Button
                size="sm"
                to="/o/:org/w/:app/m/:model/new"
                params={params}
                icon={Plus}
                variant="secondary"
              >
                New {model?.names.singular}
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ModelDetail;
