import { FormEvent, PropsWithChildren } from "react";

export interface FormProps extends PropsWithChildren {
  className?: string;
  onSubmit(event: FormEvent<HTMLFormElement>): void;
}

export function Form({ children, className, onSubmit }: FormProps) {
  return (
    <form
      className={className}
      onSubmit={onSubmit}
      onKeyDownCapture={(evt) => {
        if (evt.key === "Enter" && evt.metaKey) {
          evt.preventDefault();
          evt.currentTarget.requestSubmit();
        }
      }}
    >
      {children}
    </form>
  );
}
