import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Lock02 } from "@untitled-ui/icons-react";
import {
  createOrganizationsApplicationsPresignedPost,
  updateApplicationMutationOptions,
  useOrganizationsApplicationQuery,
} from "api-client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SyncStepper } from "~/components/_applications/SyncStepper";
import { Form } from "~/components/_forms/Form";
import { AppIcon } from "~/components/AppIcon";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { FileField } from "~/components/FileField";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { cn } from "~/lib/cn";
import { uploadFileMutationOptions } from "~/mutations";
import { Link, useParams } from "~/router";

const AppSettingsFormSchema = z.object({
  name: z.string().min(1),
  avatar_key: z.string().nullable().optional(),
  avatar_url: z.string().nullable(),
});

type AppSettingsFormSchema = z.infer<typeof AppSettingsFormSchema>;

function AppSettings() {
  const params = useParams("/settings/o/:org/w/:app");
  const applicationQuery = useOrganizationsApplicationQuery({
    slug: params.app,
    organizationSlug: params.org,
  });
  const application = applicationQuery.data ?? null;

  const uploadFileMutation = useMutation(uploadFileMutationOptions());

  const defaultValues = useMemo(
    () => ({
      name: application?.name ?? "",
      avatar_key: undefined,
      avatar_url: application?.avatar_url ?? null,
    }),
    [application],
  );

  const methods = useForm<AppSettingsFormSchema>({
    resolver: zodResolver(AppSettingsFormSchema),
    defaultValues,
  });

  const updateApplicationMutation = useMutation(
    updateApplicationMutationOptions(),
  );
  const preventSubmit =
    uploadFileMutation.isPending ||
    !methods.formState.isValid ||
    !methods.formState.isDirty;

  const submit = methods.handleSubmit((data) => {
    if (preventSubmit || updateApplicationMutation.isPending) return;
    updateApplicationMutation.mutate({
      slug: params.app,
      organizationSlug: params.org,
      ...data,
    });
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="team">
            <Link className="p-1" to="/settings/o/:org/w/:app" params={params}>
              <span className={cn("flex items-center gap-2")}>
                <AppIcon className="w-5 h-5" application={application} />
                <p className="whitespace-nowrap">{application?.name}</p>
                {application?.visibility === "private" && (
                  <Lock02 className={cn("w-3.5 h-3.5 text-icon")} />
                )}
              </span>
            </Link>
          </BreadcrumbItem>,
        ]}
      />
      <div className="p-4 pt-1 flex flex-col flex-1 gap-4">
        <h1 className="font-semibold text-2xl">
          {application?.name ?? (
            <span className="text-placeholder">Workspace</span>
          )}
        </h1>

        <Form onSubmit={submit} className="flex flex-col gap-4 max-w-lg">
          <TextField
            name="name"
            label="Name"
            control={methods.control}
            placeholder="My Workspace"
          />

          <FileField
            label="Avatar"
            busy={uploadFileMutation.isPending}
            accept={["image"]}
            maxSizeMb={2}
            value={methods.watch("avatar_url")}
            onValueChange={(value) => {
              if (!value) {
                methods.setValue("avatar_key", null, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                methods.setValue("avatar_url", null, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              } else {
                uploadFileMutation.mutate(
                  {
                    file: value,
                    presign: () =>
                      createOrganizationsApplicationsPresignedPost({
                        organizationSlug: params.org,
                        applicationSlug: params.app,
                        mime_type: value.type,
                      }),
                  },
                  {
                    onSuccess: (key) => {
                      methods.setValue("avatar_key", key, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      methods.setValue(
                        "avatar_url",
                        URL.createObjectURL(value),
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      );
                    },
                  },
                );
              }
            }}
          />

          <Button
            type="submit"
            disabled={preventSubmit}
            busy={updateApplicationMutation.isPending}
          >
            Save
          </Button>
        </Form>

        {application?.latest_sync && (
          <section className="flex flex-col p-4 gap-3 max-w-lg bg-panel rounded-xl border border-primary shadow mt-3">
            <h2 className="font-medium">Latest sync</h2>
            <SyncStepper sync={application.latest_sync} />
          </section>
        )}
      </div>
    </div>
  );
}

export default AppSettings;
