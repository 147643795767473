import { useId } from "react";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { cn } from "~/lib/cn";

export interface NumberFieldProps {
  name?: string;
  placeholder?: string;
  min?: number | null;
  max?: number | null;
  step?: number | null;
  value: number | string;
  onValueChange(value: string): void;
  label: string;
  hideLabel?: boolean;
  readOnly?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
}

export function NumberField({
  name,
  placeholder,
  min,
  max,
  step,
  value,
  onValueChange,
  label,
  hideLabel,
  readOnly,
  size = "lg",
}: NumberFieldProps) {
  const id = useId();

  return (
    <div className="flex flex-col gap-2">
      <FieldLabel hidden={hideLabel} htmlFor={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <input
        className={cn(
          {
            "px-3.5 h-11 gap-2": size === "lg",
            "px-3.5 h-10 gap-2": size === "md",
            "px-3 h-9 text-[15px] gap-1.5": size === "sm",
            "px-3 h-8 text-[15px] gap-1.5": size === "xs",
          },
          "min-w-0 flex w-full",
          "bg-action text-primary px-3.5 py-2 rounded-xl border border-action shadow",
          "focus:outline-none focus-within:border-action-active focus-within:bg-action-active",
        )}
        id={id}
        name={name}
        type="number"
        min={min ?? undefined}
        max={max ?? undefined}
        step={step ?? undefined}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={(evt) => {
          onValueChange(evt.target.value);
        }}
        readOnly={readOnly}
      />
    </div>
  );
}
