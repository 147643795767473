import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateInviteMutation } from "api-client";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { Select } from "~/components/Select";
import { TextField } from "~/components/TextField";
import { handleFormErrors } from "~/lib/handleFormErrors";

const InviteFormSchema = z.object({
  recipient_email: z.string().email(),
  role: z.enum(["member"]),
});

type InviteFormSchema = z.infer<typeof InviteFormSchema>;

export interface InviteFormProps {
  org: string;
}

export function InviteForm({ org }: InviteFormProps) {
  const invite = useCreateInviteMutation();

  const methods = useForm<InviteFormSchema>({
    resolver: zodResolver(InviteFormSchema),
    defaultValues: {
      recipient_email: "",
      role: "member",
    },
  });

  const submit = methods.handleSubmit((data) => {
    invite.mutate(
      {
        recipient_email: data.recipient_email,
        organizationSlug: org,
      },
      {
        onError(error) {
          handleFormErrors(error, methods.setError);
        },
        onSuccess() {
          methods.reset();
        },
        onSettled() {
          methods.setFocus("recipient_email");
        },
      },
    );
  });

  return (
    <form
      onSubmit={submit}
      className="grid gap-2 grid-cols-[4fr,2fr,1fr] items-end"
    >
      <div className="flex flex-col gap-1">
        <TextField
          name="recipient_email"
          label="Email"
          control={methods.control}
          type="email"
          placeholder="josh@acme.com"
          errorMessages={{
            taken: "This email has already been invited.",
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Select
          name="role"
          options={["member"]}
          value="member"
          onValueChange={() => {}}
          label="Role"
        />
      </div>
      <div className="flex flex-col">
        <Button size="lg" type="submit" disabled={!methods.formState.isValid}>
          Invite
        </Button>
      </div>
    </form>
  );
}
