import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, ModelFieldNumberView } from "api-client";
import { Fragment, useRef } from "react";

import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { NumberField } from "~/components/NumberField";
import { cn } from "~/lib/cn";
import { UnionKeys } from "~/types";

const NUMBER_OPERATORS = {
  eq: (value) => (value !== null ? "equals" : "has no value"),
  neq: (value) => (value !== null ? "not equals" : "has value"),
  gt: "greater than",
  gte: "greater than or equals",
  lt: "less than",
  lte: "less than or equals",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<number>>,
  string | ((value: number | null) => string)
>;

export interface NumberFieldFilterProps {
  field: ModelFieldNumberView;
  value: Filter<number>;
  onValueChange: (value: Filter<number>) => void;
  onRemove: () => void;
}

export function NumberFieldFilter({
  field,
  value,
  onValueChange,
  onRemove,
}: NumberFieldFilterProps) {
  const number = Object.values(value)[0];
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;

  const getOperatorLabel = (
    operator: UnionKeys<typeof value>,
    overrideNumber?: number | null,
  ) => {
    const operatorLabel = NUMBER_OPERATORS[operator];
    return typeof operatorLabel === "function"
      ? operatorLabel(overrideNumber !== undefined ? overrideNumber : number)
      : operatorLabel;
  };

  // Store last selected number (to keep in memory between `null` values)
  const lastSelectedNumberRef = useRef<number | null>(null);
  if (number !== null) {
    lastSelectedNumberRef.current = number;
  }
  const lastSelectedNumber = lastSelectedNumberRef.current ?? 1;
  const currentNumber = number ?? lastSelectedNumber;

  return (
    <div
      className={cn(
        "contents",
        "items-center rounded-xl h-9",
        "relative overflow-hidden",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2">
        <Icon
          className="w-4 h-4 text-icon"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar" />}
        />
        <p className="text-sm whitespace-nowrap font-medium">
          {field.names.camelized_singular}
        </p>
      </div>

      <DropdownMenu
        modal
        value={getOperatorLabel(operator)}
        align="start"
        trigger={
          <button
            className={cn(
              "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
              "flex items-center gap-1.5",
              "outline-none focus-visible:bg-action-active data-[state=open]:bg-action-active",
            )}
          >
            <p className="text-sm truncate flex-1 text-left">
              {getOperatorLabel(operator)}
            </p>
            <ChevronDown className="w-4 h-4 text-icon" />
          </button>
        }
      >
        {field.nullable && (
          <Fragment>
            <DropdownItem onSelect={() => onValueChange({ neq: null })}>
              {getOperatorLabel("neq", null)}
            </DropdownItem>

            <DropdownItem onSelect={() => onValueChange({ eq: null })}>
              {getOperatorLabel("eq", null)}
            </DropdownItem>
          </Fragment>
        )}

        <DropdownItem onSelect={() => onValueChange({ lt: currentNumber })}>
          {getOperatorLabel("lt", currentNumber)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ lte: currentNumber })}>
          {getOperatorLabel("lte", currentNumber)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ eq: currentNumber })}>
          {getOperatorLabel("eq", currentNumber)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ neq: currentNumber })}>
          {getOperatorLabel("neq", currentNumber)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ gt: currentNumber })}>
          {getOperatorLabel("gt", currentNumber)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ gte: currentNumber })}>
          {getOperatorLabel("gte", currentNumber)}
        </DropdownItem>
      </DropdownMenu>

      <div className="flex-1">
        {number !== null && (
          <NumberField
            label="Filter"
            hideLabel
            size="sm"
            step={field.step}
            min={field.min}
            max={field.max}
            placeholder="value"
            value={currentNumber}
            onValueChange={(value) => {
              onValueChange({ [operator]: value } as any as Filter<number>);
            }}
          />
        )}
      </div>

      <div className="px-0.5 flex items-center">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
