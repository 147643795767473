import {
  animate,
  m,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { PropsWithChildren, ReactNode } from "react";

import {
  ApplicationBreadcrumbItem,
  Breadcrumbs,
  ModelBreadcrumbItem,
  RecordBreadcrumbItem,
} from "~/components/Breadcrumbs";
import {
  useCurrentApplication,
  useCurrentModel,
  useCurrentRecord,
} from "~/lib/current";
import { usePathname } from "~/lib/pathname";

export interface TitlebarProps extends PropsWithChildren {
  breadcrumbs?: ReactNode;
}

export function Titlebar({ breadcrumbs, children }: TitlebarProps) {
  const pathname = usePathname();

  const application = useCurrentApplication();
  const showApplicationBreadcrumb = pathname?.startsWith("/o/:org/w/:app");

  const model = useCurrentModel();
  const showModelBreadcrumb = pathname?.startsWith("/o/:org/w/:app/m/:model");

  const record = useCurrentRecord();
  const showRecordBreadcrumb = pathname?.startsWith(
    "/o/:org/w/:app/m/:model/r/:record",
  );

  const scroll = useScroll({
    axis: "y",
  });
  const borderOpacity = useMotionValue(0);
  useMotionValueEvent(scroll.scrollY, "change", (value) => {
    if (value > 0) {
      animate(borderOpacity, 1, { duration: 0.2 });
    } else {
      borderOpacity.stop();
      borderOpacity.set(0);
    }
  });

  return (
    <header className="sticky top-0 bg-main z-10 pb-3 w-full overflow-hidden">
      <div className="pt-3 px-2 flex w-full overflow-hidden todesktop:app-drag">
        <div className="flex items-center justify-between w-full overflow-hidden pl-1 pr-2 gap-8 todesktop:has-[button[data-state=open]]:app-no-drag">
          <div className="todesktop:app-no-drag flex overflow-hidden">
            <Breadcrumbs>
              {application && showApplicationBreadcrumb && (
                <ApplicationBreadcrumbItem application={application} />
              )}
              {model && showModelBreadcrumb && (
                <ModelBreadcrumbItem model={model} />
              )}
              {record && showRecordBreadcrumb && (
                <RecordBreadcrumbItem record={record} />
              )}
              {breadcrumbs}
            </Breadcrumbs>
          </div>

          <div className="todesktop:app-no-drag">{children}</div>
        </div>

        <m.div
          className="absolute inset-x-0 bottom-0 border-b border-primary"
          style={{ opacity: borderOpacity } as any}
        />
      </div>
    </header>
  );
}
