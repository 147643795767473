import { ApiModel } from "api-client";
import { AnimatePresence, m } from "framer-motion";
import {
  ButtonHTMLAttributes,
  ComponentType,
  forwardRef,
  PropsWithChildren,
  RefAttributes,
  SVGProps,
  useRef,
} from "react";

import { ExtractContext, PropsWithExtraContext } from "~/components/ActionMenu";
import { ActivityIndicator } from "~/components/ActivityIndicator";
import { apply, useAction, useActionContext } from "~/lib/actions";
import { ActionReactContext } from "~/lib/actions/context";
import { cn } from "~/lib/cn";
import { easeOutSmooth } from "~/lib/motion";
import { Link, Params, Path } from "~/router";
import { Action, Prettify } from "~/types";

export type ActionButtonProps<T extends ApiModel, A extends Action<T, any>> = {
  target: T | null;
  action: A;
  variant?: "primary" | "secondary" | "subtle";
  size?: "xs" | "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
} & PropsWithExtraContext<Prettify<ExtractContext<[A]>>>;

export function ActionButton<T extends ApiModel, A extends Action<T, any>>({
  action,
  target,
  variant,
  size,
  type,
  ...props
}: ActionButtonProps<T, A>) {
  const ref = useRef<HTMLButtonElement>(null);

  const context = useActionContext(target, props.context);
  const label = context ? apply(action.label)(context) : "";
  const actionVariant = context
    ? apply(action.variant ?? "primary")(context)
    : undefined;

  const [node, execute] = useAction(target, props.context, {
    onCleanup() {
      ref.current?.focus();
    },
  });

  if (action.applicable && !action.applicable?.(context)) return null;

  return (
    <ActionReactContext.Provider value={context}>
      {node}
      <Button
        ref={ref}
        type={type}
        icon={action.icon}
        variant={variant}
        size={size}
        danger={actionVariant === "danger"}
        onClick={() => execute(action)}
      >
        {label}
      </Button>
    </ActionReactContext.Provider>
  );
}

export type ButtonProps<
  P extends
    | Path
    | {
        pathname: Path;
        search?: string | undefined;
        hash?: string | undefined;
      },
> = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "subtle";
  danger?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  busy?: boolean;
}> &
  (P extends keyof Params
    ? { to: P; params: Params[P] }
    : P extends { pathname: keyof Params }
      ? { to: P; params?: Params[P["pathname"]] }
      : P extends Path
        ? { to: P; params?: never }
        : { to?: never; params?: never }) &
  ButtonHTMLAttributes<HTMLButtonElement>;

const InternalButton = forwardRef<HTMLButtonElement, ButtonProps<any>>(
  function Button(
    {
      children,
      variant = "primary",
      danger,
      size = "md",
      icon: Icon,
      busy,
      ...props
    },
    ref,
  ) {
    const variantClassName = cn([
      variant === "primary" &&
        "bg-reverse text-reverse ring-1 ring-inset shadow ring-reverse",
      variant === "secondary" && [
        "bg-action text-primary ring-1 ring-inset ring-action shadow enabled:hover:bg-action-active",
        "focus-visible:bg-action-active",
        "data-[state=open]:bg-action-active",
      ],
      variant === "subtle" &&
        "-m-0.5 focus-visible:bg-subtle hover:enabled:bg-subtle data-[state=open]:bg-subtle",
    ]);
    const dangerClassName = cn({
      "bg-danger-subtle text-danger ring-1 ring-inset ring-danger enabled:hover:bg-danger-subtle-active focus-visible:bg-danger-subtle-active":
        variant === "primary" || variant === "secondary",
      "text-danger focus-visible:bg-danger-subtle hover:enabled:bg-danger-subtle":
        variant === "subtle",
    });
    const sizeClassName = cn({
      "px-2 h-7 text-[13px] rounded-lg": size === "xs",
      "px-3 h-9 text-sm": size === "sm",
      "px-3.5 h-10 text-[15px]": size === "md",
      "px-3.5 h-11": size === "lg",
    });
    const className = cn(
      "flex items-center justify-center shrink-0 rounded-xl group/button",
      "font-medium overflow-hidden relative",
      "disabled:cursor-not-allowed disabled:opacity-40",
      "transition-opacity duration-100",
      "outline-none",
      variantClassName,
      danger && dangerClassName,
      sizeClassName,
      props.className,
    );

    const child = (
      <span className="flex items-center justify-center whitespace-nowrap">
        {variant === "primary" && (
          <span
            className={cn(
              "absolute inset-0 bg-gradient-to-b text-reverse from-current to-transparent",
              "opacity-0 group-hover/button:opacity-15 group-focus-visible/button:opacity-15",
              "transition-opacity duration-100",
            )}
          />
        )}
        <span
          className={cn(
            "flex items-center justify-center transition-opacity duration-100",
            {
              "gap-1.5": size === "xs",
              "gap-2": size === "sm" || size === "md" || size === "lg",
            },
            busy && "opacity-40",
          )}
        >
          {Icon && (
            <Icon
              className={cn(
                "text-icon shrink-0",
                {
                  "w-3.5 h-3.5": size === "xs",
                  "w-4 h-4": size === "sm" || size === "md" || size === "lg",
                },
                danger && "text-danger",
              )}
              aria-hidden="true"
            />
          )}
          <span className="flex items-center justify-center">{children}</span>
        </span>
        <AnimatePresence>
          {busy && (
            <m.span
              className="flex"
              aria-hidden="true"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "auto" }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ duration: 0.25, ease: easeOutSmooth }}
            >
              <span className="flex ml-2.5">
                <ActivityIndicator className="w-4 h-4" />
              </span>
            </m.span>
          )}
        </AnimatePresence>
      </span>
    );

    if (props.to) {
      return (
        <Link to={props.to as any} params={props.params} className={className}>
          {child}
        </Link>
      );
    } else {
      return (
        <button
          ref={ref}
          {...props}
          className={className}
          onClick={props.disabled ? undefined : props.onClick}
          onPointerDown={props.disabled ? undefined : props.onPointerDown}
          aria-busy={busy}
        >
          {child}
        </button>
      );
    }
  },
);

export const Button: <
  P extends
    | Path
    | {
        pathname: Path;
        search?: string | undefined;
        hash?: string | undefined;
      },
>(
  props: ButtonProps<P> & RefAttributes<HTMLButtonElement>,
) => JSX.Element = InternalButton as any;
