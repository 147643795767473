import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateOrganizationMutation } from "api-client";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { cn } from "~/lib/cn";
import { useCurrentUser } from "~/lib/current";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useSignOutMutation } from "~/mutations";
import { Navigate, useNavigate } from "~/router";
import { Logo } from "~/svgs/Logo";

const CreateOrganizationFormSchema = z.object({
  name: z.string(),
  slug: z.string().max(64, "URL must be 64 characters or less"),
  username: z.string(),
});

type CreateOrganizationForm = z.infer<typeof CreateOrganizationFormSchema>;

function CreateOrganization() {
  const me = useCurrentUser();
  const {
    formState: { errors, touchedFields },
    setError,
    resetField,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm<CreateOrganizationForm>({
    resolver: zodResolver(CreateOrganizationFormSchema),
    defaultValues: {
      name: "",
      slug: "",
      username: "",
    },
  });

  const navigate = useNavigate();
  const create = useCreateOrganizationMutation();
  const signOut = useSignOutMutation();

  const orgName = watch("name");
  const slug = watch("slug");

  useEffect(() => {
    if (touchedFields?.slug) {
      return;
    }

    if (!orgName) {
      resetField("slug");
      return;
    }

    setValue("slug", orgName.toLowerCase().replace(/\s/g, "-"));
  }, [orgName, setValue, touchedFields, resetField]);

  if (me && !me.email_verified) {
    return <Navigate to="/verify" replace />;
  }

  const onSubmit = handleSubmit(
    async ({ name, slug, username }: CreateOrganizationForm) => {
      create.mutate(
        { name, slug, organization_membership: { username } },
        {
          onSuccess(membership) {
            navigate("/o/:org", {
              params: { org: membership.organization.slug },
            });
          },
          onError: (error) => handleFormErrors(error, setError),
        },
      );
    },
  );

  return (
    <div className="flex-1 flex flex-col p-3 items-start">
      <Button
        variant="subtle"
        size="xs"
        onClick={() => {
          if (me) {
            signOut.mutate({ user_id: me.id });
          }
        }}
      >
        Sign out
      </Button>

      <div className="gap-4 flex-1 flex flex-col justify-center mx-auto w-full max-w-md">
        <div className="flex flex-col gap-1.5 text-icon w-full">
          <div className="w-6 aspect-square text-icon-subtle">
            <Logo />
          </div>
          <h1 className="text-lg font-medium text-primary">Create a team</h1>
          <p className="text-sm text-placeholder">
            Teams are shared spaces for applications.
          </p>
        </div>

        <div className="w-full mt-1">
          <form className="flex flex-col gap-3 mb-4" onSubmit={onSubmit}>
            <TextField
              name="name"
              label="Team name"
              type="text"
              placeholder="Acme Inc."
              control={control}
            />

            <TextField
              name="username"
              label="Username"
              type="text"
              placeholder="johnny"
              control={control}
            />

            <div className="flex flex-col gap-1.5">
              <TextField
                name="slug"
                label="URL"
                type="text"
                placeholder="acme"
                leadingAccessory={<p>baselayer.com/t/</p>}
                control={control}
              />
              <p
                className={cn(
                  "text-placeholder text-xs text-right",
                  slug.length > 64 && "!text-danger",
                )}
              >
                {slug.length} / 64
              </p>
            </div>

            <div className="flex flex-col mt-4">
              <Button size="lg">Create</Button>
            </div>
          </form>

          {errors.root && <ErrorMessage label="Form" error={errors.root} />}
        </div>
      </div>
    </div>
  );
}

const ProtectedCreateOrganization = withAuth(CreateOrganization, {
  type: "authenticated",
});

export default ProtectedCreateOrganization;
