import { useMemo } from "react";
import { FieldError } from "react-hook-form";

export type ErrorCode =
  | "taken"
  | "complexity"
  | "invalid"
  | "invalid_email_password"
  | "password_reset_error"
  | (string & {});

interface ErrorProps {
  label: string;
  error: Pick<FieldError, "message">;
  overrides?: Partial<Record<ErrorCode, string>>;
  id?: string;
}

export function ErrorMessage({ label, error, overrides, id }: ErrorProps) {
  const errorMessage = useMemo(() => {
    const code = error.message as ErrorCode | undefined;

    if (overrides && code && code in overrides) {
      return overrides[code];
    }

    switch (code) {
      case "taken":
        return `${label} is already taken`;
      case "complexity":
        return `${label} is not strong enough`;
      case "invalid":
        return `${label} is invalid`;
      case "invalid_email_password":
        return "Invalid email or password";
      case "password_reset_error":
        return "Link has expired, try resetting your password again.";
      default:
        return code;
    }
  }, [error.message, label, overrides]);

  return (
    <p className="text-xs text-danger text-left" aria-live="assertive" id={id}>
      {errorMessage}
    </p>
  );
}
