import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { createFeedbackMutationOptions } from "api-client";
import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { useLocation } from "react-router-dom";
import { z } from "zod";

import { Form } from "~/components/_forms/Form";
import { Button } from "~/components/Button";
import { Popover } from "~/components/Popover";
import { TextAreaField } from "~/components/TextAreaField";
import { cn } from "~/lib/cn";
import { useParams } from "~/router";
import { XTwitter } from "~/svgs/XTwitter";

const feedbackSchema = z.object({
  description: z.string().trim().min(1, ""),
});
type FeedbackSchema = z.infer<typeof feedbackSchema>;

export interface FeedbackPopoverProps {
  trigger?: ReactNode;
}

export function FeedbackPopover({ trigger }: FeedbackPopoverProps) {
  const [open, setOpen] = useState(false);
  const params = useParams("/o/:org/w/:app");
  const location = useLocation();

  const methods = useForm<FeedbackSchema>({
    resolver: zodResolver(feedbackSchema),
    defaultValues: {
      description: "",
    },
  });

  const createFeedbackMutation = useMutation(createFeedbackMutationOptions());

  const submit = methods.handleSubmit((data) => {
    createFeedbackMutation.mutate(
      {
        description: data.description,
        organization_slug: params.org,
        application_slug: params.app,
        pathname: location.pathname,
      },
      {
        onSuccess() {
          setOpen(false);
          methods.reset();
          toast.success("Your feedback was submitted. Thank you!");
        },
      },
    );
  });

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      modal
      dark={false}
      align="end"
      alignOffset={-8}
      sideOffset={14}
      side="right"
      trigger={trigger}
    >
      <Form onSubmit={submit} className="flex flex-col gap-2 p-2.5 w-[28rem]">
        <TextAreaField
          label="Feedback"
          hideLabel
          name="description"
          placeholder="Feedback, feature requests, bug reports..."
          control={methods.control}
          size="sm"
          rows={3}
          disabled={createFeedbackMutation.isPending}
        />

        <div className="flex items-center justify-between mt-px">
          <a
            className={cn(
              "flex items-center gap-1.5 group/link outline-none",
              "-mx-0.5 px-2 py-1.5 rounded-lg focus-visible:bg-subtle",
            )}
            href="https://x.com/baselayerapp"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baselayer on X (Twitter)"
          >
            <XTwitter className="w-4 h-4 text-icon group-hover/link:text-primary group-focus-visible/link:text-primary" />
            <span className="text-sm text-secondary group-hover/link:text-primary group-focus-visible/link:text-primary">
              @baselayerapp
            </span>
          </a>

          <Button
            size="sm"
            busy={createFeedbackMutation.isPending}
            disabled={!methods.formState.isValid}
          >
            Send feedback
          </Button>
        </div>
      </Form>
    </Popover>
  );
}
