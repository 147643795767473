import { useQuery } from "@tanstack/react-query";
import {
  ModelFieldOneReferenceView,
  ModelRecord,
  organizationsModelsRecordQueryOptions,
} from "api-client";
import { forwardRef, ReactNode } from "react";

import { cn } from "~/lib/cn";
import { Link } from "~/router";

import { RecordThumbnail } from "./RecordThumbnail";

export interface RecordCardProps {
  actions?: ReactNode;
  field: ModelFieldOneReferenceView;
  value: ModelRecord;
}

export const RecordCard = forwardRef<HTMLDivElement, RecordCardProps>(
  function RecordCard({ actions, field, value: initialData, ...props }, ref) {
    const recordQuery = useQuery({
      ...organizationsModelsRecordQueryOptions({
        id: initialData.id,
        modelId: initialData.model_id,
        organizationSlug: initialData.organization_slug,
      }),
      initialData,
    });
    const value = recordQuery.data;

    const currentModel =
      field.models.find((model) => model.id === value?.model_id) ??
      field.models.at(0) ??
      null;

    return (
      <div
        ref={ref}
        className={cn(
          "flex items-center",
          "relative bg-action rounded-xl ring-1 ring-inset ring-action shadow",
          "hover:bg-action-active isolate-group-hover:bg-action-active",
          "data-[state=open]:bg-action-active has-[_a:focus-visible]:bg-action-active has-[_a[data-state=open]]:bg-action-active",
          "has-[_a:focus-visible]:ring-action-active",
        )}
        {...props}
      >
        <div className="flex flex-1 items-center justify-between pr-3.5">
          <Link
            to="/o/:org/w/:app/m/:model/r/:record"
            params={{
              org: value.organization_slug,
              app: value.application_slug,
              model: value.model_id,
              record: value.id,
            }}
            className="px-3.5 py-3 flex gap-2.5 outline-none before:absolute before:inset-0"
            onClick={(evt) => {
              if (evt.currentTarget.closest('[role="dialog"]')) {
                evt.preventDefault();
                evt.stopPropagation();
              }
            }}
          >
            <RecordThumbnail size="md" model={currentModel} record={value} />
            <p className="flex flex-wrap gap-x-1.5 pt-0.5 font-medium leading-snug">
              {value?.title ?? value?.id}
            </p>
          </Link>

          <div className="z-10 flex items-center gap-1.5 -mr-0.5">
            {actions}
          </div>
        </div>
      </div>
    );
  },
);
