import { Outlet } from "react-router-dom";

import { SettingsSidebar } from "~/components/Sidebar/Settings";

function SettingsLayout() {
  return (
    <div className="flex-1 flex">
      <SettingsSidebar />
      <div className="w-[calc(100vw-16rem)] flex">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsLayout;
