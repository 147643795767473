import { useOrganizationQuery } from "api-client";

import { InviteList } from "~/components/_settings/InviteList";
import { MemberList } from "~/components/_settings/MemberList";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { TeamAvatar } from "~/components/TeamAvatar";
import { Titlebar } from "~/components/Titlebar";
import { Link, useParams } from "~/router";

function OrgSettings() {
  const params = useParams("/settings/o/:org/members");
  const organizationQuery = useOrganizationQuery({ slug: params.org });
  const org = organizationQuery.data ?? null;

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="team">
            <Link className="p-1" to="/settings/o/:org" params={params}>
              <div className="flex items-center">
                <TeamAvatar size="xs" team={org} />
                <p className="text-sm font-medium ml-2">{org?.name ?? ""}</p>
              </div>
            </Link>
          </BreadcrumbItem>,
          <BreadcrumbItem key="members">
            <Link className="p-1" to="/settings/o/:org/members" params={params}>
              Members
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 flex flex-col flex-1 gap-4">
        <h1 className="font-semibold text-2xl">Members</h1>

        <div className="flex flex-col gap-4 max-w-2xl">
          <MemberList org={params.org} />

          <InviteList org={params.org} />
        </div>
      </div>
    </div>
  );
}

export default OrgSettings;
