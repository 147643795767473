import { Outlet } from "react-router-dom";

import { useParams } from "~/router";

function AppSettingsLayout() {
  const params = useParams("/settings/o/:org/w/:app");
  return <Outlet key={params.app} />;
}

export default AppSettingsLayout;
