import plugin from "tailwindcss/plugin";
export const IsolateGroupPlugin = plugin(function (api) {
  api.addVariant("isolate-group", ":merge(.group:not(:has(.group))) &");

  api.addVariant(
    "isolate-group-hover",
    ":merge(.group:not(:has(.group))):hover &",
  );

  api.addVariant(
    "isolate-group-focus",
    ":merge(.group:not(:has(.group))):focus &",
  );
});
