import { Organization } from "api-client";

import { cn } from "~/lib/cn";

const sizeMap = {
  xs: cn("w-5 h-5"),
  sm: cn("w-6 h-6"),
  md: cn("w-8 h-8"),
  lg: cn("w-10 h-10"),
};

export type TeamAvatarSize = keyof typeof sizeMap;

const textSizeMap: Record<TeamAvatarSize, string> = {
  xs: cn("text-3xs"),
  sm: cn("text-2xs"),
  md: cn("text-xs"),
  lg: cn("text-sm"),
};

export interface TeamAvatarProps {
  team: Organization | null;
  size?: TeamAvatarSize;
}

export function TeamAvatar({ team, size = "md" }: TeamAvatarProps) {
  const initials = team?.name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.at(0))
    .join("")
    .toUpperCase();

  return (
    <span className={cn("shrink-0 rounded-md bg-main", sizeMap[size])}>
      <span
        className={cn(
          "relative w-full h-full flex items-center justify-center",
        )}
      >
        <span className="absolute inset-0 rounded-md bg-avatar" />
        <span
          aria-hidden="true"
          className={cn(
            "relative font-medium text-secondary select-none touch-none",
            textSizeMap[size],
          )}
        >
          {initials}
        </span>
      </span>
    </span>
  );
}
