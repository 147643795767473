import { Check, Minus } from "@untitled-ui/icons-react";
import {
  ModelField,
  ModelFieldDateView,
  ModelFieldManyReferenceView,
  ModelFieldOneReferenceView,
  ModelRecord,
} from "api-client";
import { Fragment, useMemo } from "react";

import {
  RecordPreview,
  RecordPreviewLockup,
} from "~/components/_forms/RecordPreview";
import { formatDate, formatDateTime, formatTime } from "~/lib/date";

interface ReferenceFieldPreviewProps {
  value?: null | ModelRecord | number;
  field: ModelFieldOneReferenceView | ModelFieldManyReferenceView;
  interactive?: boolean;
}
function FieldReferencePreview({
  value,
  field,
  interactive = false,
}: ReferenceFieldPreviewProps) {
  const modelId = useMemo(() => {
    if (typeof value === "number") {
      return null;
    } else {
      return value?.model_id ?? null;
    }
  }, [value]);

  if (field.reference_type === "to_many") {
    if (typeof value === "number" && value) {
      return <span className="font-medium">{value}</span>;
    }

    return <Minus className="w-4 h-4 text-icon-subtle" />;
  }

  const model = field.models.find((model) => model.id === modelId);

  if (!value || typeof value === "number" || !model) {
    return <Fragment>&nbsp;</Fragment>;
  }

  const Component = interactive ? RecordPreview : RecordPreviewLockup;

  return <Component model={model} record={value} />;
}

interface DateFieldPreviewProps {
  value?: string;
  field: ModelFieldDateView;
}
function DateFieldPreview({ value, field }: DateFieldPreviewProps) {
  if (!value) {
    return <Fragment>&nbsp;</Fragment>;
  }

  switch (field.specificity) {
    case "date":
      return formatDate(value);
    case "datetime":
      return formatDateTime(value);
    case "time":
      return formatTime(value);
  }
}

interface BooleanFieldPreviewProps {
  value?: boolean | null;
}
function BooleanFieldPreview({ value }: BooleanFieldPreviewProps) {
  if (value === null || value === undefined) {
    return <Fragment>&nbsp;</Fragment>;
  }

  return (
    <span aria-label={value ? "True" : "False"}>
      {value ? (
        <Check className="w-4 h-4 text-icon" />
      ) : (
        <Minus className="w-4 h-4 text-icon-subtle" />
      )}
    </span>
  );
}

export interface FieldPreviewProps {
  value: any;
  field: ModelField;
  interactive?: boolean;
}

export function FieldPreview({
  value,
  field,
  interactive = false,
}: FieldPreviewProps) {
  switch (field.field_type) {
    case "string":
      return value ?? <Fragment>&nbsp;</Fragment>;
    case "number":
      return value?.toLocaleString() ?? <Fragment>&nbsp;</Fragment>;
    case "boolean":
      return <BooleanFieldPreview value={value} />;
    case "date":
      return (
        <span className="whitespace-nowrap">
          <DateFieldPreview value={value} field={field} />
        </span>
      );
    case "json":
      return JSON.stringify(value);
    case "reference":
      return (
        <FieldReferencePreview
          value={value}
          field={field}
          interactive={interactive}
        />
      );
    default:
      return <Fragment>&nbsp;</Fragment>;
  }
}

export interface FieldPreviewSkeletonProps {
  field: ModelField;
}
export function FieldPreviewSkeleton({ field }: FieldPreviewSkeletonProps) {
  if (field.field_type === "reference" && field.reference_type === "to_one") {
    return (
      <div className="flex flex-row items-center gap-2">
        <span className="flex w-6.5 h-6.5 -my-1 bg-avatar rounded-xl" />
        <div className="flex-1 h-4 my-1 rounded-md bg-subtle" />
      </div>
    );
  } else {
    return <div className="w-full h-4 rounded-md bg-subtle my-1" />;
  }
}
