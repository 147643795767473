import {
  differenceInMinutes,
  format,
  formatDistanceStrict,
  isSameDay,
  isSameYear,
  parseISO,
} from "date-fns";

export const formatDate = (date: Date | string) => {
  const iso = parseISO(typeof date === "string" ? date : date.toISOString());
  return format(iso, "MMM d, yyyy");
};

export const formatDateTime = (date: Date | string) =>
  format(date, "MMM d, yyyy 'at' h:mmaaa");

export const formatTime = (date: Date | string) => format(date, "h:mmaaa");

interface FormatTimestampOptions {
  capitalize?: boolean;
  addSuffix?: boolean;
}
const DEFAULT_FORMAT_TIMESTAMP_OPTIONS: Required<FormatTimestampOptions> = {
  capitalize: false,
  addSuffix: true,
};
export function formatTimestamp(
  timestamp: Date | string,
  options?: FormatTimestampOptions,
) {
  const now = new Date();

  if (differenceInMinutes(now, timestamp) < 1) {
    const capitalize =
      options?.capitalize ?? DEFAULT_FORMAT_TIMESTAMP_OPTIONS.capitalize;
    return capitalize ? "Just now" : "just now";
  }

  if (isSameDay(now, timestamp)) {
    const addSuffix =
      options?.addSuffix ?? DEFAULT_FORMAT_TIMESTAMP_OPTIONS.addSuffix;
    return formatDistanceStrict(timestamp, now, { addSuffix })
      .replace(/(\s*seconds?)/g, "s")
      .replace(/(\s*minutes?)/g, "m")
      .replace(/(\s*hours?)/g, "h")
      .replace(/(\s*days?)/g, "d");
  }

  if (isSameYear(timestamp, now)) {
    return format(timestamp, "MMM dd");
  }

  return formatDate(timestamp);
}
