import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, ModelFieldDateView } from "api-client";
import { Fragment, useRef } from "react";

import { DatePicker } from "~/components/DateInput";
import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { cn } from "~/lib/cn";
import { formatDate, formatDateTime, formatTime } from "~/lib/date";
import { UnionKeys } from "~/types";

const DATE_OPERATORS = {
  eq: (value) => (value ? "on" : "has no value"),
  neq: (value) => (value ? "not on" : "has value"),
  gt: "after",
  gte: "after or on",
  lt: "before",
  lte: "before or on",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<Date>>,
  string | ((value: Date | null) => string)
>;

const DATETIME_OPERATORS = {
  eq: (value) => (value ? "at" : "has no value"),
  neq: (value) => (value ? "not at" : "has value"),
  gt: "after",
  gte: "after or at",
  lt: "before",
  lte: "before or at",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<Date>>,
  string | ((value: Date | null) => string)
>;

export interface DateFieldFilterProps {
  field: ModelFieldDateView;
  value: Filter<Date>;
  onValueChange: (value: Filter<Date>) => void;
  onRemove: () => void;
}

export function DateFieldFilter({
  field,
  value,
  onValueChange,
  onRemove,
}: DateFieldFilterProps) {
  const date = Object.values(value)[0];
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;

  const operatorsMap =
    field.specificity === "date" ? DATE_OPERATORS : DATETIME_OPERATORS;

  const getOperatorLabel = (
    operator: UnionKeys<typeof value>,
    overrideDate?: Date | null,
  ) => {
    const operatorLabel = operatorsMap[operator];
    return typeof operatorLabel === "function"
      ? operatorLabel(overrideDate !== undefined ? overrideDate : date)
      : operatorLabel;
  };

  // Store last selected date (to keep in memory between `null` values)
  const lastSelectedDateRef = useRef<Date | null>(null);
  if (date) {
    lastSelectedDateRef.current = date;
  }
  const lastSelectedDate = lastSelectedDateRef.current ?? new Date();
  const currentDate = date ?? lastSelectedDate;

  return (
    <div
      className={cn(
        "contents",
        "items-center rounded-xl h-9",
        "relative overflow-hidden",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2">
        <Icon
          className="w-4 h-4 text-icon"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar" />}
        />
        <p className="text-sm whitespace-nowrap font-medium">
          {field.names.camelized_singular.replace(/\s+(at|on)$/i, "")}
        </p>
      </div>

      <DropdownMenu
        modal
        value={getOperatorLabel(operator)}
        align="start"
        trigger={
          <button
            className={cn(
              "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
              "flex items-center gap-1.5",
              "outline-none focus-visible:bg-action-active data-[state=open]:bg-action-active",
            )}
          >
            <p className="text-sm truncate flex-1 text-left">
              {getOperatorLabel(operator)}
            </p>
            <ChevronDown className="w-4 h-4 text-icon" />
          </button>
        }
      >
        {field.nullable && (
          <Fragment>
            <DropdownItem onSelect={() => onValueChange({ neq: null })}>
              {getOperatorLabel("neq", null)}
            </DropdownItem>

            <DropdownItem onSelect={() => onValueChange({ eq: null })}>
              {getOperatorLabel("eq", null)}
            </DropdownItem>
          </Fragment>
        )}

        <DropdownItem onSelect={() => onValueChange({ lt: currentDate })}>
          {getOperatorLabel("lt", currentDate)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ lte: currentDate })}>
          {getOperatorLabel("lte", currentDate)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ eq: currentDate })}>
          {getOperatorLabel("eq", currentDate)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ neq: currentDate })}>
          {getOperatorLabel("neq", currentDate)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ gt: currentDate })}>
          {getOperatorLabel("gt", currentDate)}
        </DropdownItem>

        <DropdownItem onSelect={() => onValueChange({ gte: currentDate })}>
          {getOperatorLabel("gte", currentDate)}
        </DropdownItem>
      </DropdownMenu>

      <div className="flex-1 overflow-hidden">
        {date && (
          <DatePicker
            modal
            trigger={
              <button
                className={cn(
                  "w-full px-2.5 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
                  "flex items-center gap-1.5",
                  "outline-none focus-visible:bg-action-active data-[state=open]:bg-action-active",
                )}
              >
                <p className="text-sm truncate flex-1 text-left">
                  {field.specificity === "date"
                    ? formatDate(date)
                    : field.specificity === "datetime"
                      ? formatDateTime(date)
                      : formatTime(date)}
                </p>
                <ChevronDown className="w-4 h-4 text-icon" />
              </button>
            }
            value={date}
            onValueChange={(value) => {
              if (!value) return;
              onValueChange({ [operator]: value } as Filter<Date>);
            }}
            type={field.specificity}
          />
        )}
      </div>

      <div className="px-0.5 flex items-center">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
