import { useMutation } from "@tanstack/react-query";

import {
  createFeedback,
  createInvitesAccept,
  createOrganization,
  createOrganizationsApplication,
  createOrganizationsApplicationsHmacSecret,
  createOrganizationsApplicationsSync,
  createOrganizationsInvite,
  createOrganizationsModelsFavorite,
  createOrganizationsModelsRecord,
  createOrganizationsModelsRecordsFavorite,
  createOrganizationsModelsRecordsView,
  createOrganizationsModelsView,
  deleteOrganizationsApplication,
  deleteOrganizationsApplicationsHmacSecret,
  deleteOrganizationsInvite,
  deleteOrganizationsMember,
  deleteOrganizationsModelsFavorite,
  deleteOrganizationsModelsRecord,
  deleteOrganizationsModelsRecordsFavorite,
  getOptimisticFavorite,
  getOptimisticOrganizationMembership,
  infiniteAppend,
  infiniteFilter,
  infinitePrepend,
  infiniteReorder,
  mutationOptions,
  organizationsApplicationQueryOptions,
  organizationsApplicationsFavoritesInfiniteQueryOptions,
  organizationsApplicationsInfiniteQueryOptions,
  organizationsInvitesInfiniteQueryOptions,
  organizationsMembersInfiniteQueryOptions,
  organizationsModelQueryOptions,
  organizationsModelsFieldsRecordsInfiniteQueryOptions,
  organizationsModelsRecordQueryOptions,
  organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions,
  organizationsModelsRecordsInfiniteQueryOptions,
  organizationsModelsRecordsViewsInfiniteQueryOptions,
  organizationsModelsViewsInfiniteQueryOptions,
  p,
  queryClient,
  updateOrganizationsApplication,
  updateOrganizationsApplicationsFavoritesReorder,
  updateOrganizationsModelsRecord,
  updateOrganizationsView,
  updateUsersMe,
  usersMeQueryOptions,
} from "..";

export function createOrganizationMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganization,
    onMutate(variables, register) {
      register({
        query: usersMeQueryOptions({}),
        update: (cache) => ({
          ...cache,
          organization_memberships: [
            ...cache.organization_memberships,
            getOptimisticOrganizationMembership(variables),
          ],
        }),
      });
    },
  });
}

export function useCreateOrganizationMutation() {
  return useMutation(createOrganizationMutationOptions());
}

export function createApplicationMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsApplication,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
        }),
      });
    },
  });
}

export function useCreateApplicationMutation() {
  return useMutation(createApplicationMutationOptions());
}

export function updateApplicationMutationOptions() {
  return mutationOptions({
    mutationFn: updateOrganizationsApplication,
  });
}

export function destroyApplicationMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsApplication,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
        }),
        update: (cache) =>
          infiniteFilter(
            cache,
            (application) => application.slug !== variables.slug,
          ),
      });
    },
  });
}

export function useDestroyApplicationMutation() {
  return useMutation(destroyApplicationMutationOptions());
}

export function createInviteMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsInvite,
    onMutate(variables, register) {
      register({
        query: organizationsInvitesInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
        }),
      });
    },
  });
}

export function syncApplicationMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsApplicationsSync,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationQueryOptions({
          organizationSlug: variables.organizationSlug,
          slug: variables.applicationSlug,
        }),
      });
    },
  });
}

export function useCreateInviteMutation() {
  return useMutation(createInviteMutationOptions());
}

export function destroyMemberMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsMember,
    onMutate(variables, register) {
      register({
        query: organizationsMembersInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
        }),
        update: (cache) =>
          infiniteFilter(cache, (member) => member.id !== variables.id),
      });
    },
  });
}

export function destroyInviteMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsInvite,
    onMutate(variables, register) {
      register({
        query: organizationsInvitesInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
        }),
        update: (cache) =>
          infiniteFilter(cache, (invite) => invite.id !== variables.id),
      });
    },
  });
}

export function useAcceptInviteMutation() {
  return useMutation(
    mutationOptions({
      mutationFn: createInvitesAccept,
      onMutate(_variables, register) {
        register({
          query: usersMeQueryOptions({}),
        });
      },
    }),
  );
}

export function createModelRecordMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsModelsRecord,
    onMutate(variables, register) {
      register({
        query: organizationsModelsRecordsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
        }),
        update: (cache) => infinitePrepend(cache, variables.record),
      });
    },
  });
}

export function updateModelRecordMutationOptions() {
  return mutationOptions({
    mutationFn: updateOrganizationsModelsRecord,
    onMutate(variables, register) {
      register({
        query: organizationsModelsRecordQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          id: variables.id,
        }),
      });
    },
  });
}

export function destroyModelRecordMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsModelsRecord,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationsFavoritesInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          applicationSlug: p.Any,
        }),
        update: (cache) =>
          infiniteFilter(
            cache,
            (favorite) =>
              !(
                favorite.favoritable.type === "ModelRecord" &&
                favorite.favoritable.id === variables.id
              ),
          ),
      });

      register({
        query: organizationsModelsRecordsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: p.Any,
          ...p.Any,
        }),
        update: (cache) =>
          infiniteFilter(
            cache,
            (record) =>
              !(
                record.model_id === variables.modelId &&
                record.id === variables.id
              ),
          ),
      });

      register({
        query: organizationsModelsFieldsRecordsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: p.Any,
          fieldName: p.Any,
          ...p.Any,
        }),
        update: (cache) =>
          infiniteFilter(
            cache,
            (record) =>
              !(
                record.model_id === variables.modelId &&
                record.id === variables.id
              ),
          ),
      });

      register({
        query: organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: p.Any,
          recordId: p.Any,
          fieldName: p.Any,
          ...p.Any,
        }),
        update: (cache) =>
          infiniteFilter(
            cache,
            (record) =>
              !(
                record.model_id === variables.modelId &&
                record.id === variables.id
              ),
          ),
      });
    },
  });
}

export function createApplicationHmacSecretMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsApplicationsHmacSecret,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationQueryOptions({
          organizationSlug: variables.organizationSlug,
          slug: variables.applicationSlug,
        }),
        update: (cache) => ({
          ...cache,
          has_hmac_secret: true,
        }),
      });
    },
  });
}

export function deleteApplicationHmacSecretMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsApplicationsHmacSecret,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationQueryOptions({
          organizationSlug: variables.organizationSlug,
          slug: variables.applicationSlug,
        }),
        update: (cache) => ({
          ...cache,
          has_hmac_secret: false,
        }),
      });
    },
  });
}

export function createModelViewMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsModelsView,
    onMutate(variables, register) {
      register({
        query: organizationsModelsViewsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
        }),
      });
    },
  });
}

export function createModelRecordViewMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsModelsRecordsView,
    onMutate(variables, register) {
      register({
        query: organizationsModelsRecordsViewsInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          recordId: variables.recordId,
        }),
      });
    },
  });
}

export function touchViewMutationOptions() {
  return mutationOptions({
    mutationFn: updateOrganizationsView,
  });
}

export function reorderFavoritesMutationOptions() {
  return mutationOptions({
    mutationFn: updateOrganizationsApplicationsFavoritesReorder,
    onMutate(variables, register) {
      register({
        query: organizationsApplicationsFavoritesInfiniteQueryOptions({
          organizationSlug: variables.organizationSlug,
          applicationSlug: variables.applicationSlug,
        }),
        update: (cache) =>
          infiniteReorder(cache, (favorite) => favorite.id, variables.items),
      });
    },
  });
}

export function favoriteModelMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsModelsFavorite,
    onMutate(variables, register) {
      register({
        query: organizationsModelQueryOptions({
          organizationSlug: variables.organizationSlug,
          id: variables.modelId,
        }),
        update: (cache) => ({
          ...cache,
          viewer_favorited_at: new Date().toISOString(),
        }),
      });

      const model = queryClient.getQueryData(
        organizationsModelQueryOptions({
          organizationSlug: variables.organizationSlug,
          id: variables.modelId,
        }).queryKey,
      );
      if (model) {
        register({
          query: organizationsApplicationsFavoritesInfiniteQueryOptions({
            organizationSlug: variables.organizationSlug,
            applicationSlug: model.application_slug,
          }),
          update: (cache) =>
            infiniteAppend(cache, getOptimisticFavorite(model)),
        });
      }
    },
  });
}

export function unfavoriteModelMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsModelsFavorite,
    onMutate(variables, register) {
      register({
        query: organizationsModelQueryOptions({
          organizationSlug: variables.organizationSlug,
          id: variables.modelId,
        }),
        update: (cache) => ({
          ...cache,
          viewer_favorited_at: null,
        }),
      });

      const model = queryClient.getQueryData(
        organizationsModelQueryOptions({
          organizationSlug: variables.organizationSlug,
          id: variables.modelId,
        }).queryKey,
      );
      if (model) {
        register({
          query: organizationsApplicationsFavoritesInfiniteQueryOptions({
            organizationSlug: variables.organizationSlug,
            applicationSlug: model.application_slug,
          }),
          update: (cache) =>
            infiniteFilter(
              cache,
              (favorite) =>
                !(
                  favorite.favoritable.type === "Model" &&
                  favorite.favoritable.id === model.id
                ),
            ),
        });
      }
    },
  });
}

export function favoriteRecordMutationOptions() {
  return mutationOptions({
    mutationFn: createOrganizationsModelsRecordsFavorite,
    onMutate(variables, register) {
      register({
        query: organizationsModelsRecordQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          id: variables.recordId,
        }),
        update: (cache) => ({
          ...cache,
          viewer_favorited_at: new Date().toISOString(),
        }),
      });

      const record = queryClient.getQueryData(
        organizationsModelsRecordQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          id: variables.recordId,
        }).queryKey,
      );
      if (record) {
        register({
          query: organizationsApplicationsFavoritesInfiniteQueryOptions({
            organizationSlug: variables.organizationSlug,
            applicationSlug: record.application_slug,
          }),
          update: (cache) =>
            infiniteAppend(cache, getOptimisticFavorite(record)),
        });
      }
    },
  });
}

export function unfavoriteRecordMutationOptions() {
  return mutationOptions({
    mutationFn: deleteOrganizationsModelsRecordsFavorite,
    onMutate(variables, register) {
      register({
        query: organizationsModelsRecordQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          id: variables.recordId,
        }),
        update: (cache) => ({
          ...cache,
          viewer_favorited_at: null,
        }),
      });

      const record = queryClient.getQueryData(
        organizationsModelsRecordQueryOptions({
          organizationSlug: variables.organizationSlug,
          modelId: variables.modelId,
          id: variables.recordId,
        }).queryKey,
      );
      if (record) {
        register({
          query: organizationsApplicationsFavoritesInfiniteQueryOptions({
            organizationSlug: variables.organizationSlug,
            applicationSlug: record.application_slug,
          }),
          update: (cache) =>
            infiniteFilter(
              cache,
              (favorite) =>
                !(
                  favorite.favoritable.type === "ModelRecord" &&
                  favorite.favoritable.id === record.id &&
                  favorite.favoritable.model_id === record.model_id
                ),
            ),
        });
      }
    },
  });
}

export function createFeedbackMutationOptions() {
  return mutationOptions({
    mutationFn: createFeedback,
  });
}

export function updateMeMutationOptions() {
  return mutationOptions({
    mutationFn: updateUsersMe,
  });
}
