import { ApiModel } from "api-client";
import { AnimatePresence } from "framer-motion";
import {
  ButtonHTMLAttributes,
  ComponentType,
  forwardRef,
  Fragment,
  RefAttributes,
  SVGProps,
  useRef,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { ExtractContext, PropsWithExtraContext } from "~/components/ActionMenu";
import { Tooltip } from "~/components/Tooltip";
import { Wrap } from "~/components/Wrap";
import { apply, useAction, useActionContext } from "~/lib/actions";
import { ActionReactContext } from "~/lib/actions/context";
import { cn } from "~/lib/cn";
import { Link, Params, Path } from "~/router";
import { Action, Prettify } from "~/types";

export type ActionIconButtonProps<
  T extends ApiModel,
  A extends Action<T, any>,
> = {
  type?: "button" | "submit" | "reset";
  target: T | null;
  action: A;
  variant?: "primary" | "subtle";
} & PropsWithExtraContext<Prettify<ExtractContext<[A]>>>;

export function ActionIconButton<T extends ApiModel, A extends Action<T, any>>({
  type,
  action,
  target,
  variant,
  ...props
}: ActionIconButtonProps<T, A>) {
  const ref = useRef<HTMLButtonElement>(null);

  const context = useActionContext(target, props.context);
  const label = context ? apply(action.label)(context) : "";
  const actionVariant = context
    ? apply(action.variant ?? "primary")(context)
    : undefined;

  const [node, execute] = useAction(target, props.context, {
    onCleanup() {
      ref.current?.focus();
    },
  });

  if (action.applicable && !action.applicable?.(context)) return null;

  return (
    <ActionReactContext.Provider value={context}>
      {node}
      <IconButton
        ref={ref}
        type={type}
        shortcut={action.shortcut}
        icon={action.icon}
        accessibilityLabel={label}
        variant={variant}
        danger={actionVariant === "danger"}
        onClick={() => execute(action)}
      />
    </ActionReactContext.Provider>
  );
}

export type IconButtonProps<
  P extends
    | Path
    | {
        pathname: Path;
        search?: string | undefined;
        hash?: string | undefined;
      },
> = {
  size?: "xs" | "sm" | "md";
  className?: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  accessibilityLabel: string;
  disabled?: boolean;
  variant?: "primary" | "subtle";
  danger?: boolean;
  hideTooltip?: boolean;
  shortcut?: string;
  onClick?(event: Event): void;
} & (P extends keyof Params
  ? { to: P; params: Params[P]; state?: any }
  : P extends { pathname: keyof Params }
    ? { to: P; params?: Params[P["pathname"]]; state?: any }
    : P extends Path
      ? { to: P; params?: never; state?: any }
      : { to?: never; params?: never; state?: never }) &
  ButtonHTMLAttributes<HTMLButtonElement>;

const InternalIconButton = forwardRef<HTMLButtonElement, IconButtonProps<any>>(
  function IconButton(
    {
      size = "md",
      variant = "subtle",
      danger,
      disabled,
      icon: Icon,
      accessibilityLabel,
      hideTooltip,
      shortcut,
      ...props
    },
    ref,
  ) {
    const showTooltip = !hideTooltip && variant !== "primary";

    const variantClassName = cn(
      variant === "primary" && [
        {
          "w-8 h-8": size === "xs",
          "w-9 h-9": size === "sm",
          "w-10 h-10": size === "md",
        },
        "flex items-center justify-center rounded-xl",
        "bg-action text-primary ring-1 ring-inset ring-action",
        "aria-enabled:hover:bg-action-active aria-enabled:focus-visible:bg-action-active",
        "aria-disabled:cursor-not-allowed aria-disabled:opacity-50",
        "shadow overflow-hidden",
        "text-icon",
        danger &&
          "bg-danger-subtle text-danger ring-danger hover:bg-danger-subtle-active focus-visible:bg-danger-subtle-active",
      ],
      variant === "subtle" && [
        "w-7 h-7 -m-0.5 flex items-center justify-center rounded-lg",
        "aria-disabled:cursor-not-allowed aria-disabled:opacity-40",
        "dark:border-t border-transparent",
        "dark:aria-expanded:shadow dark:focus-visible:shadow dark:hover:aria-enabled:shadow",
        "text-icon",
        "aria-expanded:bg-subtle aria-expanded:border-action",
        "focus-visible:bg-subtle focus-visible:border-action",
        "hover:aria-enabled:bg-subtle hover:aria-enabled:border-action",
        danger && [
          "text-danger",
          "aria-expanded:bg-danger-subtle aria-expanded:border-danger",
          "focus-visible:bg-danger-subtle focus-visible:border-danger",
          "hover:aria-enabled:bg-danger-subtle hover:aria-enabled:border-danger",
        ],
      ],
    );

    const className = cn(
      "shrink-0 outline-none",
      variantClassName,
      props.className,
    );

    const child = (
      <Fragment>
        <AnimatePresence initial={false}>
          <Icon className="w-4 h-4" aria-hidden="true" />
        </AnimatePresence>
        <span className="sr-only">{accessibilityLabel}</span>
      </Fragment>
    );

    useHotkeys(
      shortcut ?? "",
      (event) => {
        if (event.defaultPrevented) return;
        props.onClick?.(event);
      },
      {
        enabled: !!shortcut,
        preventDefault: true,
      },
    );

    if (props.to) {
      return (
        <Wrap
          condition={showTooltip}
          wrap={(children) => (
            <Tooltip label={accessibilityLabel} shortcut={shortcut}>
              {children}
            </Tooltip>
          )}
        >
          <span>
            <Link
              to={props.to as any}
              params={props.params}
              className={className}
            >
              {child}
            </Link>
          </span>
        </Wrap>
      );
    } else {
      return (
        <Wrap
          condition={showTooltip}
          wrap={(children) => (
            <Tooltip label={accessibilityLabel} shortcut={shortcut}>
              {children}
            </Tooltip>
          )}
        >
          <button
            ref={ref}
            {...props}
            className={className}
            aria-disabled={disabled}
            onClick={disabled ? undefined : props.onClick}
            onPointerDown={disabled ? undefined : props.onPointerDown}
          >
            {child}
          </button>
        </Wrap>
      );
    }
  },
);

export const IconButton: <
  P extends
    | Path
    | {
        pathname: Path;
        search?: string | undefined;
        hash?: string | undefined;
      },
>(
  props: IconButtonProps<P> & RefAttributes<HTMLButtonElement>,
) => JSX.Element = InternalIconButton as any;
