import { routes } from "@generouted/react-router";
import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

import { Path } from "~/router";

export function usePathname() {
  const location = useLocation();
  return useMemo(() => {
    const matches = matchRoutes(routes, location);
    const path = matches?.at(-1)?.route.id ?? null;
    if (path) {
      let normalizedPath = path;
      // Remove and "/(group)" parts
      normalizedPath = normalizedPath.replace(/(\/?\([^/]+\))/g, "");
      // Remove any trailing "/index"
      normalizedPath = normalizedPath.replace(/\/index$/, "");
      // Replace "[param]" with ":param"
      normalizedPath = normalizedPath.replace(/\[([^/]+)\]/g, ":$1");
      return normalizedPath as Path;
    } else {
      return null;
    }
  }, [location]);
}
