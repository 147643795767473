import { Model, ModelRecord } from "api-client";

import { Icon } from "~/components/Icon";
import { cn } from "~/lib/cn";

export interface RecordThumbnailProps {
  record?: ModelRecord | null;
  model?: Model | null;
  size?: "sm" | "md" | "lg";
}

export function RecordThumbnail({
  model,
  record,
  size = "sm",
}: RecordThumbnailProps) {
  if (!model || record?.thumbnail) {
    return (
      <span
        className={cn(
          "relative shrink-0 flex bg-avatar shadow overflow-hidden",
          {
            "w-5 h-5 rounded-md": size === "sm",
            "w-6.5 h-6.5 rounded-lg": size === "md",
            "w-10 h-10 rounded-xl": size === "lg",
          },
        )}
      >
        <div className="absolute inset-0 rounded-[inherit] border border-black/10" />
        {record?.thumbnail && (
          <img
            src={record.thumbnail}
            alt=""
            className="w-full h-full object-cover"
          />
        )}
      </span>
    );
  }

  return (
    <span
      className={cn(
        "flex shrink-0 items-center justify-center",
        "bg-action ring-1 ring-inset ring-action shadow",
        {
          "w-5 h-5 rounded-md": size === "sm",
          "w-6.5 h-6.5 rounded-lg": size === "md",
          "w-10 h-10 rounded-xl": size === "lg",
        },
      )}
    >
      <Icon
        name={model.icon}
        className={cn("text-icon", {
          "w-3 h-3 [&_*]:stroke-[2.5]": size === "sm",
          "w-4 h-4": size === "md",
          "w-6 h-6": size === "lg",
        })}
      />
    </span>
  );
}
