import { useCallback } from "react";
import toast from "react-hot-toast/headless";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";

import { useNavigate, useParams } from "~/router";

export function GlobalHotKeys() {
  const navigate = useNavigate();
  const params = useParams("/o/:org/w/:app");
  const location = useLocation();

  // Navigate to user settings when pressing Cmd + ,
  useHotkeys(
    "meta+,",
    useCallback(() => {
      if (location.pathname.startsWith("/settings")) return;

      if (params.app) {
        navigate(`/settings/o/:org/w/:app`, {
          params,
          state: { redirect: location.pathname },
        });
      } else if (params.org) {
        navigate(`/settings/o/:org`, {
          params,
          state: { redirect: location.pathname },
        });
      } else {
        navigate("/settings", { state: { redirect: location.pathname } });
      }
    }, [navigate, params, location.pathname]),
    {
      splitKey: "|",
      preventDefault: true,
    },
  );

  // Copy current URL when pressing Cmd + shift + C
  useHotkeys(
    "meta+shift+c",
    useCallback(() => {
      const url = new URL(
        location.pathname + location.search,
        window.location.origin,
      );
      navigator.clipboard.writeText(url.toString());
      toast("Current URL copied to clipboard");
    }, [location.pathname, location.search]),
    {
      splitKey: "|",
      preventDefault: true,
    },
  );

  return null;
}
