import { mergeRefs } from "@react-aria/utils";
import { Eye, EyeOff } from "@untitled-ui/icons-react";
import { ReactNode, useId, useRef } from "react";
import { useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { ErrorCode, ErrorMessage } from "~/components/ErrorMessage";
import { IconButton } from "~/components/IconButton";
import { cn } from "~/lib/cn";

export interface PasswordTextFieldProps {
  type: "password";
  preventReveal?: boolean;
}

export interface GenericTextFieldProps {
  type?: "text" | "email" | "url";
}

export type TextFieldProps<T extends FieldValues> = {
  className?: string;
  name: Path<T>;
  control: Control<T>;
  label: string;
  hideLabel?: boolean;
  placeholder?: string;
  leadingAccessory?: ReactNode;
  trailingAccessory?: ReactNode;
  errorMessages?: Partial<Record<ErrorCode, string>>;
  readOnly?: boolean;
  autoFocus?: boolean;
  preventReveal?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
} & (GenericTextFieldProps | PasswordTextFieldProps);

export function TextField<T extends FieldValues>({
  className,
  name,
  control,
  label,
  hideLabel,
  placeholder,
  leadingAccessory: LeadingAccessory,
  trailingAccessory: TrailingAccessory,
  errorMessages,
  readOnly,
  autoFocus,
  size = "lg",
  ...props
}: TextFieldProps<T>) {
  const id = useId();
  const errorId = `${id}-error`;

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control });

  const [passwordType, setPasswordType] = useState<"password" | "text">(
    "password",
  );

  return (
    <div
      className={cn("flex flex-col gap-2 flex-1 items-start group", className)}
    >
      <FieldLabel hidden={hideLabel} htmlFor={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <div
        className={cn(
          {
            "px-3.5 h-11 gap-2": size === "lg",
            "px-3.5 h-10 gap-2": size === "md",
            "px-3 h-9 text-[15px] gap-1.5": size === "sm",
            "px-3 h-8 text-[15px] gap-1.5": size === "xs",
          },
          "flex min-w-0 w-full items-center",
          "bg-action text-primary rounded-xl border border-action shadow",
          "has-[_input:focus-visible]:border-action-active has-[_input:focus-visible]:bg-action-active",
          "isolate-group-hover:bg-action-active",
          "[&:has(input[aria-invalid='true'])]:border-danger",
        )}
      >
        {LeadingAccessory && (
          <div
            className={cn(
              "flex mr-0.5",
              "[&>svg]:w-4 [&>svg]:h-4 [&>svg]:text-icon",
              "[&>p]:text-secondary",
              size === "sm" && "-ml-px",
            )}
          >
            {LeadingAccessory}
          </div>
        )}

        <input
          {...field}
          ref={mergeRefs(field.ref, inputRef)}
          value={field.value ?? ""}
          className={cn(
            "flex-1 min-w-0 bg-transparent",
            "placeholder:text-placeholder",
            "focus/input:outline-none",
          )}
          id={id}
          type={props.type === "password" ? passwordType : props.type}
          placeholder={placeholder}
          aria-invalid={invalid}
          aria-describedby={error ? errorId : undefined}
          readOnly={readOnly}
          autoFocus={autoFocus}
        />

        {props.type === "password" && !props.preventReveal && (
          <IconButton
            className="-mr-1"
            type="button"
            icon={passwordType === "password" ? Eye : EyeOff}
            accessibilityLabel="Toggle show password"
            hideTooltip
            aria-controls={id}
            onClick={() => {
              setPasswordType(
                passwordType === "password" ? "text" : "password",
              );
              inputRef.current?.focus();
            }}
          />
        )}

        {TrailingAccessory && (
          <div
            className={cn(
              "[&>svg]:w-4 [&>svg]:h-4 [&>svg]:text-icon",
              "[&>p]:text-secondary",
            )}
          >
            {TrailingAccessory}
          </div>
        )}
      </div>

      {error && (
        <ErrorMessage
          label={label}
          error={error}
          overrides={errorMessages}
          id={errorId}
        />
      )}
    </div>
  );
}
