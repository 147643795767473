import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DateInput } from "~/components/DateInput";
import { NumberField } from "~/components/NumberField";
import { OtpField } from "~/components/OtpField";
import { Select } from "~/components/Select";
import { TextAreaField } from "~/components/TextAreaField";
import { TextField } from "~/components/TextField";
import { ToggleField } from "~/components/Toggle";

const KitchenSinkFormSchema = z.object({
  name: z.string(),
  password: z.string(),
  textarea: z.string(),
  date: z.date(),
  dateMin: z.date().min(new Date(2022, 5, 2)),
  dateMax: z.date().max(new Date()),
  dateMinMax: z
    .date()
    .min(new Date(2022, 5, 2))
    .max(new Date()),
  dateTime: z.string().datetime(),
  dateTimeMin: z.string().datetime(),
  dateTimeMax: z.string().datetime(),
  dateTimeMinMax: z.string().datetime(),
});

type KitchenSinkForm = z.infer<typeof KitchenSinkFormSchema>;

function FormKitchenSink() {
  const { control } = useForm<KitchenSinkForm>({
    resolver: zodResolver(KitchenSinkFormSchema),
    defaultValues: {
      name: "",
      password: "",
    },
  });

  const [code, setCode] = useState("");
  const [visibility, setVisibility] = useState<"public" | "private">("public");
  const [number, setNumber] = useState("1");
  const [flag, setFlag] = useState(true);

  return (
    <div className="p-4 flex flex-col flex-1 gap-4">
      <form className="flex flex-col gap-4 mb-4">
        <TextField
          name="name"
          type="text"
          label="Name"
          placeholder="Koala"
          control={control}
        />

        <TextField
          name="password"
          type="password"
          label="Password"
          placeholder="password"
          control={control}
        />

        <TextAreaField
          name="textarea"
          label="Textarea"
          placeholder="Koala"
          control={control}
        />

        <Select
          name="visibility"
          options={["public", "private"]}
          value={visibility}
          onValueChange={(value) => setVisibility(value)}
          label="Visibility"
        />

        <NumberField
          name="number"
          placeholder="1"
          min={1}
          value={number}
          onValueChange={(value) => setNumber(value)}
          label="Number Field"
        />

        <ToggleField
          value={flag}
          label="Example"
          onValueChanged={(value) => setFlag(value)}
        />

        <OtpField value={code} onValueChange={setCode} numInputs={5} />

        <DateInput
          label="Date"
          name="date"
          type="date"
          control={control}
          placeholder="Custom placeholder"
        />

        <DateInput
          label="Date with min"
          name="dateMin"
          type="date"
          control={control}
          minDate={new Date(2022, 5, 2)}
        />

        <DateInput
          label="Date with max"
          name="dateMax"
          type="date"
          control={control}
          maxDate={new Date()}
        />

        <DateInput
          label="Date with min and max"
          name="dateMinMax"
          type="date"
          control={control}
          minDate={new Date(2022, 5, 2)}
          maxDate={new Date()}
        />

        <DateInput
          label="Date time"
          name="dateTime"
          type="datetime"
          control={control}
        />

        <DateInput
          label="Date time with min"
          name="dateTimeMin"
          type="datetime"
          control={control}
          minDate={new Date(2022, 5, 2, 10, 0, 0)}
        />

        <DateInput
          label="Date time with max"
          name="dateTimeMax"
          type="datetime"
          control={control}
          maxDate={new Date()}
        />

        <DateInput
          label="Date time with min and max"
          name="dateTimeMinMax"
          type="datetime"
          control={control}
          minDate={new Date(2022, 5, 2, 10, 0, 0)}
          maxDate={new Date()}
        />
      </form>
    </div>
  );
}

export default FormKitchenSink;
