import { Table } from "@tanstack/react-table";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react";
import { ModelRecord } from "api-client";

import { cn } from "~/lib/cn";

export interface RecordTablePaginationProps {
  table: Table<ModelRecord>;
}

export function RecordTablePagination({ table }: RecordTablePaginationProps) {
  const canPrevious = table.getCanPreviousPage();
  const canNext = table.getCanNextPage();

  const pagination = table.getState().pagination;
  const start = pagination.pageIndex * pagination.pageSize + 1;
  const end = start + table.getRowModel().rows.length - 1;
  const total = table.getRowCount();

  return (
    <div className="flex flex-row items-center gap-4">
      {end > 0 && total > 0 && (
        <p className="text-secondary text-sm">
          {start.toLocaleString()}-{end.toLocaleString()} of{" "}
          {total.toLocaleString()}
        </p>
      )}

      <nav
        aria-label="Table pagination"
        className={cn(
          "flex flex-row divide-x divide-action shadow rounded-xl",
          !canPrevious && !canNext && "divide-action/50",
          canPrevious &&
            "has-[button:not([disabled])[data-dir='previous']:focus-visible]:divide-action-active",
          canNext &&
            "has-[button:not([disabled])[data-dir='next']:focus-visible]:divide-action-active",
        )}
      >
        <div>
          <button
            data-dir="previous"
            type="button"
            disabled={!canPrevious}
            onClick={() => table.previousPage()}
            className={cn(
              "p-2.5 rounded-l-xl border-y border-l border-action outline-none",
              "bg-action enabled:hover:bg-action-active",
              "focus-visible:bg-action-active focus-visible:border-action-active",
              "disabled:opacity-50 disabled:cursor-not-allowed",
            )}
          >
            <span className="sr-only">Previous page</span>
            <ChevronLeft className="text-icon w-4.5 h-4.5" />
          </button>
        </div>
        <div>
          <button
            data-dir="next"
            type="button"
            disabled={!canNext}
            onClick={() => table.nextPage()}
            className={cn(
              "p-2.5 rounded-r-xl border-y border-r border-action outline-none",
              "bg-action enabled:hover:bg-action-active",
              "focus-visible:bg-action-active focus-visible:border-action-active",
              "disabled:opacity-50 disabled:cursor-not-allowed",
            )}
          >
            <span className="sr-only">Next page</span>
            <ChevronRight className="text-icon w-4.5 h-4.5" />
          </button>
        </div>
      </nav>
    </div>
  );
}
