export function Rails() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.617284 16.0476H7.58377C7.58377 16.0476 6.2522 9.97176 10.6614 7.51144C11.6226 7.04407 14.6825 5.29804 19.6914 9.00174C19.8501 8.86946 20 8.76364 20 8.76364C20 8.76364 15.4145 4.18692 10.3086 4.69839C7.7425 4.92766 4.58554 7.26453 2.73369 10.3509C0.881834 13.4374 0.617284 16.0476 0.617284 16.0476Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.617284 16.0476H7.58377C7.58377 16.0476 6.2522 9.97176 10.6614 7.51144C11.6226 7.04407 14.6825 5.29804 19.6914 9.00174C19.8501 8.86946 20 8.76364 20 8.76364C20 8.76364 15.4145 4.18692 10.3086 4.69839C7.7425 4.92766 4.58554 7.26453 2.73369 10.3509C0.881834 13.4374 0.617284 16.0476 0.617284 16.0476Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.617284 16.0476H7.58377C7.58377 16.0476 6.2522 9.97176 10.6614 7.51144C11.6226 7.04407 14.6825 5.29804 19.6914 9.00174C19.8501 8.86946 20 8.76364 20 8.76364C20 8.76364 15.4145 4.18692 10.3086 4.69839C7.73369 4.92766 4.57672 7.26453 2.72487 10.3509C0.873016 13.4374 0.617284 16.0476 0.617284 16.0476ZM15.1323 5.19222L15.1675 4.60139C15.0882 4.5573 14.8677 4.45148 14.3122 4.29275L14.2769 4.87476C14.5679 4.97176 14.8501 5.07758 15.1323 5.19222Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2945 7.0617L14.2593 7.61726C14.5503 7.62608 14.8413 7.66135 15.1323 7.72308L15.1675 7.17634C14.8677 7.11461 14.5767 7.07934 14.2945 7.0617ZM11.0317 4.31038H11.1199L10.9436 3.77246C10.6702 3.77246 10.388 3.7901 10.097 3.82537L10.2645 4.34565C10.5203 4.3192 10.776 4.31038 11.0317 4.31038ZM11.455 7.55553L11.6578 8.164C11.9136 8.04054 12.1693 7.93472 12.425 7.85535L12.231 7.27334C11.9312 7.36153 11.6755 7.45853 11.455 7.55553ZM7.4515 5.23631L7.05467 4.62784C6.83421 4.74248 6.60494 4.86594 6.36684 5.00703L6.77249 5.62431C7.00176 5.48322 7.22222 5.35094 7.4515 5.23631ZM9.25926 9.20456L9.68254 9.83948C9.83245 9.61902 10.0088 9.4162 10.2028 9.21338L9.806 8.61373C9.60317 8.79892 9.41799 9.00174 9.25926 9.20456ZM7.9806 12.0441L8.69488 12.6084C8.73016 12.2645 8.79189 11.9206 8.88007 11.5767L8.24515 11.074C8.13051 11.4003 8.05115 11.7266 7.9806 12.0441ZM4.11817 7.85535L3.49206 7.30862C3.26279 7.52907 3.04233 7.74953 2.83951 7.96999L3.51852 8.552C3.7037 8.31391 3.90653 8.07581 4.11817 7.85535ZM1.45503 11.7972L0.440917 11.4268C0.273369 11.806 0.0881834 12.2469 0 12.485L1.01411 12.8554C1.12875 12.5555 1.31393 12.1234 1.45503 11.7972ZM7.84832 14.2839C7.86596 14.7513 7.91005 15.1305 7.95414 15.395L9.01235 15.7742C8.93298 15.4303 8.85362 15.0423 8.80071 14.6278L7.84832 14.2839Z"
        fill="currentColor"
      />
    </svg>
  );
}
