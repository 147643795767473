import {
  ModelField,
  ModelFieldDateView,
  ModelFieldManyReferenceView,
  ModelFieldNumberView,
  ModelFieldOneReferenceView,
  ModelFieldStringView,
  ModelRecord,
} from "api-client";
import { z, ZodSchema } from "zod";

export const recordSchema = z
  .object({
    type: z.literal("ModelRecord"),
    organization_slug: z.string(),
    application_slug: z.string(),
    model_id: z.string(),
    model_name: z.string(),
    id: z.string(),
    title: z.union([z.string(), z.number()]).nullable(),
    thumbnail: z.string().nullable(),
    created_at: z.string().nullable(),
    updated_at: z.string().nullable(),
    data: z.object({}).passthrough(),
  })
  .passthrough() as any as z.ZodSchema<ModelRecord>;

export function getStringFieldSchema(field: ModelFieldStringView) {
  if (field.enum_options.length > 0) {
    return z.enum(
      field.enum_options.map((option) => option.value) as [string, ...string[]],
    );
  }

  let type = z.string();

  if (field.min_length) {
    type = type.min(field.min_length);
  }

  if (field.max_length) {
    type = type.max(field.max_length);
  }
  return type;
}

export function getNumberFieldSchema(field: ModelFieldNumberView) {
  let type = z.number();
  if (field.min) {
    type = type.min(field.min);
  }
  if (field.max) {
    type = type.max(field.max);
  }
  if (field.step) {
    type = type.step(field.step);
  }
  return type;
}

export function getDateFieldSchema(field: ModelFieldDateView) {
  let type = z.string();
  if (field.specificity === "date") {
    type = type.date();
  } else if (field.specificity === "time") {
    type = type.time();
  } else if (field.specificity === "datetime") {
    type = type.datetime({ offset: true });
  }
  // TODO: handle min/max
  return type;
}

export function getBooleanFieldSchema() {
  return z.boolean();
}

export function getJsonFieldSchema() {
  return z.object({}).passthrough();
}

export function getReferenceFieldSchema(
  field: ModelFieldOneReferenceView | ModelFieldManyReferenceView,
) {
  if (field.reference_type === "to_one") {
    return recordSchema;
  } else {
    return undefined;
  }
}

export function getFieldSchema(field: ModelField) {
  let type: ZodSchema | undefined;
  switch (field.field_type) {
    case "string":
      type = getStringFieldSchema(field);
      break;
    case "number":
      type = getNumberFieldSchema(field);
      break;
    case "boolean":
      type = getBooleanFieldSchema();
      break;
    case "date":
      type = getDateFieldSchema(field);
      break;
    case "json":
      type = getJsonFieldSchema();
      break;
    case "reference":
      type = getReferenceFieldSchema(field);
      break;
  }

  if (type) {
    if (field.nullable) {
      type = type.nullable();
    }
    if (field.default) {
      type = type.default(field.default);
    }
  }

  return type;
}

export function getModelSchema(fields: ModelField[]) {
  const schema = recordSchema;

  let objectSchema = z.object({}).passthrough();
  fields.forEach((field) => {
    const fieldSchema = getFieldSchema(field);
    if (fieldSchema) {
      objectSchema = objectSchema.extend({
        [field.field_name]: fieldSchema,
      }) as any;
    }
  });

  return (schema as any).extend({ data: objectSchema }) as typeof schema;
}

export type ModelSchema = ReturnType<typeof getModelSchema>;
