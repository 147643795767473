import { Application } from "api-client";

import { cn } from "~/lib/cn";
import { Logo } from "~/svgs/Logo";

export interface AppIconProps {
  className?: string;
  application: Application | null;
}

export function AppIcon({ application, className }: AppIconProps) {
  return (
    <span
      className={cn(
        "relative shrink-0 rounded-md shadow",
        "bg-avatar overflow-hidden",
        className,
      )}
    >
      {application &&
        (application?.avatar_url ? (
          <img
            className="absolute inset-0"
            src={application.avatar_url}
            alt=""
          />
        ) : (
          <div className="absolute inset-0 bg-black flex items-center justify-center">
            <span className="absolute inset-0 rounded-md opacity-20 bg-gradient-to-t from-transparent to-white" />
            <span className="flex w-[65%] h-[65%] text-white relative">
              <Logo />
            </span>
          </div>
        ))}
    </span>
  );
}
