import { Outlet } from "react-router-dom";

import { useCurrentUser } from "~/lib/current";
import NotFound from "~/pages/404";
import { Navigate, useParams } from "~/router";

function ScopedLayout() {
  const me = useCurrentUser();

  const params = useParams("/o/:org");

  // Wait for all authenticated users to load
  if (!me) return null;

  // If not verified, redirect to verify
  if (!me.email_verified) {
    return <Navigate to="/verify" replace />;
  }

  // If no orgs, redirect to create org
  if (!me.organization_memberships.length) {
    return <Navigate to="/org" replace />;
  }

  // If no org in URL, redirect to first org
  if (!params.org) {
    const orgSlug = me.organization_memberships[0].organization.slug;
    return <Navigate to="/o/:org" params={{ org: orgSlug }} replace />;
  }

  // If org in URL, but not in user's orgs, redirect to first org
  const orgSlug = me.organization_memberships.find(
    (om) => om.organization.slug === params.org,
  );
  if (!orgSlug) {
    return <NotFound />;
  }

  // Otherwise, render the child route
  return <Outlet />;
}

export default ScopedLayout;
