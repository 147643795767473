import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useSigninMutation } from "~/mutations";
import { Link, useNavigate } from "~/router";
import { Logo } from "~/svgs/Logo";

const SignInFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

type SignInForm = z.infer<typeof SignInFormSchema>;

function SignIn() {
  const navigate = useNavigate();
  const {
    formState: { errors },
    setError,
    handleSubmit,
    control,
  } = useForm<SignInForm>({
    resolver: zodResolver(SignInFormSchema),
    defaultValues: {
      email: import.meta.env.VITE_AUTH_EMAIL ?? "",
      password: import.meta.env.VITE_AUTH_PASS ?? "",
    },
  });

  const signIn = useSigninMutation();

  const onSubmit = handleSubmit(async (data: SignInForm) => {
    signIn.mutate(data, {
      onSuccess(data) {
        if (data.email_verified && data.organization_memberships.length > 0) {
          const org = data.organization_memberships[0].organization.slug;
          navigate("/o/:org", { params: { org }, replace: true });
        } else if (data.email_verified) {
          navigate("/org", { replace: true });
        } else {
          navigate("/verify", { replace: true });
        }
      },
      onError: (error) => handleFormErrors(error, setError),
    });
  });

  return (
    <div className="p-4 gap-4 flex-1 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-1.5 text-icon w-full max-w-md">
        <div className="w-6 aspect-square text-icon-subtle">
          <Logo />
        </div>
        <h1 className="text-lg font-medium text-primary">Sign in</h1>
        <p className="text-sm text-placeholder">
          New to Baselayer?{" "}
          <Link className="text-secondary" to="/signup">
            Sign up
          </Link>
        </p>
      </div>

      <div className="w-full max-w-md mt-1">
        <form className="flex flex-col gap-3 mb-4" onSubmit={onSubmit}>
          <TextField
            name="email"
            type="email"
            label="Email"
            placeholder="john@appleseed.com"
            control={control}
          />

          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="•••••••••••••••"
            control={control}
          />

          <p className="text-sm text-secondary">
            <Link to="/forgot-password">Forgot password?</Link>
          </p>

          <div className="flex flex-col mt-4">
            <Button size="lg">Sign in</Button>
          </div>
        </form>

        {errors.root && <ErrorMessage label="Form" error={errors.root} />}
      </div>
    </div>
  );
}

const ProtectedSignIn = withAuth(SignIn, { type: "unauthenticated" });

export default ProtectedSignIn;
