import { Lock02 } from "@untitled-ui/icons-react";
import { Fragment, PropsWithChildren } from "react";

import { Tooltip } from "~/components/Tooltip";
import { cn } from "~/lib/cn";

export interface FieldLabelProps extends PropsWithChildren {
  htmlFor: string;
  readOnly?: boolean;
  hidden?: boolean;
}

export function FieldLabel({
  children,
  htmlFor,
  readOnly,
  hidden,
}: FieldLabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        "flex items-center text-sm text-secondary select-none",
        hidden && "sr-only",
      )}
    >
      {children}
      {readOnly && (
        <Fragment>
          &nbsp;
          <Tooltip label="Read-only">
            <span className="text-icon-subtle" aria-label="(read-only)">
              <Lock02 className="w-3 h-3 [&_path]:stroke-[2.5]" />
            </span>
          </Tooltip>
        </Fragment>
      )}
    </label>
  );
}
