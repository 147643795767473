import { ComponentType } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { Navigate, Path } from "~/router";

export type AuthStatus = "none" | "pending" | "success";

export interface AuthStateStore {
  status: AuthStatus;
  setStatus: (status: AuthStatus) => void;
}

export const useAuthStateStore = create(
  persist<AuthStateStore>(
    (set) => ({
      status: "none",
      setStatus(status: AuthStatus) {
        set({ status });
      },
    }),
    {
      name: "auth_state",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export function useAuth() {
  return useAuthStateStore((state) => state.status);
}

export type AuthType =
  | "unauthenticated"
  | "authenticated"
  | "pending_or_authenticated"
  | "public";

export interface WithAuthParams {
  type: AuthType;
  redirect?: Path;
}

export function withAuth<P>(
  Component: ComponentType<P>,
  params: WithAuthParams,
) {
  return function AuthComponent(props: P) {
    const status = useAuth();

    if (
      status === "none" &&
      (params.type === "authenticated" ||
        params.type === "pending_or_authenticated")
    ) {
      return <Navigate to={params.redirect ?? ("/signin" as any)} replace />;
    }

    if (status === "pending" && params.type === "unauthenticated") {
      return <Navigate to="/verify" replace />;
    }

    if (status === "success" && params.type === "unauthenticated") {
      return <Navigate to={params.redirect ?? ("/" as any)} replace />;
    }

    return <Component {...(props as any)} />;
  };
}
