import { queryClient } from ".";
import {
  Application,
  CreateOrganizationParams,
  CreateOrganizationsApplicationParams,
  CreateOrganizationsInviteParams,
  CreateUserParams,
  CurrentUser,
  Favorite,
  Organization,
  OrganizationInvite,
  OrganizationMembership,
  organizationQueryOptions,
  usersMeQueryOptions,
} from "./generated/api";

export function getOptimisticUser(params: CreateUserParams): CurrentUser {
  return {
    type: "CurrentUser",
    id: "optimistic",
    email: params.email,
    email_verified: false,
    first_name: params.first_name,
    last_name: params.last_name,
    full_name: params.first_name.concat(" ", params.last_name),
    joined_at: new Date().toISOString(),
    organization_memberships: [],
    pusher_channel: "private-",
    avatar_url: "",
    role: "member",
    updated_at: new Date().toISOString(),
    feature_flags: [],
  };
}

export function getOptimisticApplication(
  params: CreateOrganizationsApplicationParams,
): Application {
  return {
    type: "Application",
    id: "optimistic",
    slug: params.slug,
    visibility: params.visibility,
    name: params.name,
    models_count: 0,
    organization_slug: params.organizationSlug.toString(),
    creator: null as any,
    avatar_url: null,
    viewer_can_sync: true,
    latest_sync: null,
    syncs_count: 0,
    pusher_channel: "private-",
    has_hmac_secret: false,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
}

export function getOptimisticOrganization(
  params: CreateOrganizationParams,
): Organization {
  return {
    type: "Organization",
    id: "optimistic",
    slug: params.slug,
    name: params.name,
    applications_count: 0,
    members_count: 1,
    sso_enabled: false,
  };
}

export function getOptimisticOrganizationMembership(
  params: CreateOrganizationParams,
): OrganizationMembership {
  const user = queryClient.getQueryData(usersMeQueryOptions({}).queryKey);
  return {
    type: "OrganizationMembership",
    id: "optimistic",
    organization: getOptimisticOrganization(params),
    user_id: user?.id ?? "optimistic",
    username: params.organization_membership.username,
    feature_flags: [],
  };
}

export function getOptimisticOrganizationInvite(
  params: CreateOrganizationsInviteParams,
): OrganizationInvite {
  const organization = queryClient.getQueryData(
    organizationQueryOptions({ slug: params.organizationSlug }).queryKey,
  );
  return {
    type: "OrganizationInvite",
    id: "optimistic",
    expires_at: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(), // 14 days from now
    organization: organization!,
    recipient_email: params.recipient_email,
  };
}

export function getOptimisticFavorite(
  favoritable: Favorite["favoritable"],
): Favorite {
  return {
    type: "Favorite",
    id: "optimistic",
    favoritable: favoritable as any,
    created_at: new Date().toISOString(),
  };
}
