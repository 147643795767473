import {
  organizationsModelQueryOptions,
  useInfiniteData,
  useInfiniteQueries,
  useOrganizationsApplicationsModelsInfiniteQuery,
} from "api-client";

import { modelActions } from "~/actions/model";
import { SyncStepper } from "~/components/_applications/SyncStepper";
import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { ActionMenu } from "~/components/ActionMenu";
import { Titlebar } from "~/components/Titlebar";
import { cn } from "~/lib/cn";
import { useCurrentApplication } from "~/lib/current";
import { Link, useParams } from "~/router";

function ApplicationDetail() {
  const params = useParams("/o/:org/w/:app");
  const app = useCurrentApplication();

  const modelsQuery = useOrganizationsApplicationsModelsInfiniteQuery({
    organizationSlug: params.org,
    applicationSlug: params.app,
  });
  const data = useInfiniteData(modelsQuery.data);
  const models = useInfiniteQueries(data, (model) =>
    organizationsModelQueryOptions({
      organizationSlug: model.organization_slug,
      id: model.id,
    }),
  );

  return (
    <div className="flex-1 flex flex-col">
      <Titlebar />

      <div className="p-4 pt-1 flex-1 flex flex-col gap-4">
        <header className="flex items-center justify-between gap-8">
          <h1 className={cn("text-2xl font-semibold")}>{app?.name}</h1>
        </header>

        {app?.latest_sync && (
          <section className="flex flex-col p-4 pt-3.5 gap-3 bg-panel rounded-xl border border-primary shadow">
            <h2 className="font-medium">Latest sync</h2>
            <SyncStepper sync={app.latest_sync} />
          </section>
        )}

        <section
          aria-label="Models"
          className="grid md:grid-cols-2 lg:grid-cols-3 gap-3"
        >
          {models.map((model) => (
            <ActionMenu
              key={model.id}
              as="contextmenu"
              target={model}
              actions={modelActions}
              trigger={
                <div
                  className={cn(
                    "relative bg-action p-3 border border-action rounded-xl shadow",
                    "hover:bg-action-active data-[state=open]:bg-action-active",
                  )}
                >
                  <Link
                    className="flex items-center gap-2.5 before:absolute before:inset-0"
                    to="/o/:org/w/:app/m/:model"
                    params={{
                      org: params.org,
                      app: params.app,
                      model: model.id,
                    }}
                  >
                    <RecordThumbnail size="md" model={model} />
                    <p className="font-medium truncate">{model.name}</p>
                  </Link>
                </div>
              }
            />
          ))}
        </section>
      </div>
    </div>
  );
}

export default ApplicationDetail;
