import { Plus } from "@untitled-ui/icons-react";
import {
  infinitePrepend,
  ModelFieldManyReferenceView,
  ModelRecord,
  organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions,
  queryClient,
  Where,
} from "api-client";
import { useState } from "react";
import { useDebounce } from "use-debounce";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { NewRecordDialog } from "~/components/_records/NewRecordDialog";
import { RecordTable } from "~/components/_records/RecordTable";
import { Button } from "~/components/Button";
import { useNavigate } from "~/router";

export interface ManyReferenceFieldProps {
  field: ModelFieldManyReferenceView;
  record: ModelRecord | null;
}

export function ManyReferenceField({ field, record }: ManyReferenceFieldProps) {
  const navigate = useNavigate();

  const [where, setWhere] = useState<Where>({});
  const [debouncedWhere] = useDebounce(where, 300, { leading: true });

  return (
    <div className="flex flex-col gap-2 group">
      <FieldLabel htmlFor={field.id} readOnly={field.read_only}>
        {field.name}
      </FieldLabel>

      <div id={field.id} className="mt-0.5">
        <RecordTable
          variant="contained"
          record={record}
          models={field.models}
          where={where}
          onWhereChange={setWhere}
          queryOptions={{
            ...organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions({
              organizationSlug: record?.organization_slug ?? "",
              modelId: record?.model_id ?? "",
              recordId: record?.id ?? "",
              fieldName: field.field_name,
              where: debouncedWhere,
            }),
            enabled: !!record,
          }}
          onSelect={(event) => {
            navigate("/o/:org/w/:app/m/:model/r/:record", {
              params: {
                org: event.record.organization_slug,
                app: event.record.application_slug,
                model: event.record.model_id,
                record: event.record.id,
              },
            });
          }}
          actions={
            <NewRecordDialog
              trigger={
                <Button icon={Plus} size="sm" variant="secondary">
                  New {field.names.humanized_singular}
                </Button>
              }
              field={field}
              record={record}
              onSuccess={(newRecord) => {
                const query =
                  organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions({
                    organizationSlug: record?.organization_slug ?? "",
                    modelId: record?.model_id ?? "",
                    recordId: record?.id ?? "",
                    fieldName: field.field_name,
                  });
                queryClient.setQueryData(query.queryKey, (cache) => {
                  if (!cache) return cache;
                  return infinitePrepend(cache, newRecord);
                });
                queryClient.invalidateQueries(query);
              }}
            />
          }
        />
      </div>
    </div>
  );
}
