import { SVGProps } from "react";

export function DragHandle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 13C9.30228 13 9.75 12.5523 9.75 12C9.75 11.4477 9.30228 11 8.75 11C8.19772 11 7.75 11.4477 7.75 12C7.75 12.5523 8.19772 13 8.75 13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 6C9.30228 6 9.75 5.55228 9.75 5C9.75 4.44772 9.30228 4 8.75 4C8.19772 4 7.75 4.44772 7.75 5C7.75 5.55228 8.19772 6 8.75 6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 20C9.30228 20 9.75 19.5523 9.75 19C9.75 18.4477 9.30228 18 8.75 18C8.19772 18 7.75 18.4477 7.75 19C7.75 19.5523 8.19772 20 8.75 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 13C15.8023 13 16.25 12.5523 16.25 12C16.25 11.4477 15.8023 11 15.25 11C14.6977 11 14.25 11.4477 14.25 12C14.25 12.5523 14.6977 13 15.25 13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 6C15.8023 6 16.25 5.55228 16.25 5C16.25 4.44772 15.8023 4 15.25 4C14.6977 4 14.25 4.44772 14.25 5C14.25 5.55228 14.6977 6 15.25 6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 20C15.8023 20 16.25 19.5523 16.25 19C16.25 18.4477 15.8023 18 15.25 18C14.6977 18 14.25 18.4477 14.25 19C14.25 19.5523 14.6977 20 15.25 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
