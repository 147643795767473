import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { updateMeMutationOptions } from "api-client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { z } from "zod";

import { Form } from "~/components/_forms/Form";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { useCurrentUser } from "~/lib/current";
import { Link } from "~/router";

const UserSettingsFormSchema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  email: z.string().email(),
});

type UserSettingsFormSchema = z.infer<typeof UserSettingsFormSchema>;

function Settings() {
  const me = useCurrentUser();

  const defaultValues = useMemo(
    () => ({
      first_name: me?.first_name || "",
      last_name: me?.last_name || "",
      email: me?.email || "",
    }),
    [me?.first_name, me?.last_name, me?.email],
  );

  const methods = useForm<UserSettingsFormSchema>({
    resolver: zodResolver(UserSettingsFormSchema),
    defaultValues,
  });

  const updateMutation = useMutation(updateMeMutationOptions());

  const onSubmit = methods.handleSubmit((data) => {
    updateMutation.mutate(data, {
      onSuccess: () => {
        toast.success("Profile updated");
      },
    });
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="account">
            <Link className="p-1" to="/settings">
              Account
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="flex flex-col flex-1 items-start p-4 pt-1 gap-4">
        <h1 className="font-semibold text-2xl">Profile</h1>

        <Form
          onSubmit={onSubmit}
          className="flex flex-col gap-4 w-full max-w-lg"
        >
          <div className="flex gap-3">
            <TextField
              name="first_name"
              label="First name"
              control={methods.control}
            />
            <TextField
              name="last_name"
              label="Last name"
              control={methods.control}
            />
          </div>
          <TextField
            name="email"
            label="Email"
            type="email"
            control={methods.control}
            readOnly
          />
          <Button type="submit" disabled={!methods.formState.isValid}>
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Settings;
