import { Check, XClose } from "@untitled-ui/icons-react";
import { ApplicationSync } from "api-client";
import { intervalToDuration } from "date-fns";
import { AnimatePresence, m } from "framer-motion";
import { useEffect, useState } from "react";

import { SdkLockup } from "~/components/_applications/SdkLockup";
import { ActivityIndicator } from "~/components/ActivityIndicator";
import { easeOutSmooth } from "~/lib/motion";

function formatDiff(start: Date, end: Date) {
  const value = intervalToDuration({ start, end });
  const formatted = [];
  if (value.days) formatted.push(`${value.days}d`);
  if (value.hours) formatted.push(`${value.hours}h`);
  if (value.minutes) formatted.push(`${value.minutes}m`);
  if (value.seconds) formatted.push(`${value.seconds}s`);
  if (formatted.length === 0) formatted.push("0s");
  return formatted.join(" ");
}

type SyncStatus = "pending" | "working" | "succeeded" | "failed";

export interface SyncStatusIconProps {
  status: SyncStatus;
}

export function SyncStatusIcon({ status }: SyncStatusIconProps) {
  switch (status) {
    case "pending":
      return (
        <div
          className="w-5 h-5 rounded-full border-primary border-2"
          aria-label="Pending"
        />
      );

    case "working":
      return (
        <div className="w-5 h-5" aria-label="In progress">
          <ActivityIndicator className="w-full h-full text-warning" />
        </div>
      );

    case "succeeded":
      return (
        <m.div
          className="w-5 h-5 bg-success rounded-full flex items-center justify-center"
          aria-label="Succeeded"
          initial={{ scale: 0.375 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2, ease: easeOutSmooth }}
        >
          <Check className="w-2.5 h-2.5 text-white [&_*]:stroke-[4]" />
        </m.div>
      );

    case "failed":
      return (
        <m.div
          className="w-5 h-5 bg-danger rounded-full flex items-center justify-center"
          aria-label="Failed"
          initial={{ scale: 0.375 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2, ease: easeOutSmooth }}
        >
          <XClose className="w-2.5 h-2.5 text-white [&_*]:stroke-[4]" />
        </m.div>
      );
  }
}

export interface SyncStepperProps {
  sync: ApplicationSync;
}

export function SyncStepper({ sync }: SyncStepperProps) {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    if (sync.state !== "succeeded" && sync.state !== "failed") {
      setNow(new Date());
      const interval = setInterval(() => {
        setNow(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [sync.state]);

  const connectStatus: SyncStatus =
    sync.state === "connecting"
      ? "working"
      : "connecting_at" in sync && sync.connecting_at
        ? sync.state === "failed" && !sync.syncing_at
          ? "failed"
          : "succeeded"
        : "pending";
  const connectStartedAt =
    "connecting_at" in sync && sync.connecting_at
      ? new Date(sync.connecting_at)
      : null;
  const connectEndedAt =
    sync.state === "failed"
      ? new Date(sync.failed_at)
      : "syncing_at" in sync && sync.syncing_at
        ? new Date(sync.syncing_at)
        : now;
  const connectTime = connectStartedAt
    ? formatDiff(connectStartedAt, connectEndedAt)
    : null;

  const syncStatus: SyncStatus =
    sync.state === "syncing"
      ? "working"
      : "syncing_at" in sync && sync.syncing_at
        ? sync.state === "failed" && !sync.analyzing_at
          ? "failed"
          : "succeeded"
        : "pending";
  const syncStartedAt =
    "syncing_at" in sync && sync.syncing_at ? new Date(sync.syncing_at) : null;
  const syncEndedAt =
    sync.state === "failed"
      ? new Date(sync.failed_at)
      : "analyzing_at" in sync && sync.analyzing_at
        ? new Date(sync.analyzing_at)
        : now;
  const syncTime = syncStartedAt
    ? formatDiff(syncStartedAt, syncEndedAt)
    : null;

  const analyzeStatus: SyncStatus =
    sync.state === "analyzing"
      ? "working"
      : "analyzing_at" in sync && sync.analyzing_at
        ? sync.state === "failed"
          ? "failed"
          : "succeeded"
        : "pending";
  const analyzeStartedAt =
    "analyzing_at" in sync && sync.analyzing_at
      ? new Date(sync.analyzing_at)
      : null;
  const analyzeEndedAt =
    sync.state === "failed"
      ? new Date(sync.failed_at)
      : "succeeded_at" in sync && sync.succeeded_at
        ? new Date(sync.succeeded_at)
        : now;
  const analyzeTime = analyzeStartedAt
    ? formatDiff(analyzeStartedAt, analyzeEndedAt)
    : null;

  return (
    <div className="flex flex-col gap-4">
      <ol className="flex flex-col gap-4">
        <AnimatePresence initial={false}>
          <li className="flex items-center gap-2.5">
            <SyncStatusIcon status={connectStatus} />
            <span className="font-medium flex-1">
              {connectStatus === "pending" || connectStatus === "failed"
                ? "Connect to SDK"
                : connectStatus === "working"
                  ? "Connecting to SDK"
                  : "Connected to SDK"}
            </span>
            {connectTime && (
              <span className="text-secondary">{connectTime}</span>
            )}
          </li>
        </AnimatePresence>

        <AnimatePresence initial={false}>
          <li className="flex items-center gap-2.5">
            <SyncStatusIcon status={syncStatus} />
            <span className="font-medium flex-1">
              {syncStatus === "pending" || syncStatus === "failed"
                ? "Sync models"
                : syncStatus === "working"
                  ? "Syncing models"
                  : "Synced models"}
            </span>
            <span className="text-secondary">{syncTime}</span>
          </li>
        </AnimatePresence>

        <AnimatePresence initial={false}>
          <li className="flex items-center gap-2.5">
            <SyncStatusIcon status={analyzeStatus} />
            <span className="font-medium flex-1">
              {analyzeStatus === "pending" || analyzeStatus === "failed"
                ? "Analyze models"
                : analyzeStatus === "working"
                  ? "Analyzing models"
                  : "Analyzed models"}
            </span>
            <span className="text-secondary">{analyzeTime}</span>
          </li>
        </AnimatePresence>
      </ol>

      {sync.state === "failed" && (
        <div className="border border-danger rounded-xl p-3 bg-danger-subtle">
          <p className="text-danger text-sm">{sync.failure_reason}</p>
        </div>
      )}

      <SdkLockup sync={sync} />
    </div>
  );
}
