import { Lock02 } from "@untitled-ui/icons-react";
import { useOrganizationsApplicationQuery } from "api-client";

import { RequestSigningForm } from "~/components/_settings/RequestSigningForm";
import { AppIcon } from "~/components/AppIcon";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { Titlebar } from "~/components/Titlebar";
import { cn } from "~/lib/cn";
import { Link, useParams } from "~/router";

function AppSecuritySettings() {
  const params = useParams("/settings/o/:org/w/:app/security");
  const applicationQuery = useOrganizationsApplicationQuery({
    slug: params.app,
    organizationSlug: params.org,
  });
  const application = applicationQuery.data ?? null;

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="team">
            <Link className="p-1" to="/settings/o/:org/w/:app" params={params}>
              <span className={cn("flex items-center gap-2")}>
                <AppIcon className="w-5 h-5" application={application} />
                <p className="whitespace-nowrap">{application?.name}</p>
                {application?.visibility === "private" && (
                  <Lock02 className={cn("w-3.5 h-3.5 text-icon")} />
                )}
              </span>
            </Link>
          </BreadcrumbItem>,
          <BreadcrumbItem key="security">
            <Link
              className="p-1"
              to="/settings/o/:org/w/:app/security"
              params={params}
            >
              Security
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 flex flex-col flex-1 gap-5">
        <h1 className="font-semibold text-2xl">Security</h1>

        <section className="flex flex-col items-start gap-3">
          <header className="flex flex-col gap-1">
            <h2 className="font-medium text-xl">Request signing</h2>
            <p className="text-secondary">
              Authenticate your application endpoints used by Baselayer
            </p>
          </header>

          {application && <RequestSigningForm application={application} />}
        </section>
      </div>
    </div>
  );
}

export default AppSecuritySettings;
