import { zodResolver } from "@hookform/resolvers/zod";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useForgotPasswordMutation } from "~/mutations";
import { Link } from "~/router";
import { Logo } from "~/svgs/Logo";

const ForgotPasswordFormSchema = z.object({
  email: z.string().email("Invalid email address"),
});

type ForgotPasswordForm = z.infer<typeof ForgotPasswordFormSchema>;

function ForgotPassword() {
  const {
    formState: { errors },
    setError,
    handleSubmit,
    control,
  } = useForm<ForgotPasswordForm>({
    resolver: zodResolver(ForgotPasswordFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const [showEmailSent, setShowEmailSent] = useState(false);
  const forgotPassword = useForgotPasswordMutation();

  const onSubmit = handleSubmit(async (data: ForgotPasswordForm) => {
    forgotPassword.mutate(data, {
      onSuccess() {
        setShowEmailSent(true);
      },
      onError: (error) => handleFormErrors(error, setError),
    });
  });

  return (
    <div className="p-4 gap-4 flex-1 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-1.5 text-icon w-full max-w-md">
        <div className="w-6 aspect-square text-icon-subtle">
          <Logo />
        </div>
        <h1 className="text-lg font-medium text-primary">Forgot password</h1>
        <p className="text-sm text-placeholder">
          Remembered your password?{" "}
          <Link className="text-secondary" to="/signin">
            Sign in
          </Link>
        </p>
      </div>

      <div className="w-full max-w-md mt-1">
        {showEmailSent ? (
          <div className="bg-subtle py-3 px-3.5 rounded-xl flex">
            <p className="text-sm text-secondary">
              If the email you entered is associated with an account, you will
              receive an email with instructions to reset your password.
            </p>
          </div>
        ) : (
          <Fragment>
            <form className="flex flex-col gap-3 mb-4" onSubmit={onSubmit}>
              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="john@appleseed.com"
                control={control}
              />
              <div className="flex flex-col mt-4">
                <Button size="lg">Send password request</Button>
              </div>
            </form>

            {errors.root && <ErrorMessage label="Form" error={errors.root} />}
          </Fragment>
        )}
      </div>
    </div>
  );
}

const ProtectedForgotPassword = withAuth(ForgotPassword, { type: "public" });

export default ProtectedForgotPassword;
