import { useOrganizationsApplicationsInfiniteQuery } from "api-client";

import { CreateApplicationForm } from "~/components/_applications/CreateApplicationForm";
import { Button } from "~/components/Button";
import { useCurrentUser } from "~/lib/current";
import { useSignOutMutation } from "~/mutations";
import { Navigate, useNavigate, useParams } from "~/router";
import { Logo } from "~/svgs/Logo";

function OrganizationDetail() {
  const params = useParams("/o/:org");
  const navigate = useNavigate();
  const applicationsQuery = useOrganizationsApplicationsInfiniteQuery({
    organizationSlug: params.org,
  });
  const applications =
    applicationsQuery.data?.pages.flatMap((page) => page.data) ?? [];
  const firstApp = applications.at(0);

  const me = useCurrentUser();
  const signOut = useSignOutMutation();

  if (applicationsQuery.isPending) return null;

  if (!firstApp) {
    return (
      <div className="flex-1 flex flex-col p-3 items-start">
        <Button
          variant="subtle"
          size="xs"
          onClick={() => {
            if (me) {
              signOut.mutate({ user_id: me.id });
            }
          }}
        >
          Sign out
        </Button>

        <div className="gap-4 flex-1 flex flex-col items-center justify-center mx-auto w-full max-w-md">
          <div className="flex flex-col gap-1.5 text-icon w-full">
            <div className="w-6 aspect-square text-icon-subtle">
              <Logo />
            </div>
            <h1 className="text-lg font-medium text-primary">
              Create a workspace
            </h1>
            <p className="text-sm text-placeholder">
              Connect your application to Baselayer using one of our SDKs.
            </p>
          </div>

          <div className="w-full mt-1">
            <CreateApplicationForm
              onSuccess={(app) => {
                navigate("/o/:org/w/:app", {
                  params: { org: params.org, app: app.slug },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to="/o/:org/w/:app"
      params={{ org: params.org, app: firstApp.slug }}
      replace
    />
  );
}

export default OrganizationDetail;
