import { zodResolver } from "@hookform/resolvers/zod";
import { useOrganizationQuery } from "api-client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { TeamAvatar } from "~/components/TeamAvatar";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { Link, useParams } from "~/router";

const OrgSettingsFormSchema = z.object({
  team_name: z.string().min(1),
});

type OrgSettingsFormSchema = z.infer<typeof OrgSettingsFormSchema>;

function OrgSettings() {
  const params = useParams("/settings/o/:org");
  const organizationQuery = useOrganizationQuery({ slug: params.org });
  const org = organizationQuery.data ?? null;

  const defaultValues = useMemo(
    () => ({
      team_name: org?.name || "",
    }),
    [org?.name],
  );

  const methods = useForm<OrgSettingsFormSchema>({
    resolver: zodResolver(OrgSettingsFormSchema),
    defaultValues,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="team">
            <Link className="p-1" to="/settings/o/:org" params={params}>
              <div className="flex items-center">
                <TeamAvatar size="xs" team={org} />
                <p className="text-sm font-medium ml-2">{org?.name ?? ""}</p>
              </div>
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 flex flex-col flex-1 gap-4">
        <h1 className="font-semibold text-2xl">General</h1>

        <div className="flex flex-col gap-4 max-w-lg">
          <TextField
            name="team_name"
            label="Team name"
            control={methods.control}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

export default OrgSettings;
