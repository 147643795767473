import { useCallback } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { useScreenMatches } from "~/lib/breakpoint";

export interface RecordSidebarStore {
  open: boolean;
  setOpen(open: boolean): void;
  openMobile: boolean;
  setOpenMobile(open: boolean): void;
}

export const useRecordSidebarStore = create(
  persist<RecordSidebarStore, [], [], Pick<RecordSidebarStore, "open">>(
    (set, get) => ({
      open: true,
      setOpen: (open) => {
        set({
          open,
          // Close mobile sidebar when desktop sidebar is closed
          openMobile: !open ? false : get().openMobile,
        });
      },
      openMobile: false,
      setOpenMobile: (openMobile) => set({ openMobile }),
    }),
    {
      name: "record-sidebar",
      storage: createJSONStorage(() => localStorage),
      // Only persist the desktop open state
      partialize: (state) => ({ open: state.open }),
    },
  ),
);

export function useRecordSidebarState() {
  const sidebar = useRecordSidebarStore();

  const onScreenChange = useCallback((matches: boolean, previous: boolean) => {
    const state = useRecordSidebarStore.getState();
    // When transitioning to desktop, open desktop sidebar if mobile sidebar was opened
    if (!matches && previous && state.openMobile) {
      state.setOpen(true);
    }
  }, []);

  const isMobile = useScreenMatches("collapse-sidebar", onScreenChange);

  if (isMobile) {
    return [sidebar.openMobile, sidebar.setOpenMobile] as const;
  } else {
    return [sidebar.open, sidebar.setOpen] as const;
  }
}
