import { ApiModel, createPusherAuth } from "api-client";
import Pusher, { Channel, PresenceChannel } from "pusher-js";
import { MutableRefObject, useEffect, useRef } from "react";

import { NonUndefined } from "~/types";

export const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
  channelAuthorization: {
    customHandler: async (params, callback) => {
      try {
        const response = await createPusherAuth({
          socket_id: params.socketId,
          channel_name: params.channelName,
        });
        callback(null, response);
      } catch (error) {
        const e = error instanceof Error ? error : new Error();
        callback(e, null);
      }
    },
  },
});

type PresenceChannelName = `presence-${string}`;
type PrivateChannelName = `private-${string}`;
export type ChannelName = PresenceChannelName | PrivateChannelName;

type ChannelType = PresenceChannel | Channel;
export type ChannelResponse<T extends ChannelName> =
  NonUndefined<T> extends PresenceChannelName ? PresenceChannel : Channel;

export function useRealtimeChannel<T extends ChannelName>(
  channelName: T | undefined,
) {
  const channelRef = useRef(
    channelName ? (pusher.subscribe(channelName) as ChannelResponse<T>) : null,
  );

  useEffect(() => {
    const shouldSubscribe =
      !!channelName &&
      (channelRef.current?.name !== channelName ||
        !channelRef.current?.subscribed);

    if (shouldSubscribe) {
      channelRef.current = pusher.subscribe(channelName) as ChannelResponse<T>;
    }

    return () => {
      channelRef.current?.unsubscribe();
    };
  }, [channelName]);

  return channelRef;
}

export interface PusherMember {
  id: string;
  info: Record<string, any>;
}

export type DefaultPusherEventMap = {
  "pusher:subscription_succeeded": never;
  "pusher:member_added": PusherMember;
  "pusher:member_removed": PusherMember;
};

type FindPusherEvent<T extends ApiModel> = T extends { event_name: any }
  ? T
  : never;
export type ApiPusherEvent = FindPusherEvent<ApiModel>;
export type ApiPusherEventName = ApiPusherEvent["event_name"];
export type ApiPusherEventMap = {
  [K in ApiPusherEventName]: Extract<ApiPusherEvent, { event_name: K }>;
};

export type PusherEventMap = DefaultPusherEventMap & ApiPusherEventMap;

export function useRealtimeEvent<
  K extends keyof PusherEventMap,
  T extends ChannelType,
>(
  channelRef: MutableRefObject<T | null>,
  event: K,
  callback: (data: PusherEventMap[K]) => void,
) {
  useEffect(() => {
    const channel = channelRef.current;
    if (!channel) return;
    channel.bind(event, callback);
    return () => {
      channel.unbind(event, callback);
    };
  }, [channelRef, event, callback]);
}
